<!----------------------->
<!-- title -->
<div class="row">
    <div class="col-11">
        <h2 class="slightlyBigger boldDLabel greyText"> {{ dataIn.title }} </h2>
    </div>
    <div class="col d-flex justify-content-end">
        <button type="button" mat-icon-button matRipple (click)="onNoClick()"><mat-icon>close</mat-icon></button>
    </div>
</div>
<!----------------------->
<!-- form -->
<form [formGroup]="addForm" (ngSubmit)="performRequest()">

    <div class="row">

        <div class="col-12">
            <h2 class="orangeText mt-2 boldDLabel blueText">PURCHASE CONTRACT INFORMATION</h2>
        </div>
        <!----------------------->
        <!-- participants autocomplete -->


        <div class="col dubai-col5 formItem">
            <!-- <div class="basisInputContainer" *ngIf="dataIn.subject">
                <label class="inputLabel">Contract No.</label>
                <div class="customInput">
                    <span class="fullHeight cut-text infoInput"> {{ dataIn.subject ? dataIn.subject.name : 'N/A' }}</span>
                    <mat-icon class="helpIcon" matTooltip="Contract Number" matTooltipPosition="above">help_outline</mat-icon>
                </div>
            </div> -->
            <!----------------------->
            <!-- client (if !hasClient) -->
            <div class="basisInputContainer" *ngIf="!dataIn.subject">
                    <!-- <input type="text" matInput placeholder="Available Contracts" id="contract" #contract formControlName="contract" [matAutocomplete]="autoContract" class="contract">
                    <button type="button"  class="searchbarSuffix fsize22" *ngIf="addForm.get('contract').value" matSuffix aria-label="Clear" (click)="addForm.get('contract').setValue('')">
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-error *ngIf="!addForm.get('contract').value.id">
                        <mat-icon>error_outline</mat-icon>
                    </mat-error> -->

                    <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
                        <mat-label>Contract</mat-label>
                        <input type="text" matInput placeholder="Available Contracts" id="contract" #contract formControlName="contract" [matAutocomplete]="autoContract" class="contract">
                        <!-- <mat-icon class="errspan" *ngIf="addForm.get('contract').invalid">error_outline</mat-icon> -->
                        <button type="button"  class="searchbarSuffix fsize22 dubaiXButton" *ngIf="addForm.get('contract').value" matSuffix aria-label="Clear" (click)="clearContract()">
                            <mat-icon>close</mat-icon>
                        </button>
                      </mat-form-field>
                <mat-autocomplete #autoContract="matAutocomplete" [displayWith]="displayFid" [panelClass]="customAutoselectDubai" [panelWidth]="175">
                    <mat-option disabled>Select One Contract</mat-option>
                    <mat-option *ngFor="let contracto of (contractOptions | async)" [value]="contracto">
                        <div class="contractContainer">
                            <p><b>ID:</b> {{contracto.contractId}}</p>
                            <p>{{contracto.productName + ' | ' + contracto.availableQuantity + ' MT'}}</p>
                        </div>
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div> 

        <div class="col dubai-col5 formItem">
            <div class="basisInputContainer">
                <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
                    <mat-label>Product</mat-label>
                    <input type="text" matInput placeholder="Available Products" id="product" #product formControlName="product" [matAutocomplete]="autoProduct" class="product" [readonly]="contractSelected">
                    <button type="button"  class="searchbarSuffix fsize22 dubaiXButton" *ngIf="addForm.get('product').value && !contractSelected" matSuffix aria-label="Clear" (click)="addForm.get('product').setValue('')">
                        <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                <mat-autocomplete #autoProduct="matAutocomplete" [displayWith]="displayFn">
                    <mat-option disabled>Select One Product</mat-option>
                    <mat-option *ngFor="let producto of (productOptions | async)" [value]="producto">
                        <div class="buyerContainer">
                            <p>{{producto.name}}</p>
                        </div>
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div> 

        <!-- <div class="col dubai-col5 formItem">
            <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
                <mat-label>Supplier</mat-label>
                <input type="text" matInput formControlName="supplierName"
                       placeholder="Supplier" [readonly]="true">
              </mat-form-field>
        </div>  -->

        <div class="col dubai-col5 formItem">
            <div class="basisInputContainer">
                <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
                    <mat-label>Supplier</mat-label>
                    <input type="text" matInput placeholder="Available Suppliers" id="supplierName" #supplierName formControlName="supplierName" [matAutocomplete]="autoSupplier" class="supplierName" [readonly]="contractSelected">
                    <button type="button"  class="searchbarSuffix fsize22 dubaiXButton" *ngIf="addForm.get('supplierName').value && !contractSelected" matSuffix aria-label="Clear" (click)="addForm.get('supplierName').setValue('')">
                        <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                <mat-autocomplete #autoSupplier="matAutocomplete" [displayWith]="displayFn">
                    <mat-option disabled>Select One Supplier</mat-option>
                    <mat-option *ngFor="let suppliero of (supplierOptions | async)" [value]="suppliero">
                        <div class="buyerContainer">
                            <p>{{suppliero.name}}</p>
                        </div>
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div> 

        <div class="col dubai-col5 formItem">
            <!-- <label class="inputLabel">Importer</label>
            <div class="customInput">

                <span class="fullHeight cut-text infoInput"> {{ addForm.get('importer').value ? addForm.get('importer').value : '' }}</span>
                 <mat-select *ngIf="!contractSelected"  placeholder="Importer" #importer id="importer" formControlName="importer">
                    <mat-option disabled>Importer</mat-option>
                    <mat-option value="Al Wadi">Al Wadi</mat-option>
                    <mat-option value="Graindesk Dubai">Graindesk Dubai</mat-option>
                </mat-select> 
                <mat-error *ngIf="addForm.get('importer').invalid">
                    <mat-icon>error_outline</mat-icon>
                </mat-error>    
            </div> -->

            <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
                <mat-label>Importer</mat-label>
                <input type="text" matInput formControlName="importer"
                       placeholder="Importer" [readonly]="true">
              </mat-form-field>
        </div>

        <div class="col dubai-col5 formItem">
            <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
                <mat-label>Origin</mat-label>
                <input type="text" matInput formControlName="origin"
                       placeholder="Origin" [readonly]="true">
              </mat-form-field>
        </div> 

        <div class="col dubai-col5 formItem">
            <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
                <mat-label>Packing</mat-label>
                <input type="text" matInput formControlName="packing"
                       placeholder="Packing" [readonly]="true">
              </mat-form-field>
        </div>

        <div class="col dubai-col5 formItem">
            <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
                <mat-label>Basis Terms</mat-label>
                <input type="text" matInput formControlName="basisTerms"
                       placeholder="Basis Terms" [readonly]="true">
              </mat-form-field>
        </div>

        <div class="col dubai-col5 formItem">
            <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
                <mat-label>Sea Port</mat-label>
                <input type="text" matInput formControlName="basisLocation"
                       placeholder="basisLocation" [readonly]="true">
              </mat-form-field>
        </div>

        <div class="col dubai-col5 formItem">
            <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
                <mat-label>Currency</mat-label>
                <input type="text" matInput formControlName="currency"
                        placeholder="Currency" [readonly]="true">
                <!-- <mat-icon class="errspan" *ngIf="addForm.get('currency').invalid">error_outline</mat-icon> -->
                </mat-form-field>
        </div>

        <div class="col dubai-col5 formItem">
                <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
                    <mat-label>Purchase Price</mat-label>
                    <input type="text" matInput formControlName="purchasePrice"
                           placeholder="Purchase Price" [readonly]="true">
                    <!-- <mat-icon class="errspan" *ngIf="addForm.get('purchasePrice').invalid">error_outline</mat-icon> -->
                  </mat-form-field>
        </div>

        <!-- <div class="col-6 col-md-4 col-lg-3 formItem">
            <label for="seaPort" class="inputLabel">Sea Port</label>
            <div class="customInput">
                <span class="fullHeight cut-text infoInput"> {{ addForm.get('seaPort').value ? addForm.get('seaPort').value : '' }}</span>
                <mat-error *ngIf="addForm.get('seaPort').invalid"><mat-icon>error_outline</mat-icon></mat-error>
            </div>
        </div> -->
        <!-- ************************************************************************************************* -->
        <div class="col-12">
            <h2 class="orangeText mt-2 blueText boldDLabel">SHIPMENT DETAILS</h2>
        </div>

        <div class="col dubai-col5 formItem">
            <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small" (click)="picker1.open()">
                <mat-label>Receiving Date</mat-label>
                <input matInput [matDatepicker]="picker1" formControlName="receivingDate" placeholder="Choose a date" autocomplete="off">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1 startView="month" ></mat-datepicker>
              </mat-form-field>
        </div>

        <div class="col dubai-col5 formItem">
            <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
                <mat-label>Import BL #</mat-label>
                <input type="text" matInput placeholder="Import BL #" id="importBlNo" #importBlNo formControlName="importBlNo">
                <!-- <mat-icon class="errspan" *ngIf="addForm.get('importBlNo').invalid">error_outline</mat-icon> -->
            </mat-form-field>
        </div>

        <div *ngIf="!dataIn.isNew" class="col dubai-col5 formItem">
            <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
                <mat-label>Container #</mat-label>
                <input type="text" matInput placeholder="Container #" id="containerNo" #containerNo formControlName="containerNo">
            </mat-form-field>
        </div>

        <div class="col dubai-col5 formItem">
            <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
                <mat-label>{{ dataIn.isNew ? 'BL Load Qty (MT)' : 'Container Load Qty (MT)'}}</mat-label>
                <input type="number" matInput placeholder="{{ dataIn.isNew ? 'BL Load Qty (MT)' : 'Container Load Qty (MT)'}}" id="qtyLoad" #qtyLoad formControlName="qtyLoad">
            </mat-form-field>
        </div>

        <div class="col dubai-col5 formItem">
            <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
                <mat-label>Shortage MT</mat-label>
                <input type="number" matInput placeholder="Shortage MT" id="shortage" #shortage formControlName="shortage">
            </mat-form-field>
        </div>

        <div class="col dubai-col5 formItem">
            <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
                <mat-label>{{ dataIn.isNew ? 'BL Unload Qty (MT)' : 'Container Unload Qty (MT)'}}</mat-label>
                <input type="number" matInput placeholder="{{ dataIn.isNew ? 'BL Unload Qty (MT)' : 'Container Unload Qty (MT)'}}" id="qtyUnload" #qtyUnload formControlName="qtyUnload">
            </mat-form-field>
        </div>

        <div class="col dubai-col5 formItem">
            <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
                <mat-label>Wastage</mat-label>
                <input type="number" matInput placeholder="Wastage" id="wastage" #wastage formControlName="wastage">
            </mat-form-field>
        </div>

        <div class="col dubai-col5 formItem">
            <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
                <mat-label>{{ dataIn.isNew ? 'BL Net Qty (MT)' : 'Container Net Qty (MT)'}}</mat-label>
                <input type="number" matInput placeholder="{{ dataIn.isNew ? 'BL Net Qty (MT)' : 'Container Net Qty (MT)'}}" id="qty" #qty formControlName="qty">
            </mat-form-field>
        </div>

        <div class="col dubai-col5 formItem">
            <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
                <mat-label>Fx Rate</mat-label>
                <input type="number" matInput placeholder="Fx Rate" id="fxRate" #fxRate formControlName="fxRate">
                <!-- <mat-icon class="errspan" *ngIf="addForm.get('fxRate').invalid">error_outline</mat-icon> -->
            </mat-form-field>
        </div>

        <div class="col dubai-col5 formItem">
            <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
                <mat-label>Seller Invoice No.</mat-label>
                <input type="text" matInput placeholder="Invoice No." id="supplierInvoiceNo" #supplierInvoiceNo formControlName="supplierInvoiceNo">
            </mat-form-field>
        </div>

        <!-- <div class="col-12 formItem">
            <label class="inputLabel">Remarks</label>
            <div class="customNoteInput mt-2">
                <textarea matInput placeholder="Remarks..." id="importRemarks" formControlName="importRemarks" rows="3"></textarea>
            </div>
        </div> -->
        <!-- IMPORT DETAILS -->

        <ng-container *ngIf="dataIn.isNew">
            <div class="col-12 row">
                <div class="col-6 col-md-4 lastRow formItem">
                    <button class="fullWidth" [ngClass]="{'blueCustomButton': importDetails.length === 0, 'blueOutlineButton' : importDetails.length > 0}" matRipple type="button" (click)="this.openImportDetails()">
                        <span [ngClass]="{'buttonText': importDetails.length === 0, 'blueBText' : importDetails.length > 0}">IMPORT DETAILS</span>
                    </button>
                </div>
            </div>
        </ng-container>

        <!-- <ng-container *ngIf="dataIn.isNew">
            <div class="col-12 fullWidth row mb-2">
                <div class="col-6 col-md-9">
                    <h2 class="orangeText mt-2 blueText boldDLabel">IMPORT DETAILS</h2>
                </div>
    
            </div>
        <div class="col-12 table-container formItem">
           <div *ngIf="!(addForm.get('importBlNo').value) || !(addForm.get('qty').value) || !(addForm.get('actualQty').value)" class="w-100 h-100 no-data">
                <h2>Enter  Import BL and Quantities First</h2>
            </div>
            <form *ngIf="addForm.get('importBlNo').value && addForm.get('qty').value && addForm.get('actualQty').value" [formGroup]="IDForm">
            <table class="h-100">
                <thead>
                    <tr #secondRow>
                        <th></th>
                        <th>
                            <div class="align-center">
                                {{totalWeight}}
                                <mat-icon class="ml-2 warn" *ngIf="totalWeight !== addForm.get('qty').value">error_outline</mat-icon>
                                <mat-icon class="ml-2 succ" *ngIf="totalWeight === addForm.get('qty').value">check_circle_outline</mat-icon>
                            </div>                        
                        </th>
                        <th>
                            <div class="align-center">
                                {{totalActualWeight}}
                                <mat-icon class="ml-2 warn" *ngIf="totalActualWeight !== addForm.get('actualQty').value ">error_outline</mat-icon>
                                <mat-icon class="ml-2 succ" *ngIf="totalActualWeight === addForm.get('actualQty').value ">check_circle_outline</mat-icon>
                            </div>  
                        </th>
                        
                        <th></th>
                    </tr>
                </thead>
                <tbody>

                    <tr class ="second">
                        <td class="text-b" [style.top.px]="heightTop">Container #</td>
                        <td class="text-b" [style.top.px]="heightTop">Container Weight MT</td>
                        <td class="text-b" [style.top.px]="heightTop">Actual Weight MT</td>
                        <td class="text-b" [style.top.px]="heightTop"></td>
                    </tr>
                    <tr class="second"> 
                        <td [style.top.px]="heightTop*2-5"> 
                            <div class="formItem w-100" >
                                <div class="customInput" (click)="IDDetails.focus()">
                                    <input type="text" matInput placeholder="Container" id="IDDetails" #IDDetails formControlName="details">
                                </div>
                            </div>
                        </td>
                        <td [style.top.px]="heightTop*2-5">
                            <div class="formItem">
                                <div class="customInput" (click)="IDBLWeight.focus()">
                                    <input type="number" matInput placeholder="BL Weight MT" id="IDBLWeight" #IDBLWeight formControlName="BLWeight">
                                    <mat-error *ngIf="IDForm.get('BLWeight').invalid"><mat-icon>error_outline</mat-icon></mat-error>
                                </div>
                            </div>
                        </td>
                        <td [style.top.px]="heightTop*2-5">
                            <div class="formItem">
                                <div class="customInput" (click)="IDActualWeight.focus()">
                                    <input type="number" matInput placeholder="Actual Weight MT" id="IDActualWeight" #IDActualWeight formControlName="actualWeight">
                                    <mat-error *ngIf="IDForm.get('actualWeight').invalid"><mat-icon>error_outline</mat-icon></mat-error>
                                </div>
                            </div>
                        </td>
                        <td [style.top.px]="heightTop*2-5">
                            <div class="alignCenter">
                                <button class="addButton" matRipple type="button" (click)="newID() ? IDDetails.focus(): '' ">
                                    <i class="feather-icon icon-plus-circle whiteIcon"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                    <ng-container>
                        <tr *ngFor="let detail of importDetails;let i = index">
                            <td>{{detail.details}}</td>
                            <td>{{detail.BLWeight | number:'0.0-2'}}</td>
                            <td>{{detail.actualWeight | number:'0.0-2'}}</td>
                            <td><mat-icon class="pointer" (click)="importDetails.splice(i,1)">close</mat-icon></td>
                        </tr>
                    </ng-container>

                </tbody>
            </table>
    
        </form>
        </div>
        </ng-container> -->

        <div class="col-12">
            <h2 class="orangeText mt-2 blueText boldDLabel">PROCESSING DETAILS</h2>
        </div>

        <div class="col dubai-col5 formItem">
            <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
                <mat-label>Purpose Plan</mat-label>
                <mat-select panelClass="testClass" placeholder="Purpose Plan" #purposePlan id="purposePlan" formControlName="purposePlan" class="customInputDropOption">
                    <mat-option disabled>Select a plan</mat-option>
                    <mat-option value="Sale - As Is">Sale - As Is</mat-option>
                    <mat-option value="Packaging">Packaging</mat-option>
                    <mat-option value="Cleaning - Packaging">Cleaning - Packaging</mat-option>
                    <mat-option value="Advanced Processing">Advanced Processing</mat-option>
                    <mat-option value="Service - Handling">Service - Handling</mat-option>
                </mat-select>
                <!-- <mat-icon class="errspan" *ngIf="addForm.get('purposePlan').invalid">error_outline</mat-icon> -->
              </mat-form-field>
        </div>

        <div class="col dubai-col5 formItem">
            <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
                <mat-label>Bag Sizes</mat-label>
                <mat-select panelClass="testClass" placeholder="Bag Sizes" #bagSizes id="bagSizes" formControlName="bagSizes" class="customInputDropOption">
                    <mat-option disabled="">Select a bag size</mat-option>
                    <mat-option *ngFor="let pack of packs" [value]="pack.name">
                        {{pack.name}}
                    </mat-option>
                </mat-select>
                <!-- <mat-icon class="errspan" *ngIf="addForm.get('bagSizes').invalid">error_outline</mat-icon> -->

              </mat-form-field>
        </div>
        <div class="col dubai-col5 formItem">
            <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
                <mat-label>Currency</mat-label>
                <mat-select panelClass="testClass" placeholder="Currency" #purposePlan id="AED" [formControl]="AED" class="customInputDropOption">
                    <mat-option value="USD">USD</mat-option>
                    <mat-option value="AED">AED</mat-option>
                </mat-select>
                <!-- <mat-icon class="errspan" *ngIf="addForm.get('purposePlan').invalid">error_outline</mat-icon> -->
              </mat-form-field>
        </div>
 <ng-container *ngIf="AED.value == 'USD'">
     
    <div class="col-12">
        <h2 class="orangeText mt-2 blueText boldDLabel">ACTUAL COST PER MT - IN USD (ESTIMATED IF ACTUAL NOT AVAILABLE)</h2>
    </div>

    <div class="col dubai-col5 formItem">
        <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
            <mat-label>Custom Clearance / MT</mat-label>
            <input type="number" matInput placeholder="Custom Clearance / MT" id="customClearance" #customClearance formControlName="customClearance">
        </mat-form-field>
    </div>

    <div class="col dubai-col5 formItem">
        <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
            <mat-label>Trucking Charges / MT</mat-label>
            <input type="number" matInput placeholder="Trucking Charges / MT" id="truckingCharges" #truckingCharges formControlName="truckingCharges">
        </mat-form-field>
    </div>

    <div class="col dubai-col5 formItem">
        <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
            <mat-label>Bagging Labour Cost / MT</mat-label>
            <input type="number" matInput placeholder="Bagging Labour Cost / MT" id="baggingLabourCost" #baggingLabourCost formControlName="baggingLabourCost">
        </mat-form-field>
    </div>

    <div class="col dubai-col5 formItem">
        <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
            <mat-label>External Storage Charges / MT</mat-label>
            <input type="number" matInput placeholder="External Storage Charges / MT" id="storageCost" #storageCost formControlName="storageCost">
        </mat-form-field>
    </div>

    <div class="col dubai-col5 formItem">
        <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
            <mat-label>Handling Fee %</mat-label>
            <input type="number" matInput placeholder="Handling Fee %" id="handlingFee" #handlingFee formControlName="handlingFee">
        </mat-form-field>
    </div>
    
    <div class="col dubai-col5 formItem">
        <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
            <mat-label>Un-Loading Cost / MT</mat-label>
            <input type="number" matInput placeholder="Un-Loading Cost / MT" id="unloadingCost" #unloadingCost formControlName="unloadingCost">
        </mat-form-field>
    </div>

    <div class="col dubai-col5 formItem">
        <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
            <mat-label>Cleaning Cost / MT</mat-label>
            <input type="number" matInput placeholder="Cleaning Cost / MT" id="cleaningCost" #cleaningCost formControlName="cleaningCost">
        </mat-form-field>
    </div>

    <div class="col dubai-col5 formItem">
        <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
            <mat-label>Cost Of Bags / MT</mat-label>
            <input type="number" matInput placeholder="Cost Of Bags / MT" id="costOfBags" #costOfBags formControlName="costOfBags">
        </mat-form-field>
    </div>

 </ng-container>
 <ng-container *ngIf="AED.value == 'AED'">
     
    <div class="col-12">
        <h2 class="orangeText mt-2 blueText boldDLabel">ACTUAL COST PER MT - IN AED (ESTIMATED IF ACTUAL NOT AVAILABLE)</h2>
    </div>

    <div class="col dubai-col5 formItem">
        <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
            <mat-label>Custom Clearance / MT</mat-label>
            <input type="number" matInput placeholder="Custom Clearance / MT" id="customClearanceAED" #customClearance formControlName="customClearanceAED">
        </mat-form-field>
    </div>

    <div class="col dubai-col5 formItem">
        <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
            <mat-label>Trucking Charges / MT</mat-label>
            <input type="number" matInput placeholder="Trucking Charges / MT" id="truckingChargesAED" #truckingCharges formControlName="truckingChargesAED">
        </mat-form-field>
    </div>

    <div class="col dubai-col5 formItem">
        <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
            <mat-label>Bagging Labour Cost / MT</mat-label>
            <input type="number" matInput placeholder="Bagging Labour Cost / MT" id="baggingLabourCostAED" #baggingLabourCost formControlName="baggingLabourCostAED">
        </mat-form-field>
    </div>

    <div class="col dubai-col5 formItem">
        <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
            <mat-label>External Storage Charges / MT</mat-label>
            <input type="number" matInput placeholder="External Storage Charges / MT" id="storageCostAED" #storageCost formControlName="storageCostAED">
        </mat-form-field>
    </div>

    <div class="col dubai-col5 formItem">
        <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
            <mat-label>Handling Fee %</mat-label>
            <input type="number" matInput placeholder="Handling Fee %" id="handlingFeeAED" #handlingFee formControlName="handlingFeeAED">
        </mat-form-field>
    </div>
    
    <div class="col dubai-col5 formItem">
        <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
            <mat-label>Un-Loading Cost / MT</mat-label>
            <input type="number" matInput placeholder="Un-Loading Cost / MT" id="unloadingCostAED" #unloadingCost formControlName="unloadingCostAED">
        </mat-form-field>
    </div>

    <div class="col dubai-col5 formItem">
        <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
            <mat-label>Cleaning Cost / MT</mat-label>
            <input type="number" matInput placeholder="Cleaning Cost / MT" id="cleaningCostAED" #cleaningCost formControlName="cleaningCostAED">
        </mat-form-field>
    </div>

    <div class="col dubai-col5 formItem">
        <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
            <mat-label>Cost Of Bags / MT</mat-label>
            <input type="number" matInput placeholder="Cost Of Bags / MT" id="costOfBagsAED" #costOfBags formControlName="costOfBagsAED">
        </mat-form-field>
    </div>

 </ng-container>
 
<ng-container *ngIf="AED.value == 'USD'">
    <div class="col-12">
        <h2 class="orangeText mt-2 blueText boldDLabel">TOTAL COSTS PER MT - IN USD</h2>
    </div>

    <div class="col dubai-col5 formItem">
        <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
            <mat-label>Total Cost USD / MT</mat-label>
            <input type="number" matInput placeholder="Total Cost" id="costTotalUsd" #costTotalUsd formControlName="costTotalUsd" [readonly]="true">
        </mat-form-field>
    </div> 

    <div class="col dubai-col5 formItem">
        <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
            <mat-label>Total Purchase Cost USD / MT</mat-label>
            <input type="number" matInput id="totalPurchaseCostUsd" #totalPurchaseCostUsd formControlName="totalPurchaseCostUsd" [readonly]="true">
        </mat-form-field>
    </div>

    <div class="col dubai-col5 formItem">
        <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
            <mat-label>Total P.C. After Wastage USD / MT</mat-label>
            <input type="number" matInput id="totalAfterWastage" #totalAfterWastage formControlName="totalAfterWastage" [readonly]="true">
            <mat-icon matSuffix matTooltip="After Wastage and Shortage" class="helpIcon fivePxMoved greenIconColor" matTooltipPosition="above">notification_important</mat-icon>
        </mat-form-field>
    </div>

    <div *ngIf="dataIn.isNew && this.genLots.value" [@fInT1]="'in'" class="col lastRow formItem onlyOnMobile">
        <mat-form-field appearance="outline" class="example-full-width my-custom-component-small">
            <mat-label>WareHouse Location</mat-label>
            <input type="text" matInput placeholder="Warehouse Location" [formControl]="wareHouseLocation">
            <!-- <mat-icon class="errspan" *ngIf="!wareHouseLocation.value">error_outline</mat-icon> -->
        </mat-form-field>
    </div>
</ng-container>

<ng-container *ngIf="AED.value == 'AED'">
    <div class="col-12">
        <h2 class="orangeText mt-2 blueText boldDLabel">TOTAL COSTS PER MT - IN AED</h2>
    </div>

    <div class="col dubai-col5 formItem">
        <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
            <mat-label>Total Cost AED / MT</mat-label>
            <input type="number" matInput placeholder="Total Cost" id="costTotalUsdAED" #costTotalUsd formControlName="costTotalUsdAED" [readonly]="true">
        </mat-form-field>
    </div> 

    <div class="col dubai-col5 formItem">
        <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
            <mat-label>Total Purchase Cost AED / MT</mat-label>
            <input type="number" matInput id="totalPurchaseCostUsdAED" #totalPurchaseCostUsd formControlName="totalPurchaseCostUsdAED" [readonly]="true">
        </mat-form-field>
    </div>

    <div class="col dubai-col5 formItem">
        <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
            <mat-label>Total P.C. After Wastage AED / MT</mat-label>
            <input type="number" matInput id="totalAfterWastageAED" #totalAfterWastage formControlName="totalAfterWastageAED" [readonly]="true">
            <mat-icon matSuffix matTooltip="After Wastage and Shortage" class="helpIcon fivePxMoved greenIconColor" matTooltipPosition="above">notification_important</mat-icon>
        </mat-form-field>
    </div>

    <div *ngIf="dataIn.isNew && this.genLots.value" [@fInT1]="'in'" class="col lastRow formItem onlyOnMobile">
        <mat-form-field appearance="outline" class="example-full-width my-custom-component-small">
            <mat-label>WareHouse Location</mat-label>
            <input type="text" matInput placeholder="Warehouse Location" [formControl]="wareHouseLocation">
            <!-- <mat-icon class="errspan" *ngIf="!wareHouseLocation.value">error_outline</mat-icon> -->
        </mat-form-field>
    </div>
</ng-container>


        <!-- <div class="row col-12">
            <div class="basisInputContainer">
                <label class="inputLabel">Warehouse</label>
                <div class="customInput" (click)="warehouse.focus()">
                    <input type="text" matInput placeholder="Warehouse" id="warehouse" #warehouse formControlName="warehouse" [matAutocomplete]="autoWarehouse" class="contract">
                    <button type="button"  class="searchbarSuffix fsize22" *ngIf="addForm.get('contract').value" matSuffix aria-label="Clear" (click)="addForm.get('warehouse').setValue('')">
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-error *ngIf="!addForm.get('warehouse').value">
                        <mat-icon>error_outline</mat-icon>
                    </mat-error>
                </div>
                <mat-autocomplete #autoContract="matAutocomplete" [displayWith]="displayWarehouse">
                    <mat-option disabled>Select Warehouse</mat-option>
                    <mat-option *ngFor="let warehouse of (filterWarehouse | async)" [value]="warehouse">
                        <div class="contractContainer">
                            <p>{{warehouse}}</p>
                        </div>
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div> -->
        
        
        <!-- <div class="col-6 col-md-4 col-lg-3 formItem">
            <label for="totalCost" class="inputLabel">Total Cost</label>
            <div class="customInput" (click)="totalCost.focus()">
                <input type="number" matInput placeholder="Total Cost" id="totalCost" #totalCost formControlName="totalCost">
                <mat-error *ngIf="addForm.get('totalCost').invalid"><mat-icon>error_outline</mat-icon></mat-error>
            </div>
        </div>

        <div class="col-6 col-md-4 col-lg-3 formItem">
            <label for="fxRate" class="inputLabel">FX Rate</label>
            <div class="customInput" (click)="fxRate.focus()">
                <input type="number" matInput placeholder="FX Rate" id="fxRate" #fxRate formControlName="fxRate">
                <mat-error *ngIf="addForm.get('fxRate').invalid"><mat-icon>error_outline</mat-icon></mat-error>
            </div>
        </div>

        <div class="col-6 col-md-4 col-lg-3 formItem">
            <label for="totalCostUsd" class="inputLabel">Total Cost USD</label>
            <div class="customInput" (click)="totalCostUsd.focus()">
                <input type="number" matInput placeholder="Total Cost USD" id="totalCostUsd" #totalCostUsd formControlName="totalCostUsd">
                <mat-error *ngIf="addForm.get('totalCostUsd').invalid"><mat-icon>error_outline</mat-icon></mat-error>
            </div>
        </div> -->

        <!-- <div class="col-12">
            <h2 class="orangeText mt-2">Outward / Transfer</h2>
        </div>

        <div class="col-6 col-md-4 col-lg-3 formItem">
            <label for="wareHouseLocation" class="inputLabel">Ware House Location</label>
            <div class="customInput" (click)="wareHouseLocation.focus()">
                <input type="text" matInput placeholder="Ware House Location" id="wareHouseLocation" #wareHouseLocation formControlName="wareHouseLocation">
                <mat-error *ngIf="addForm.get('wareHouseLocation').invalid"><mat-icon>error_outline</mat-icon></mat-error>
            </div>
        </div> -->

        <!---->

        <!--
        <div class="col-3 col-sm-2 col-lg-3 formItem" *ngIf="userRole === 'generalAdmin' || userRole === 'branchAdmin' || userRole === 'branchTrader'">
            <label class="inputLabel showOnBig">Add to reminder list</label>
            <label class="inputLabel showUntilBig">Add</label>
            <button class="orangeButton addButton" matRipple type="button" (click)="addParticipant()">
                <mat-icon>add_circle_outline</mat-icon>
                <span class="showOnlyMedium">Add</span>
                <span class="showOnBig">Add user</span>
            </button>
        </div>
    --> 

    </div>

    <!--
    <div class="row" *ngIf="userRole === 'generalAdmin' || userRole === 'branchAdmin' || userRole === 'branchTrader'">
        <div class="col-12 formItem">
            <label class="inputLabel">Reminder participants</label>
        </div>

        <div class="col-12 formItem">
            <mat-chip-list *ngIf="participantBelongs.length > 0">
                <mat-chip *ngFor="let user of participantBelongs" removable="true" selectable="false" (removed)="removeParticipant(user)" class="branchChip" [matTooltip]="user.name" matTooltipPosition="above">
                    <p class="popUpMenuText">{{user.name}}</p>
                    <div class="littleColorCircle" [style.background-color]="user.color"></div>
                    <mat-icon matChipRemove>close</mat-icon>
                </mat-chip>
            </mat-chip-list>
            <p *ngIf="participantBelongs.length === 0" class="tableText">Has not users assigned to reminder</p>
        </div>
    </div>
-->

    <!----------------------->
    <!-- reminder controls -->
    <div class="row">
        <!--
        <div class="col-12 col-sm-4 formItem">
            <p class="inputLabel notificationText">Send reminders</p>
            <div class="notificationsToggle">
                <mat-slide-toggle class="toggle" formControlName="reminder" checked="false" (change)="changeReminder($event)"></mat-slide-toggle>
            </div>
        </div>
        -->
        <!--
        <div class="col-6 col-sm-4 formItem" *ngIf="addForm.get('reminder').value">
            <label class="inputLabel">Reminder date</label>
            <div class="customInput" (click)="picker1.open()">
                <input matInput [matDatepicker]="picker1" formControlName="reminderDate" placeholder="Choose a date" autocomplete="off">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1 startView="month" ></mat-datepicker>
                <mat-error *ngIf="addForm.get('reminderDate').invalid"><mat-icon>error_outline</mat-icon></mat-error>
            </div>
        </div>
    -->
        <!--
        <div class="col-6 col-sm-4 formItem" *ngIf="addForm.get('reminder').value">
            <label class="inputLabel">Time</label>
            <div class="customInput">
                <input class="fullHeight" atp-time-picker value="16:00" formControlName="reminderTime" placeholder="Choose time"/>
                <mat-error *ngIf="addForm.get('reminderTime').invalid"><mat-icon>error_outline</mat-icon></mat-error>
            </div>
        </div>
    -->
    </div>
    <div class="d-flex flex-row-reverse buttonsRow">

        <div class="col lastRow formItem">
            <button class="fullWidth myCustomButton" matRipple type="submit" [disabled]="disableButton">
                <span class="buttonText">SAVE</span>
            </button>
        </div>

        <div *ngIf="dataIn.isNew" class="col lastRow formItem row">
            <div class="pt-2">
                <mat-checkbox [formControl]="genLots" color="primary"></mat-checkbox>
            </div>
            <p class="ml-2 pt-2 boldDLabel">GENERATE LOT</p>
        </div>

        <div *ngIf="dataIn.isNew && this.genLots.value" [@fInT1]="'in'" class="col lastRow formItem notOnMobile">
            <mat-form-field appearance="outline" class="example-full-width my-custom-component-small">
                <mat-label>WareHouse Location</mat-label>
                <input type="text" matInput placeholder="Warehouse Location" id="warehouse" #warehouse [formControl]="wareHouseLocation">
                <!-- <mat-icon class="errspan" *ngIf="!wareHouseLocation.value">error_outline</mat-icon> -->
            </mat-form-field>
        </div>
    </div>
</form>