import { EventEmitter, Injectable, OnDestroy, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoadingService implements OnDestroy {
  // ----------------------------- //
  // GLOBAL VARIABLES DECLARATION  //
  // ------------------------------ //
  @Output() open: EventEmitter<any> = new EventEmitter();
  private onDestroy = new Subject<void>();
  public showLoader: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLoading = new Subject<boolean>();

  constructor() { }
  // ----------------------------------------------------------------- //
  // MAKE 'showLoader' AS OBSERVABLE (LISTEN CHANGES FROM OTHER FILES) //
  // ----------------------------------------------------------------- //
  public onLoaderChange(): Observable<boolean> {
    return this.showLoader.asObservable();
  }
  show(): void {
    this.isLoading.next(true)
  }
  hide(): void {
    this.isLoading.next(false)
  }
  // --------------------- //
  // ON DESTROY LIFE CYCLE //
  // --------------------- //
  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.unsubscribe();
  }
}
