
import {Injectable} from "@angular/core";
import * as _moment from 'moment-timezone';
import { SimpleTableColumnType } from './simpleTable-ColumnType';

@Injectable()
export class SimpleDataTableColumnTypes {

    private months = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC']; 
    public date = new SimpleTableColumnType(
        'date',
        (data) => data ? this.getFormattedDate(data) : '-',
        160,
        160
    );

    public dateTime = new SimpleTableColumnType(
        'dateTime',
        (data) => data ? (this.getFormattedDate(data) + ' ' + this.getFormattedTime(data)) : '-',
        200,
        200
    );

    public money = new SimpleTableColumnType(
        'money',
        (data) => data ? ('$' + this.processNumber(data, 2)) : '-',
        150
    );

    public quantity = new SimpleTableColumnType(
        'quantity',
        (data) => data ? this.processNumber(data,3) : '-',
        150,
        150
    );

    public quantityEdit = new SimpleTableColumnType(
        'quantity',
        (data) => this.processNumber(data, 3),
        150,
        150,
        {type: 'number'},
    );


    public text = new SimpleTableColumnType(
        'text',
        (data) => data && data !== 'N/A' ? data : '-',
        200,
        200,
        false
    );

    public textEdit = new SimpleTableColumnType(
        'text',
        (data) => data ? data : '-',
        200,
        200,
        {type: 'text'},
    );

    public relationDinamic = new SimpleTableColumnType(
        'relationDinamic',
        (data) => data ? data : '-',
        200,
        200
    );

    public relation = new SimpleTableColumnType(
        'relation',
        (data) => data ? data : '-',
        200,
        200,
        false
    );

    public displayText = new SimpleTableColumnType(
        'displayText',
        (data) => data ? data : '-',
        200,
        200,
        false
    );

    public check = new SimpleTableColumnType(
        'check',
        (data) => data,
        80,
        80
    );


    public disabledCheck = new SimpleTableColumnType(
        'disabledCheck',
        (data) => data,
        80,
        80
    );


    public icon = new SimpleTableColumnType(
        'icon',
        (data) => data,
        80,
        80
    );

    public phoneNumber = new SimpleTableColumnType(
        'text',
        (data) => data ? this.processPhone(data) : '-',
        150,
        150
    );

    public button = new SimpleTableColumnType(
        'button',
        (data) => data,
        80,
        80
    );


    public iconDinamic = new SimpleTableColumnType(
        'iconDinamic',
        (data) => data ? data : '-',
        75,
        75
    );

    public multipleFiles = new SimpleTableColumnType(
        'multipleFiles',
        (data) => data ? data : '-',
        75,
        75,
    );


    public tag = new SimpleTableColumnType(
        'relation',
        (data) => data ? data : '-',
        200,
        200,
        false
    );

    public dinamicMultipleFiles = new SimpleTableColumnType(
        'dinamicMultipleFiles',
        (data) => data ? data : '-',
        50,
        50,
    );


    constructor() {}


    public getSatuIcon(ticket) {
        if( ticket.isRejected ) {
          return 'cancel';
        } else if (ticket.released && !ticket.approved && !ticket.isRejected) {
          return 'pending_outline';
        } else if ( ticket.approved) {
          return 'check_circle';
        } else {
          return 'info';
        }
    }


    public getShipmentIcon(data){     
        if( data && data.shipmentRegister && data.shipmentRegister.type && data.shipmentRegister.type === 'Container' ){
            return 'icon-adroit-inward-01'
        } else if( data && data.shipmentRegister && data.shipmentRegister.type && data.shipmentRegister.type === 'BL' ){
            return 'directions_boat'
        } else if( data && (( data.shipmentRegister && data.shipmentRegister.type && data.shipmentRegister.type === 'Truck') || ( data.shipmentRegister && data.shipmentRegister.plantTicket &&  data.shipmentRegister.plantTicket.isTruck ))){
            return 'local_shipping'
        } else if( data && data.shipmentRegister && data.shipmentRegister.type && data.shipmentRegister.type === 'Truck'){
            return 'local_shipping'
        }      else{
            return 'directions_subway'
        }
    }
    
    public processNumber(data, digits) {
        if (data) {
            if (typeof data === 'number') {
                return data.toLocaleString('en-US', { minimumFractionDigits: digits, maximumFractionDigits: digits });
            }

            if (Number(data) ){
                return Number(data).toLocaleString('en-US', { minimumFractionDigits: digits, maximumFractionDigits: digits });
            }
             
        }
        return 0;
    }
    public processNumber4decimals(data, digits) {
        if (data) {
            if (typeof data === 'number') {
                return data.toFixed(4);
            }
        }
        return 0;
    }
    public processPhone(phone) {
        if (phone !== undefined && phone !== null && phone !== '-') {
            if (typeof phone !== 'string') phone = phone.toString();
            if (phone !== '' && phone !== '0') {
                return  phone.length == 11 ? ( phone.substring(0,1) + ' (' + ' ' +  phone.substring(1,4) + ' ) ' + phone.substring(4,7) + ' ' + phone.substring(7,11)) : ( '(' + ' ' +  phone.substring(0,3) + ' ) ' + phone.substring(3,6) + ' ' + phone.substring(6,10));
            }
        }
        return '-';
    }

    public getFormattedDate(date) {
        const tempDate = new Date(date);
        const month = this.months[tempDate.getMonth()];
        const day = tempDate.getDate();
        const year = tempDate.getFullYear();
        return day + '-' + month + '-' + year;
    }

    public getOtherFormattedDate(date){
        const realDate = _moment(date).subtract(6,'hours').toISOString()
        var month = realDate.substring(5,7);
        //console.log(realDate, "month");
        if(Number(month) > 9){
            month = this.getMonth(Number(month)-1);
        }else{
            month = this.getMonth(Number(month.substring(1,2))-1);
        }
        var day = realDate.substring(8,10);
        const year = realDate.substring(0,4);

        if(day.substring(0,1) === '0'){
            day = day.substring(1,2)
        }
        
        return (day + '-' + month + '-' + year);
    }

    public getFormattedTime(date) {
        const tempDate = new Date(date);
        let hours: any = tempDate.getHours();
        hours < 10 ? (hours = '0' + hours.toString()) : hours.toString();
        let minutes: any = tempDate.getMinutes();
        minutes < 10 ? (minutes = '0' + minutes.toString()) : minutes.toString();
        return hours + ':' + minutes;
    }

    public getDateRange(from, to) {
        const fromDate = new Date(from);
        const fromMonth = this.months[fromDate.getMonth()];
        const fromYear = fromDate.getFullYear();
        const toDate = new Date(to);
        const toMonth = this.months[toDate.getMonth()];
        const toYear = toDate.getFullYear();
        return (fromDate.getDate() + '-' + fromMonth + '-' + fromYear + ' to ' + toDate.getDate() + '-' + toMonth + '-' + toYear);
    }

    public getMonth(value){
        let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return months[value]
    }
}
