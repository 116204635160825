import { Component, OnInit, AfterViewInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BranchService} from '../../../services/branch/branch.service';
import { AuthService} from '../../../services/auth/auth.service';
import { QueryFactory} from '../../../tableQueries/queryFactory';
import { ApiService} from '../../../services/api/api.service';
import { debounceTime, startWith, switchMap, takeUntil} from 'rxjs/operators';
import { Observable, Subject} from 'rxjs';
import { Router} from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { ModalConfirmComponent} from '../../desicion-modals/modal-confirm/modal-confirm.component';
import {LoadingService} from '../../../services/loading/loading.service';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DateFormats } from '../dubai-inward-modal/dubai-inward-modal.component';
import { TimezoneService } from 'src/app/services/timezone/timezone.service';
import { ModalConfirmInputComponent} from '../../../modals/desicion-modals/modal-confirm-input/modal-confirm-input.component';
import { fadeIn, fadeInBottom, fadeInTop } from 'src/app/animations/animatedComponents';
import { GeneralService } from 'src/app/services/general/general.service';
import * as uuid from 'uuid';

export interface DataIn {
  subject: any;
  relatedTo: string;
  model: string;
  filterProp: string;
  isPurchases: boolean;
  isTrading: boolean;
  productData: any;
  prevDeal: any;
  selectType: boolean;
  dissableContent: boolean;
  localCurrency?:string;
  title: string;
  isNew: boolean;
  yearId: string;
  yearNumber: number;
  branch: any;
}

export class objectValidator {
  static validData(control: UntypedFormControl) {
    if (typeof control.value !== 'object') {
      return ({validData: true});
    } else {
      return (null);
    }
  }
}

@Component({
  selector: 'app-winnipeg-feed-deals',
  templateUrl: './winnipeg-feed-deals.component.html',
  styleUrls: ['./winnipeg-feed-deals.component.scss'],
  providers: [ 
    { provide: MAT_DATE_FORMATS, useValue: DateFormats }
  ],
  animations: [fadeIn, fadeInBottom, fadeInTop]
})
export class WinnipegFeedDealsComponent implements OnInit, AfterViewInit, OnDestroy {

  public isEditingClient = false;
  public isEditingDeal = false;
  packs: any;
  public validUser = false;
  public reviewedContract = false;
  OriginGrain = 'USA';

  public validUsers = [
    'jose@adroitoverseas.com',
    'yashasvi@adroitoverseas.com'
  ]

  constructor(
    @Inject(MAT_DIALOG_DATA) public dataIn: DataIn,
    private dialogRef: MatDialogRef<WinnipegFeedDealsComponent>,
    private branchService: BranchService,
    public authService: AuthService,
    private queryFactory: QueryFactory,
    private apiService: ApiService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private loadingService: LoadingService,
    private _timeZone: TimezoneService,
    public generalService:GeneralService
  ) { }

  private onDestroy = new Subject<void>();
  public buyerOptions: Observable<any>;
  public supplierOptions: Observable<any>;
  public grainOptions: Observable<any>;
  public authorizeOptions: Observable<any>;
  
  public origins: any[]

  public hasChanges = false;
  public today = moment().tz("America/Vancouver").format();
  public todayPlus30 = moment().tz("America/Vancouver").add(30, 'days').format();
  public likedMail = true;
  public signedFileName = "Add Signed Contract";
  public unsignedContractName = "Add Unsigned Contract File";
  public fileName = "";
  public imageObj = [];

  public isBuyers = false;  // Venta en winnipeg
  public isSuppliers = false; // Compra en winnipeg

  public isProducts = false;
  public isGrains = false;

  public subjectConfigured = false;

  public isBas = false;
  public isResend = false;

  public isSpecifications = false;
  public grainLetter = "";

  public userRole;
  public currentFx;
  public confirmed = false;
  public isDeal = true;
  public isCanceled = false;
  public isDone = false;
  public isFob = false;
  public willDeal = true;
  public willCanceled = false;
  public willDone = false;
  public dealTypeSelected = false;
  public isResendo = new UntypedFormControl({value: false, disabled: false});
  public showResendo = true;

  public statusHistoria;

  public disableButton = false;
  public exchange ;

  productGrades: any = [
    { name: '1' },
    { name: 'X2' },
    { name: '2' },
    { name: 'X3' },
    { name: '3' },
    { name: 'N/A' },
  ];
  statusHistory: object = [
    { appUserId: "string"},
    { reason: "string"},
    { date: "string"},
    { type: "string"},
  ]

  plantOptions: any[] = [];

  public tagEmails: string[] = [
    this.authService.currentUserValue.user.email,
    
  ];

  public tagEmail = new UntypedFormControl({value: '', disabled: false});
  public isBroker = new UntypedFormControl({ value: this.dataIn?.prevDeal?.brokerName ? true : false, disabled: false });

  public reviewedContractForm = new UntypedFormControl({ value: false, disabled: false });

  public brokerControls = ['brokerName','brokerage','brokerageType'];

  addForm = this.fb.group({ 
    // PRODUCT - GRAIN DATA
    quantity: new UntypedFormControl({value: '', disabled: false}, Validators.required),
    units: new UntypedFormControl({value: this.dataIn.isTrading ? 'MT' : 'BU', disabled: false}, Validators.required),
    grade: new UntypedFormControl({value: '', disabled: false}, Validators.required),
    // DEAL DATA
    adroitOrderId: new UntypedFormControl({value: '', disabled: (this.dataIn.isNew && !this.reviewedContract) || !this.dataIn.isNew}, Validators.required),
    contractReferenceNo: new UntypedFormControl({value: '', disabled: false}),
    price: new UntypedFormControl({value: '', disabled: false}, [Validators.required, Validators.min(1)]),
    paymentTerms: new UntypedFormControl({value: '', disabled: false}, Validators.required),
    shippingUnit: new UntypedFormControl({value: 'Truck', disabled: false}, !this.dataIn.isPurchases ? Validators.required : null),
    terms: new UntypedFormControl({value: '', disabled: false}, Validators.required),
    priceUnits: new UntypedFormControl({value: '', disabled: false}, Validators.required),
    shipmentTerms: new UntypedFormControl({value: this.dataIn.isTrading ? 'TRACK' : 'DLVD', disabled: false}, Validators.required),
    shipmentAddress: new UntypedFormControl({value: '', disabled: false}, Validators.required),
    shipmentFrom: new UntypedFormControl({value: this.dataIn.isNew ? this.today : '', disabled: false}, Validators.required),
    shipmentTo: new UntypedFormControl({value: this.dataIn.isNew ? this.todayPlus30 : '', disabled: false}, Validators.required),    notificationReminder: new UntypedFormControl({value: true, disabled: false}),
    comments: new UntypedFormControl({value: '', disabled: false}),
    packing: new UntypedFormControl({value: 'Bulk', disabled: false}),
    noOfLoads: new UntypedFormControl({value: '', disabled: false}),
    qtyPerLoad: new UntypedFormControl({value: '', disabled: false}),
    companyComments: new UntypedFormControl({value: '', disabled: false}),
    
    confirmRead: new UntypedFormControl({value: false, disabled: false}),
    emailTitle: new UntypedFormControl({value: null, disabled: false}),

    fx:  new UntypedFormControl({value:'', disabled: true},[Validators.required]),

    freightRate: new UntypedFormControl({value: null, disabled:false},  Validators.required),
    exchangeRate: new UntypedFormControl({value: '', disabled:true}), 
    zone: new UntypedFormControl({value: '', disabled:false}),

    brokerName: new UntypedFormControl({value: '', disabled: false}),
    brokerage: new UntypedFormControl({value: '', disabled: false}),
    brokerageType: new UntypedFormControl({value: '', disabled: false}),
    brokerNote: new UntypedFormControl({value: '', disabled:false}),
    //isSpecifications
    specifications: new UntypedFormControl({value: '', disabled: false}),
    authorizedBy: new UntypedFormControl({value: '', disabled: false}, [Validators.required, objectValidator.validData]),

  });

  // Form crud-inputs & validations declaration //
  subjectForm = this.fb.group({
    name: new UntypedFormControl({value: '', disabled: false}),
    companyName: new UntypedFormControl({value: '', disabled: false}),
    address: new UntypedFormControl({value: '', disabled: false}),
    postalCode: new UntypedFormControl({value: '', disabled: false}),
    email: new UntypedFormControl({value: '', disabled: false}),
    brokerage: new UntypedFormControl({value: '', disabled: false})
  });

  getBranch(){
    return this.branchService.currentBranch.getValue().name.toLowerCase()
  }

  getFormattedDate(format, date ?) {
    return moment(date ? date : new Date).format(format);
  }

  getPacks() {
    var apiData = {
      where: {
        branchId: this.branchService.currentBranch.getValue().id
      },
    };
    this.apiService.getDataObjects('Packings?filter=' + JSON.stringify(apiData)).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
      this.packs = data;
    });
  }

  ngOnInit(): void {
    this.setGrainControl();
    // this.getOrigins();
    this.getPacks();
    this.userRole = this.authService.currentUserValue.user.role;
    this.searchMailingToken();

    if(this.validUsers.includes(this.authService.currentUserValue.user.email)){
      this.validUser = true;
    }

    if(this.dataIn.isNew){
      setTimeout(() => {
        this.addForm.patchValue({
          grade: this.productGrades.find(grade => grade.name == "1"),
          documents: "Load weights & grades.",
          paymentTerms: "Within 15 Days of Delivery",
          terms: "NGFA Rules to Govern"
        });
        this.getLatestFx()
      }, 250);
      this.authService?.currentUserValue?.user?.isWinnipegTrader == true ? 
      this.addForm.get('authorizedBy').patchValue(this.authService.currentUserValue.user): '';

    }else{
      //this.addForm.disable();

      // Dont show resend checkbox
      if(this.dataIn.prevDeal.traderId != this.authService.currentUserValue.user.id) this.showResendo = false;

      this.onGrainSelect(this.dataIn.prevDeal.grain);
      this.addForm.patchValue({
        quantity: this.dataIn.prevDeal.feedQuantity ? this.dataIn.prevDeal.feedQuantity : this.dataIn.prevDeal.avalableQuantity
      })
      if(this.dataIn.prevDeal.grain){
        if(this.dataIn.prevDeal.specifications){
          this.addForm.get('specifications').patchValue(this.dataIn.prevDeal.specifications);
          this.isSpecifications = true;
        }
        else if(this.dataIn.prevDeal.grain.productSpecifications){
          this.addForm.get('specifications').patchValue(this.dataIn.prevDeal.grain.productSpecifications);
          this.isSpecifications = true;
        }        
      }
      setTimeout(() => {
        if(this.addForm.get('shipmentAddress')){
          if(this.dataIn.prevDeal.shipmentAddress){
            this.addForm.get('shipmentAddress').patchValue(this.dataIn.prevDeal.shipmentAddress);
          }
        }
      }, 250);
      if(this.dataIn.prevDeal.fx){
        this.addForm.get('fx').patchValue(this.dataIn.prevDeal.fx)
        
      }
      if (!this.dataIn.prevDeal.statusHistory){
        this.statusHistoria = []
      }else{
        this.statusHistoria = this.dataIn.prevDeal.statusHistory
        this.isDone = this.dataIn.prevDeal.isDone
        this.isCanceled = this.dataIn.prevDeal.isCanceled
      }
    }
    if(this.dataIn.prevDeal){
      if(this.dataIn.prevDeal.grain){
        if(this.dataIn.prevDeal.grain.grades && this.dataIn.prevDeal.grain.grades.length > 0){
          for (let i = 0; i < this.dataIn.prevDeal.grain.grades.length; i++ ) {
            var bandera = false;
            for (let x = 0; x < this.productGrades.length; x++ ) {
              if(this.productGrades[x].name ==  this.dataIn.prevDeal.grain.grades[i].name){
                bandera = true;
              }
            }
            if(!bandera){
              this.productGrades.push(this.dataIn.prevDeal.grain.grades[i]);
            }
          }
        }
      }
    }

    if (this.dataIn.relatedTo === 'Buyers') {
      this.isBuyers = true;
      this.isGrains = true;
      this.setBSControl();
      this.addForm.patchValue({
        units: "MT",
        priceUnits: this.dataIn.localCurrency + '/MT'
      });
    }else {
      this.isSuppliers = true;
      this.isGrains = true;
      this.setBSControl();
      this.addForm.patchValue({
        units: "ST",
        priceUnits: 'USD/ST'
      });
    }
    this.setAuthorizedOptions();
    if (this.dataIn.dissableContent) {
      this.addForm.disable();
      this.subjectForm.disable();
    }
  }

  validateQbForm(){
    if(this.reviewedContractForm.value == true){
      this.reviewedContract = true;
      this.addForm.get('adroitOrderId').enable();
    }else{
      this.reviewedContract = false;
      this.addForm.get('adroitOrderId').disable();
    }
  }

  patchFeedConfig(prevDeal) {
    this.addForm.patchValue({
      units: prevDeal.feedUnits ? prevDeal.feedUnits : prevDeal.units,
      priceUnits: prevDeal.feedPriceUnits ? prevDeal.feedPriceUnits : prevDeal.priceUnits,
      quantity: prevDeal.feedQuantity ? prevDeal.feedQuantity : prevDeal.quantity,
      price: prevDeal.feedPrice ? prevDeal.feedPrice : prevDeal.price,
    });
  }

  ngAfterViewInit() {
    if (!this.dataIn.isNew) {
      this.patchEditData();
      if (this.dataIn.prevDeal.isDeal) {
        this.isDeal = true;
        this.willDeal = true;
        this.dealTypeSelected = true;
      } else if (this.dataIn.prevDeal.isCanceled) {
        this.isCanceled = true;
        this.dealTypeSelected = true;
      } else if (this.dataIn.prevDeal.isDone) {
        this.isDone = true;
        this.dealTypeSelected = true;
      }
    } else {
      if (this.dataIn.subject) {
        this.subjectForm.patchValue({
          ...this.dataIn.subject,
          companyName: this.dataIn.subject.companyName ? this.dataIn.subject.name : ''
        });
      }
    }

    this.addForm.get('noOfLoads').valueChanges.pipe(takeUntil(this.onDestroy), debounceTime(250)).subscribe( _ =>this.generateQty());
    this.addForm.get('qtyPerLoad').valueChanges.pipe(takeUntil(this.onDestroy), debounceTime(250)).subscribe( _ => this.generateQty());
  }

  generateQty(){
      if (this.addForm.get('noOfLoads').value ) {
        const quantityPerLoad = this.addForm.get('qtyPerLoad').value || '';      
        if (quantityPerLoad){
          const calculed = (this.addForm.get('noOfLoads').value * this.addForm.get('qtyPerLoad').value).toFixed(3)
          this.addForm.get('quantity').setValue(Number(calculed))
        }
      }
  }

  getLatestFx(){
    var apiData = {
      where: {
        branchId: this.branchService.currentBranch.getValue().id
      },
      order: 'createdAt DESC',
      limit: 1
    };
    this.apiService.getDataObjects('Fxs?filter=' + JSON.stringify(apiData)).pipe(takeUntil(this.onDestroy)).subscribe((data: any[]) => {
      if(data.length){
        this.addForm.get('fx').setValue(Number(data[0].fx.toFixed(3)));
        this.exchange = (Number(1 / ((data[0].fx)+ 0.002) ).toFixed(3)  ) ;
      }
    }, err => {
    }); 
  }

  patchEditData() {
    if (!this.dataIn.isNew && this.dataIn.prevDeal && this.dataIn.prevDeal.signedDocUrl) {
      this.signedFileName = "Signed Contract Added";
    }
    if (!this.dataIn.isNew && this.dataIn.prevDeal && this.dataIn.prevDeal.unsignedContractFileDocUrl) {
      this.unsignedContractName = "Unsigned Contract File Added";
    }

    if(this.dataIn.isPurchases){
      delete this.dataIn.prevDeal.suppliers;
    }else{
      delete this.dataIn.prevDeal.buyers;
    }

    setTimeout(() => {
      this.addForm.patchValue({
        ...this.dataIn.prevDeal,
        origin: this.dataIn.prevDeal.origin == 'CANADA' ? 'Canada' : this.dataIn.prevDeal.origin == 'US' ? 'USA' : this.dataIn.prevDeal.origin
      });

      if(this.dataIn.prevDeal.grade && this.dataIn.prevDeal.grade !== ' ' ){
        this.addForm.patchValue({
          grade: this.productGrades.find(grade => grade.name == this.dataIn.prevDeal.grade)
        });
      } else {
        this.addForm.patchValue({
          grade: this.productGrades.find(grade => grade.name == 'N/A')
        });
      }

      this.addForm.patchValue({
        exchangeRate: (1/this.dataIn.prevDeal.fx).toFixed(3)
      })
    }, 250);

    this.subjectForm.patchValue({
      ...this.dataIn.subject
    });

    this.addForm.patchValue({
      companyName: this.dataIn.subject.name
    });

    setTimeout(() => {
      if(this.dataIn.prevDeal.isPurchases){
        this.patchFeedConfig(this.dataIn.prevDeal);
      }
    }, 250);

    if(this.dataIn.prevDeal.plantId){
      setTimeout(() => {
        this.addForm.patchValue({
          plant: this.plantOptions.find(plant => plant.name == this.dataIn.prevDeal.plant.name)
        });
      }, 250);
    }

    if(this.dataIn.subject && this.dataIn.subject.category == 'broker'){
      this.subjectForm.patchValue({
        brokerage: this.dataIn.prevDeal.brokerage
      });
    }
    
    setTimeout(() => {
      // if(this.dataIn.isWinnipeg){
        this.addForm.patchValue({
          shipmentAddress: this.dataIn.prevDeal.shipmentAddress
        });
      // }
    }, 500);
  }

  getOrigins(){
    const query =this.queryFactory.generateSimpleGetQuery('WinnipegOrigins',{branchId:this.branchService.currentBranch.getValue().id})
    this.apiService.getTableDataObjects(query).pipe(takeUntil(this.onDestroy)).subscribe((data:any[])=>{
      this.origins = data 
    })
  }

  searchMailingToken() {
    const whereObject = {appUserId: this.authService.currentUserValue.user.id};
    const tokenQuery = this.queryFactory.generateSimpleGetQuery('MailTokens', whereObject);
    this.apiService.getDataObjects(tokenQuery).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
      if (data && data.length > 0) {
        this.likedMail = true;
      } else {
        this.likedMail = false;
      }
    });
  }

  goToProfile() {
    this.onNoClick();
    this.router.navigate(['profile']).catch();
  }

  setBSControl(){
    this.subjectForm.addControl('phone', new UntypedFormControl({value: '', disabled: false}));
    this.subjectForm.addControl('fax', new UntypedFormControl({value: '', disabled: false})); 
    this.subjectForm.addControl('contact', new UntypedFormControl({value: '', disabled: false}));
    this.subjectForm.addControl('work', new UntypedFormControl({value: '', disabled: false}));
    this.addForm.addControl('fx', new UntypedFormControl({value: '', disabled: false},[Validators.required]));
    this.addForm.addControl('freight', new UntypedFormControl({value: '', disabled: false}));
    this.addForm.addControl('documents', new UntypedFormControl({value: '', disabled: false}));
    this.addForm.addControl('counterParty', new UntypedFormControl({value: '', disabled: false}));

    if (this.dataIn.relatedTo === 'Buyers') {
      this.setBuyerControl();
    }else{
      this.setSupplierControl();
    }
  }

  setBuyerControl() {
    const buyer = !this.dataIn.isNew ? this.dataIn.prevDeal.buyer ? this.dataIn.prevDeal.buyer : '' : '';
    this.addForm.addControl('buyers', new UntypedFormControl({value: buyer, disabled: false}, [Validators.required, objectValidator.validData]));
    this.buyerOptions = this.addForm.get('buyers').valueChanges.pipe(
        debounceTime(250),
        startWith(''),
        switchMap(value => this.loadBSOptions(value))
    );

    this.subjectConfigured = true;
  }

  setSupplierControl() {
    const supplier = !this.dataIn.isNew ? this.dataIn.prevDeal.client ? this.dataIn.prevDeal.client : '' : '';
    this.addForm.addControl('suppliers', new UntypedFormControl({value: supplier, disabled: false}, [Validators.required, objectValidator.validData]));
    this.supplierOptions = this.addForm.get('suppliers').valueChanges.pipe(
        debounceTime(250),
        startWith(''),
        switchMap(value => this.loadBSOptions(value))
    );

    this.subjectConfigured = true;
  }

  setGrainControl() {
    const grain = this.dataIn.productData ? this.dataIn.productData.product : (!this.dataIn.isNew ? this.dataIn.prevDeal.grain ? this.dataIn.prevDeal.grain : '' : '');
    this.addForm.addControl('grains', new UntypedFormControl({value: grain, disabled: false}, [Validators.required, objectValidator.validData]));
    this.addForm.addControl('origin', new UntypedFormControl({value:'', disabled: true},[Validators.required])); 
    
    this.grainOptions = this.addForm.get('grains').valueChanges.pipe(
      debounceTime(250),
      startWith(''),
      switchMap(value => this.loadGrainOptions(value))
    );
  }
  
  setAuthorizedOptions() {

    this.authorizeOptions = this.addForm.get('authorizedBy').valueChanges.pipe(
      debounceTime(250),
      startWith(''),
      switchMap(value => this. loadAuthorizeOptions(value))
    );
  }

  loadBSOptions(dataSearch: string){
    const searchQuery = this.queryFactory.setSearchQuery(dataSearch, ['name']);
    const andObject: any[] = [
      searchQuery,
      {branchId: this.dataIn.branch.id,
      isActive: true
      // feed: true
    }
    ];
    const getClientsQuery = this.queryFactory.generateGetQuery(this.isBuyers ? 'Buyers': 'Suppliers', { and: andObject }, 25, 0, 'name ASC', []);
    return new Observable<any>((objs) => this.apiService.getDataObjects(getClientsQuery).subscribe((data: any) => {
      objs.next(data);
    }));
  }

  
  loadAuthorizeOptions(dataSearch: string){
    const searchQuery = this.queryFactory.setSearchQuery(dataSearch, ['name']);
    const andObject: any[] = [
      searchQuery,
      { isWinnipegTrader: true}
    ];
    const getTradersQuery = this.queryFactory.generateGetQuery('AppUsers', { and: andObject }, 25, 0, 'name ASC', []);
    return new Observable<any>((objs) => this.apiService.getDataObjects(getTradersQuery).subscribe((data: any) => {
      objs.next(data);
    }));
  }

  loadGrainOptions(dataSearch: string) {
    const searchQuery = this.queryFactory.setSearchQuery(dataSearch, ['name']);
    const andObject: any[] = [
      searchQuery,
      {category: "Feed and Protein"},
      {isActive: true},
    ];
    const getProductsQuery = this.queryFactory.generateGetQuery('Grains', {and: andObject}, 25, 0, 'name ASC', []);
    return new Observable<any>((objs) => this.apiService.getDataObjects(getProductsQuery).subscribe((data: any[]) => {
      objs.next(data);
    }));
  }

  displayFn(user?: any): string | undefined {
    return user ? user.name : undefined;
  }

  onBuyerSuplierSelect(client: any) {
    this.subjectForm.patchValue({
      companyName: client.name,
      address: client.address,
      postalCode: client.postalCode,
      contact: client.contactPerson,
      work: client.work,
      phone: client.phone,
      fax: client.fax,
      email: client.email,
    });
    if (client.paymentTerms){
      this.addForm.patchValue({
        paymentTerms: client.paymentTerms
      });
    }

    let address = client.address;
    if ( !address.includes(',') ){
      address = address + ', ' + ( client.city || '' ) + ', ' + ( client.province || '' ) + ', ' + ( client.postalCode || '' )
    }

    this.addForm.patchValue({
      shipmentAddress: address
    });
  }

  onSelectedGrade(grade: any) {
    if (grade.priceUnits && this.dataIn.isNew) {
      var producto = this.addForm.get('products').value;
      if(producto.name.includes('Peas') || producto.name.includes('Flax')){
        this.addForm.patchValue({
          priceUnits: '$/Bu'
        });
      } else{
        this.addForm.patchValue({
          priceUnits: grade.priceUnits
        });
      }
    }
  }

  onGrainSelect(grain: any){
    this.OriginGrain = grain.origin == 'CANADA' ? 'Canada' : grain.origin == 'US' ? 'USA' : grain.origin

    this.addForm.patchValue({
      origin: this.OriginGrain
    });


    this.grainLetter = "";
    if (grain.name.includes("Canola Meal") && this.dataIn.isPurchases) {
      // If the 'grain.name' variable contains the substring "Canola Meal"
      // Set the value of 'units' to 'MT'
      this.addForm.get('units').patchValue('MT');
      // Set the value of 'priceUnits' to 'USD/MT'
      this.addForm.get('priceUnits').patchValue('USD/MT');
    } else if(!grain.name.includes("Canola Meal") && !this.dataIn.isPurchases) {
        // If the data doesn't includes Canola Meal in the string and if it's sales deal, values will be 'MT' and 'CAD/MT'
        this.addForm.get('units').patchValue('MT');
      // Set the value of 'priceUnits' to 'USD/ST'
      this.addForm.get('priceUnits').patchValue('CAD/MT');
    }else if(!grain.name.includes("Canola Meal")){
      // If the 'grain.name' variable does not contain the substring "Canola Meal"
      // Set the value of 'units' to 'ST'
      this.addForm.get('units').patchValue('ST');
      // Set the value of 'priceUnits' to 'USD/ST'
      this.addForm.get('priceUnits').patchValue('USD/ST');
    }
    
    if(grain.productSpecifications){
      this.isSpecifications = true;
      this.addForm.get('specifications').patchValue(grain.productSpecifications);
    } else{
      this.isSpecifications = false;
      this.addForm.get('specifications').patchValue('');
    }
     if(grain.name == "Soybean"){
       this.grainLetter = "SB";
     }else if(grain.name == "Corn"){
       this.grainLetter = "C";
     }else if (grain.name == "Wheat"){
       this.grainLetter = "W";
     }else if (grain.name == "Meal"){
       this.grainLetter = "SM";
     } else{
       if (/\s/.test(grain.name)) {
         this.grainLetter = grain.name.substring(0,1);
         var secondstring = grain.name.substr(grain.name.indexOf(' ')+1);
  
         this.grainLetter = (this.grainLetter) + (secondstring.substring(0,1));
       }else{
         this.grainLetter = grain.name.substring(0,1);
       }
     }


    if( grain.isFeed ){
      this.addForm.get('grade').patchValue(this.productGrades.find(grade => grade.name == "N/A"))
    } else {
      this.addForm.get('grade').patchValue(this.productGrades.find(grade => grade.name == "1"))
    }
  }

  changeGrainGrade(grain){
    if(typeof grain === "string"){
      this.grainOptions.subscribe((data:any[])=>{
        data.filter(dgrain=>dgrain.name===grain?this.productGrades = dgrain.grades:undefined)
      })
    }else{
      if(grain.grades.length > 0){
        this.productGrades = grain.grades;
      }
    }
  }
  
  processPhone(phone) {
    let formatedPhone = "";
    if (phone !== undefined && phone !== null && phone !== "") {
      if (typeof phone !== "string") phone = phone.toString();
      let regex = /[^0-9\s]+/g;
      phone = phone.replace(regex, "").replace(/ /g, "").trim();
      if (phone !== "" && phone !== "0") {
        if (phone.length == 10) {
          formatedPhone =
            "(" +
            phone.substring(0, 3) +
            ") " +
            phone.substring(3, 6) +
            " " +
            phone.substring(6, 10);
        } else if (phone.length == 11) {
          formatedPhone =
            phone.substring(0, 1) +
            " (" +
            phone.substring(1, 4) +
            ") " +
            phone.substring(4, 7) +
            " " +
            phone.substring(7, 11);
        } else if (phone.length == 12) {
          formatedPhone =
            phone.substring(0, 2) +
            " (" +
            phone.substring(2, 5) +
            ") " +
            phone.substring(5, 8) +
            " " +
            phone.substring(8, 12);
        } else {
          formatedPhone = phone
        }
      }
    } else {
      formatedPhone = phone;
    }
    return formatedPhone;
  }

  openClose(status){
    const dialogRef = this.dialog.open(ModalConfirmInputComponent, {
      data: {
        title: 'Reason to ' + status +' deal',
        button: 'Save',
        inputLabel: 'Enter reason',
        inputType: 'text',
        required: true,
      },
      autoFocus: false,
      width: '500px',
      panelClass: 'usaModal' 
    })
    ;dialogRef.afterClosed().pipe(takeUntil(this.onDestroy)).subscribe(result => {
      if (result !== undefined) {
        if (result.confirmation) {
          switch (status){
            case "open":
              this.isDone = false;
              this.isCanceled = false;
            break;
            case "close":
              this.isDone = true;
              this.isCanceled = false
            break;
            case "cancel":
              this.isDone = true;
              this.isCanceled = true;
            break;
          }
          this.statusHistory = {
            appUserId: this.authService.currentUserValue.user.id,
            reason: result.remarks,
            date: new Date().toISOString(),
            type: status
          }
          this.statusHistoria.push(this.statusHistory)
          this.editDeal();
        }
      }
    });
  }

  validateForm() {    
    if (this.addForm.status === 'INVALID') {
      this.generalService.presentToast('Error in form', 'yellow-snackbar');
    } else {
      const titleString = this.dataIn.isPurchases ? 'Purchases' : 'Sales';
      const product = this.isProducts ? this.addForm.get('products').value.name : this.addForm.get('grains').value.name;
      let client = '';
  
      if (this.isBuyers) {
        client = this.addForm.get('buyers').value.name;
      } else {
        client = this.addForm.get('suppliers').value.name;
      }
        this.addForm.patchValue({
          emailTitle: titleString + ' - Business Confirmation - ' + product + ' - ' + client
      });
    }
  }

  addTag() {
    const selected = this.tagEmail.value;
    if (selected !== '') {
      this.tagEmails.push(selected);
      this.tagEmail.patchValue('');
    }
  }

  validateQBSerial(qbSerial) {
    if (this.dataIn.isNew || (!this.dataIn.isNew && qbSerial.trim() != this.dataIn.prevDeal.adroitOrderId)){
      this.apiService.getDataObjects('WinnipegDeals/count?where=' + JSON.stringify({
        adroitOrderId: qbSerial.trim()
      })).pipe(takeUntil(this.onDestroy)).subscribe((count: any) => {
        if (count.count > 0){
          this.addForm.controls['adroitOrderId'].setErrors({'incorrect': true})
          this.generalService.presentToast('Error in form, can`t have repeating QB Serial N°', 'yellow-snackbar');
        }
      });
    }
  }

  removeTag(tagEmail: string) {
    this.tagEmails.splice(this.tagEmails.findIndex(obj => obj === tagEmail), 1);
  }

  removeAllTags() {
    this.tagEmails = [];
  }

  toggleDirectResend($event) {
    if (this.isResend == false){
      this.isResend = true;
      this.addForm.get('emailTitle').disable();
      this.addForm.get('emailTitle').patchValue('Amendment to Contract - ' + this.dataIn.prevDeal.adroitOrderId);
    } else{
      this.isResend = false;
      this.addForm.get('emailTitle').enable();
      this.addForm.get('emailTitle').patchValue(this.dataIn.prevDeal.emailTitle);
    }
  }

  changeType(type: string) {
    this.willDeal = false;
    this.willDone = false;
    this.willCanceled = false;
    if (type === 'willCanceled') {
      this.addForm.addControl('cancelationText', new UntypedFormControl({value: '', disabled: false}, Validators.required));
    } else {
      if (this.addForm.get('cancelationText')) {
        this.addForm.removeControl('cancelationText');
      }
    }
    this[type] = true;
    this.dealTypeSelected = true;
  }

  performRequest() {
    if (this.addForm.status === 'INVALID' || this.subjectForm.status === 'INVALID') {
      if (this.addForm.status  === 'INVALID') {
        this.generalService.presentToast('Business Confirmation information is incomplete', 'yellow-snackbar');
      } else {
        this.generalService.presentToast("Client's information is incomplete", 'yellow-snackbar');
      }
    } else {
      this.disableButton = true;
      if (this.dataIn.isNew) {
        this.loadingService.showLoader.next(true);
        const yearId = this.dataIn.yearId;
        this.addDeal(this.dataIn.yearId);
      } else {
        this.loadingService.showLoader.next(true);
        const signedFileName = document.getElementById('signedFileName');
        const unsignedContractName = document.getElementById('unsignedContractName');
        if (signedFileName || unsignedContractName) {
          const centralF = unsignedContractName ? unsignedContractName['files'] : null;
          const signedF  = signedFileName ? signedFileName['files'] : null;
          if (centralF && centralF.length || signedF && signedF.length){
            this.uploadFile(signedFileName, unsignedContractName);
          }else this.editDeal();
        } else  this.editDeal();
      }
    }
  }

  addDeal(clientYear: any) {
    let tmpDeal = {};
    if (this.isBuyers) {
      tmpDeal = {
        grainId: this.addForm.get('grains').value.id,
        origin: this.addForm.get('origin').value ,
        year: this.dataIn.yearNumber,
        buyerId: this.addForm.get('buyers').value.id,
        traderId: this.authService.currentUserValue.user.id,
        availableQuantity: this.addForm.get('quantity').value ?  this.addForm.get('quantity').value : 0,
      };
    } else{
      tmpDeal = {
        grainId: this.addForm.get('grains').value.id,
        origin: this.addForm.get('origin').value,
        year: this.dataIn.yearNumber,
        supplierId: this.addForm.get('suppliers').value.id,
        traderId: this.authService.currentUserValue.user.id,
        availableQuantity: this.addForm.get('units').value === 'MT' ?  this.addForm.get('quantity').value : this.addForm.get('quantity').value / 1.102,
        feedQuantity: this.addForm.get('quantity').value,
        feedUnits: this.addForm.get('units').value,
        feedPriceUnits: this.addForm.get('priceUnits').value,
        feedPrice: this.addForm.get('price').value
      };
    }

    const dealObject = {
      ...tmpDeal,
      ...this.addForm.value,
      createdAt: this._timeZone.convertVanDate(new Date()),
      branchId: this.dataIn.branch.id,
      grade:  this.addForm.get('grade').value.name == 'N/A' ? ' ' : this.addForm.get('grade').value.name ,
      
      isLead: false,
      isDeal: true,
      deductionPerTon: 0,
      deductionPerHandlingFee: 0,
      deductionPerDegrade: false,
      confirmationEmailIds: false,
      sendNotifications: false,
      confirmRead: false,
      confirmationEmails: this.tagEmails,
      emailTitle: this.addForm.get('emailTitle').value,
      isPurchases: this.dataIn.isPurchases,
      isTrading: this.dataIn.isTrading,
      isResend: false,
      fx: (this.addForm.get('fx').value + 0.002),
      dealCategory: "Feed and Protein",
      contact: this.subjectForm.get('contact').value,
      authorizedById: this.addForm.get('authorizedBy').value?.id

    };

    dealObject.shipmentFrom = this._timeZone.convertVanDate(this.addForm.get('shipmentFrom').value)
    dealObject.shipmentTo = this._timeZone.convertVanDate(this.addForm.get('shipmentTo').value)

    const units = this.addForm.get('units').value;
    dealObject.quantity = (units == 'ST') ? (this.addForm.get('quantity').value / 1.102) : this.addForm.get('quantity').value;
    dealObject.units =  (units == 'ST') ? 'MT' : units;

    const priceUnits = this.addForm.get('priceUnits').value;
    dealObject.price = (priceUnits == 'USD/ST') ? (this.addForm.get('price').value / 0.907185) : this.addForm.get('price').value;
    dealObject.priceUnits =  (priceUnits == 'USD/ST') ? 'USD/MT' : priceUnits;

    delete dealObject.grains;
    if(this.dataIn.isPurchases){
      delete dealObject.suppliers;
    }else{
      delete dealObject.buyers;
    }
    if(this.dataIn.subject && this.dataIn.subject.category == 'broker'){
      dealObject.brokerage = this.subjectForm.get('brokerage').value
    }
    if(dealObject.brokerage === ""){
      dealObject.brokerage = null;
    }
    if(dealObject.broker === ""){
      dealObject.broker = null;
    }

    if(!this.isFob){
      dealObject.zone === null;
      dealObject.brokerNote === null;
    }
    if (!this.isBroker.value) {
      delete dealObject.brokerName;
      delete dealObject.brokerage;
      delete dealObject.brokerageType;
      delete dealObject.brokerNote;
    }
    this.editSubject().pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.apiService.addDataObject(dealObject, this.dataIn.model).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
        this.finishDeal();
      }, () => {
        this.generalService.presentToast('Connection rejected', 'red-snackbar');
        this.disableButton = false;
        this.loadingService.showLoader.next(false);
      });
    });
  }
  
  resetForm(){
    if (this.isBuyers){
      this.addForm.get('buyers').setValue('')
    }else{
      this.addForm.get('suppliers').setValue('')
    }
    this.subjectForm.reset()
  }

  editDeal() {
    let tmpDeal = {};
    if (this.isBuyers) {
      tmpDeal = {
        grainId: this.addForm.get('grains').value.id,
        origin: this.addForm.get('origin').value,
        year: this.dataIn.yearNumber,
        buyerId:this.addForm.get('buyers').value.id,
        availableQuantity: this.addForm.get('quantity').value ?  this.addForm.get('quantity').value : 0,
      };
    } else{
      tmpDeal = {
        grainId: this.addForm.get('grains').value.id,
        origin: this.addForm.get('origin').value,
        year: this.dataIn.yearNumber,
        supplierId: this.addForm.get('suppliers').value.id,
        availableQuantity: this.addForm.get('units').value === 'MT' ?  this.addForm.get('quantity').value : this.addForm.get('quantity').value / 1.102,
        feedQuantity: this.addForm.get('quantity').value,
        feedUnits: this.addForm.get('units').value,
        feedPriceUnits: this.addForm.get('priceUnits').value,
        feedPrice: this.addForm.get('price').value
      };
    }

    const dealObject = {
      ...this.addForm.value,
      ...tmpDeal,
      branchId: this.dataIn.branch.id,
      grade:  this.addForm.get('grade').value.name == 'N/A' ? ' ' : this.addForm.get('grade').value.name ,

      isLead: false,
      isDeal: true,

      deductionPerTon: 0,
      deductionPerHandlingFee: 0,
      deductionPerDegrade: false,
      confirmationEmailIds: false,
      sendNotifications: false,
      confirmRead: false,
      confirmationEmails: this.tagEmails,
      emailTitle: (this.isResend) ? this.dataIn.prevDeal.emailTitle : this.addForm.get('emailTitle').value,
      isPurchases: this.dataIn.isPurchases,
      isResend: this.isResend,

      statusHistory: this.statusHistoria,
      isDone: this.isDone,
      completed: this.isDone,
      isCanceled: this.isCanceled,
      authorizedById: this.addForm.get('authorizedBy').value?.id,
    };

    if (this.imageObj.length > 0) {
      for (let index = 0; index < this.imageObj.length; index++) {
        const porp = this.imageObj[index].propiedad;
        dealObject[porp] = this.imageObj[index].url;
      }
    }

    dealObject.shipmentFrom = this._timeZone.convertVanDate(this.addForm.get('shipmentFrom').value)
    dealObject.shipmentTo = this._timeZone.convertVanDate(this.addForm.get('shipmentTo').value)

    const units = this.addForm.get('units').value;
    dealObject.quantity = (units == 'ST') ? (this.addForm.get('quantity').value / 1.102) : this.addForm.get('quantity').value;
    dealObject.units =  (units == 'ST') ? 'MT' : units;

    const priceUnits = this.addForm.get('priceUnits').value;
    dealObject.price = (priceUnits == 'USD/ST') ? (this.addForm.get('price').value / 0.907185) : this.addForm.get('price').value;
    dealObject.priceUnits =  (priceUnits == 'USD/ST') ? 'USD/MT' : priceUnits;

    // if(this.dataIn.isWinnipeg){
      dealObject['contact'] = this.subjectForm.get('contact').value;
    // }

    if(!this.isFob){
      dealObject.zone === null;
      dealObject.brokerNote === null;
    }

    if(this.dataIn.subject && this.dataIn.subject.category == 'broker'){
      dealObject.brokerage = this.subjectForm.get('brokerage').value
    }
    if(dealObject.brokerage === ""){
      dealObject.brokerage = null;
    }
    if(dealObject.broker === ""){
      dealObject.broker = null;
    }
    if (this.willCanceled) {
      dealObject.commentsOnCanceled = this.addForm.get('cancelationText').value;
    }

    if(this.dataIn.isPurchases){
      delete dealObject.suppliers;
    }else{
      delete dealObject.buyers;
    }

    this.editSubject().pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.apiService.editDataObject(
          this.dataIn.prevDeal.id,
          dealObject,
          this.dataIn.model
      ).pipe(takeUntil(this.onDestroy)).subscribe(() => {
        this.finishDeal();
      }, () => {
        this.generalService.presentToast('Save deal rejected', 'red-snackbar');
        this.disableButton = false;
        this.loadingService.showLoader.next(false);
      });
    });
  }

  editSubject() {
    const editSubject: any = {
      ...this.subjectForm.value,
      contactPerson: this.subjectForm.get('contact').value,
      address: this.subjectForm.get('address').value,
      postalCode: this.subjectForm.get('postalCode').value,
      email: this.subjectForm.get('email').value,
      branchId: this.branchService.currentBranch.getValue().id,
      name: this.subjectForm.get('companyName').value
    };
    
    var id; 
    if (this.isBuyers) {
      id = this.addForm.get('buyers').value.id;
    } else {
      id = this.addForm.get('suppliers').value.id;
    }

    return new Observable(objs => this.apiService.editDataObject(
      id,
      editSubject,
      this.dataIn.relatedTo
    ).pipe(takeUntil(this.onDestroy)).subscribe(() => {
      objs.next(true);
      objs.complete();
    }, (e) => {
      this.generalService.presentToast('Edit subject rejected', 'red-snackbar');
      this.disableButton = false;
      this.loadingService.showLoader.next(false);
    }));
  }

  finishDeal() {
    if (this.dataIn.isNew) {
      this.generalService.presentToast('Deal added successfully', 'green-snackbar');
    } else {
      this.generalService.presentToast('Deal edited successfully', 'green-snackbar');
    }
    this.loadingService.showLoader.next(false);
    this.hasChanges = true;
    this.onNoClick();
  }

  deleteObject() {
    const dialogRef = this.dialog.open(ModalConfirmComponent, {
      data: {
        button: 'Delete',
        title: 'Deal',
        subtitle: '¿Are you sure about deleting this deal?',
        message: [
        ]
      },
      autoFocus: false
    });
    dialogRef.afterClosed().pipe(takeUntil(this.onDestroy)).subscribe(result => {
      if (result !== undefined) {
        if (result.confirmation) {
          this.loadingService.showLoader.next(true);
          this.apiService.deleteDataObject('WinnipegDeals', this.dataIn.prevDeal.id).pipe(takeUntil(this.onDestroy)).subscribe(() => {
            this.generalService.presentToast('Deal product deleted successfully', 'green-snackbar');
            this.loadingService.showLoader.next(false);
            this.hasChanges = true;
            this.onNoClick();
          });
        }
      }
    });
  }

  formatDateMoment(date) {
    const tempDate = new Date(date);
    return moment(tempDate).format('MMMM DD');
  }
  formatDateMomentYear(date){
    const tempDate = new Date(date);
    let dateString = tempDate.getDate() + ' ' + moment(tempDate).format('MMMM') + ' ' + tempDate.getFullYear();
    return dateString;
  }


  toggleBroker($event) {
    if ($event.checked) {
      for(let broker of this.brokerControls){
        this.addForm.get(broker).setValidators(Validators.required);
        this.addForm.get(broker).updateValueAndValidity();
      }
    } else {
      for(let broker of this.brokerControls){
        this.addForm.get(broker).clearValidators();
        this.addForm.get(broker).updateValueAndValidity();
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close({changes: this.hasChanges});
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.unsubscribe();
  }

  preAddImage(id) {
    const imageSelect = document.getElementById(id);
    const imagesF = imageSelect['files'];
    if (imagesF.length) {
      const file = imagesF[0];
      this.fileName = file.name.split('.');
      this.fileName = this.fileName[this.fileName.length - 1];
      this[id] = "File: " + file.name;
      this.fileName = uuid.v4() + "." + this.fileName;
    }
  }
  uploadFile( signedFileName = {}, unsignedContractName = {}) {
    const centralF = unsignedContractName ? unsignedContractName['files'] : null;
    const signedF  = signedFileName ? signedFileName['files'] : null;
    let files = []
    if (signedF) {
      if (signedF.length) {
        files.push({
          ...signedFileName,
          file: signedFileName['files'],
          nameDoc: '_Signed_Confirmation.',
          propiedad: 'signedDocUrl',
          flag: 'isSigned'
        });
      }
    }
    if (centralF) {
      if (centralF.length) {
        files.push({
          file: unsignedContractName['files'],
          nameDoc: '_Unsigned_Contract.',
          propiedad: 'unsignedContractFileDocUrl',
          flag: 'isUnsignedDoc'
        });
      }
    }
    let count = 0;
    files.forEach(element => {
      const file = element.file[0];
      this.fileName = file.name.split('.');
      this.fileName = this.fileName[this.fileName.length - 1];
      this.fileName = uuid.v4() + element.nameDoc + this.fileName;
      this.apiService.addFile(file, this.fileName, 'container').pipe(takeUntil(this.onDestroy)).subscribe((res: any) => {
        count = count + 1;
        const docProp = {
          url: "",
          filename: "",
          propiedad: "",
          flag: ""
        };
        docProp.url = res.result.files.container[0].name;
        docProp.filename = file.name;
        docProp.propiedad = element.propiedad,
        docProp.flag = element.flag;
        this.imageObj.push(docProp);
        if (count === files.length) {
          this.editDeal();
        }
      });
    });
  }  
  commodityText():string{    
    return (this.addForm.get('grains').value ? this.generalService.replaceTextAfterExpression(this.addForm.get('grains').value.name,',','') : '')+
            // (this.addForm.get('grade').value && this.addForm.get('grade').value.name != 'N/A' ? ' #' : '') + 
            (this.addForm.get('grade').value && this.addForm.get('grade').value.name != 'N/A' ? ' #' + this.addForm.get('grade').value.name : '') +
            (this.addForm.get('grade').value ? (this.addForm.get('grade').value.name != 1 && this.addForm.get('grade').value.name != 'Feed' && this.addForm.get('grade').value.name != 'N/A' ) ? ' Or Better' : '' : '')+
            (this.addForm.get('origin').value ? ', ' +  this.addForm.get('origin').value + ' Origin' : '')

    
  }
  downloadSigned(data: any) {
    window.open("https://s3.us-east-2.amazonaws.com/uploads.adroitoverseas.net/" + data, '_blank'); 
  }
}
