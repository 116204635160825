import {TableColumnType} from "./table-column-type";
import {Injectable} from "@angular/core";
import * as _moment from 'moment-timezone';

@Injectable()
export class DataTableColumnTypes {

    private months = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];

    public phoneNumber = new TableColumnType(
        'phoneNumber',
        (data) => data ? this.processPhone(data) : '-',
        150,
        {},
        true,
        '150px'
    );

    public accountedButton = new TableColumnType(
        'accountedButton',
        (data) => data,
        250,
        {},
        true
    );
    public phoneNumberDash = new TableColumnType(
        'phoneNumber',
        (data) => data ? this.processPhone(data) : '-',
        150,
        {},
        true,
        '150px'
    );

    public place = new TableColumnType(
        'place',
        (data) => data ? data : '-',
        250,
        {},
        true
    );

    public date = new TableColumnType(
        'date',
        (data) => data ? this.getFormattedDate(data) : '-',
        160,
        {},
        true
    );


    public dateDash = new TableColumnType(
        'date',
        (data) => data ? this.getFormattedDate(data) : '-',
        160,
        {},
        true
    )
    
    public dateUtc = new TableColumnType(
        'date',
        (data) => data ? this.getOtherFormattedDate(data) : '-',
        160,
        {},
        true
    );

    public dateTime = new TableColumnType(
        'dateTime',
        (data) => data ? (this.getFormattedDate(data) + ' ' + this.getFormattedTime(data)) : '-',
        200,
        {},
        true
    );

    public dateTimeDash = new TableColumnType(
        'dateTime',
        (data) => data ? (this.getFormattedDate(data) + ' ' + this.getFormattedTime(data)) : '-',
        200,
        {},
        true
    );

    public money = new TableColumnType(
        'money',
        (data) => data ? ('$ ' + this.processNumber(data, 2)) : '-',
        150,
        {},
        true
    );

    public moneyRight = new TableColumnType(
        'moneyRight',
        (data) => data ? ('$ ' + this.processNumber(data, 2)) : '-',
        150,
        {},
        true
    );

    public moneyDash = new TableColumnType(
        'money',
        (data) => data ? ('$ ' + this.processNumber(data, 2)) : '-',
        150,
        {},
        true
    );

    public moneyEdit = new TableColumnType(
        'money',
        (data) => ('$ ' + this.processNumber(data, 2)),
        150,
        {},
        true,
        null,
        {type: 'number'},
    );

    public mtSuffix = new TableColumnType(
        'mtSuffix',
        (data) => (this.processNumber(data, 3) + ' MT'),
        150,
        {},
        true
    );
    

    public mtSuffixEdit = new TableColumnType(
        'mtSuffix',
        (data) => (this.processNumber(data, 3) + ' MT'),
        150,
        {},
        true,
        null,
        {type: 'number'},
    );

    public quantity = new TableColumnType(
        'quantity',
        (data) => data ? this.processNumber(data, 3) : '-',
        150,
        {},
        true,
        null,
        null,

    );

    public quantityRight = new TableColumnType(
        'quantityRight',
        (data) => data ? this.processNumber(data, 2) : '-',
        150,
        {},
        true,
        null,
        null,

    );

    public quantityDash = new TableColumnType(
        'quantity',
        (data) => data ? this.processNumber(data, 2) : '-',
        150,
        {},
        true,
        null,
        null,

    );

    public quantityEdit = new TableColumnType(
        'quantity',
        (data) => this.processNumber(data, 2),
        150,
        {},
        true,
        null,
        {type: 'number'},
    );

    public fxEdit = new TableColumnType(
        'fxquantity',
        (data) => this.processNumber4decimals(data, 4),
        150,
        {},
        true,
        null,
        {type: 'number'},
    );

    public factorBuMt = new TableColumnType(
        'factorBuMt',
        (data) => (this.processNumber(data, 2) + ' BU / MT'),
        160,
        {},
        true
    );

    public text = new TableColumnType(
        'text',
        (data) => data && data !== 'N/A' ? data : '-',
        200,
        {},
        true,
        null,
        false
    );

    public textDash = new TableColumnType(
        'text',
        (data) => data ? data : '-',
        200,
        {},
        true,
        null,
        false
    );

    public relation = new TableColumnType(
        'relation',
        (data) => data ? data : '-',
        200,
        {},
        true,
        null,
        false
    );

    public textDiference = new TableColumnType(
        'text',
        (data) => data ? data : '-',
        200,
        {},
        true,
        null,
        false
    );

    public textEdit = new TableColumnType(
        'text',
        (data) => data ? data : '-',
        200,
        {},
        true,
        null,
        {type: 'text'},
    );

    public textEditDash = new TableColumnType(
        'text',
        (data) => data ? data : '-',
        200,
        {},
        true,
        null,
        {type: 'text'},
    );

    public buttonlessTextEdit = new TableColumnType(
        'buttonlessTextEdit',
        (data) => data ? data : '-',
        200,
        {},
        true,
        null,
        {type: 'text'}
    );

    public buttonlessPercentageEditDash = new TableColumnType(
        'buttonlessTextEdit',
        (data) => data ? data + '%' : '-',
        200,
        {},
        true,
        null,
        {type: 'number'}
    );

    public buttonlessTextEditDash = new TableColumnType(
        'buttonlessTextEdit',
        (data) => data ? data : '-',
        200,
        {},
        true,
        null,
        {type: 'text'}
    );

    public iconText = new TableColumnType(
        'iconText',
        (data) => data,
        200,
        {},
        true
    );

    public imageText = new TableColumnType(
        'imageText',
        (data) => data,
        200,
        {},
        true
    );

    public progresBar = new TableColumnType(
        'progresBar',
        (data) => data,
        200,
        {},
        true
    );


    public relProdIconText = new TableColumnType(
        'relProdIconText',
        (data) => data,
        200,
        {},
        true
    );

    public check = new TableColumnType(
        'check',
        (data) => data,
        80,
        {},
        false
    );

    // created to be able to have checkbox with different width
    public customCheck = new TableColumnType(
        'customCheck',
        (data) => data,
        80,
        {},
        false
    );


    public disabledCheck = new TableColumnType(
        'disabledCheck',
        (data) => data,
        80,
        {},
        false
    );


    public icon = new TableColumnType(
        'icon',
        (data) => data,
        80,
        {},
        false
    );

    public color = new TableColumnType(
        'color',
        (data) => data,
        100,
        {},
        false
    );

    public expandButton = new TableColumnType(
        'expandButton',
        (data) => data,
        80,
        {},
        false
    );

    public expandShipmentButton = new TableColumnType(
        'expandShipmentButton',
        (data) => data,
        125,
        {},
        false
    );

    public button = new TableColumnType(
        'button',
        (data) => data,
        80,
        {},
        false
    );

    public dropdownButton = new TableColumnType(
        'dropdownButton',
        (data) => data,
        80,
        {},
        false
    );
    public expandHidden = new TableColumnType(
        'expandHidden',
        (data) => data,
        80,
        {},
        false
    );

    public html = new TableColumnType(
        'html',
        (data) => data,
        150,
        {},
        false
    );

    public textIcon = new TableColumnType(
        'textIcon',
        (data) => data,
        65,
        {},
        false,
        '65px',
        '',
        ''
    );

    constructor() {}

    public processNumber(data, digits) {
        if (data) {
            if (typeof data === 'number') {
                const decimals = digits || 2;
                return data.toLocaleString('en-US', { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
            }
        }
        return '-';
    }
    public processNumber4decimals(data, digits) {
        if (data) {
            if (typeof data === 'number') {
                return data.toFixed(4);
            }
        }
        return 0;
    }
    public processPhone(phone) {
        if (phone !== undefined && phone !== null && phone !== '-') {
            if (typeof phone !== 'string') phone = phone.toString();
            if (phone !== '' && phone !== '0') {
                return  phone.length == 11 ? ( phone.substring(0,1) + ' (' + ' ' +  phone.substring(1,4) + ' ) ' + phone.substring(4,7) + ' ' + phone.substring(7,11)) : ( '(' + ' ' +  phone.substring(0,3) + ' ) ' + phone.substring(3,6) + ' ' + phone.substring(6,10));
            }
        }
        return '-';
    }

    public getFormattedDate(date) {
        const tempDate = new Date(date);
        const month = this.months[tempDate.getMonth()];
        const day = tempDate.getDate();
        const year = tempDate.getFullYear();
        return day + '-' + month + '-' + year;
    }

    public getOtherFormattedDate(date){
        const realDate = _moment(date).subtract(6,'hours').toISOString()
        var month = realDate.substring(5,7);
        //console.log(realDate, "month");
        if(Number(month) > 9){
            month = this.getMonth(Number(month)-1);
        }else{
            month = this.getMonth(Number(month.substring(1,2))-1);
        }
        var day = realDate.substring(8,10);
        const year = realDate.substring(0,4);

        if(day.substring(0,1) === '0'){
            day = day.substring(1,2)
        }
        
        return (day + '-' + month + '-' + year);
    }

    public getFormattedTime(date) {
        const tempDate = new Date(date);
        let hours: any = tempDate.getHours();
        hours < 10 ? (hours = '0' + hours.toString()) : hours.toString();
        let minutes: any = tempDate.getMinutes();
        minutes < 10 ? (minutes = '0' + minutes.toString()) : minutes.toString();
        return hours + ':' + minutes;
    }

    public getDateRange(from, to) {
        const fromDate = new Date(from);
        const fromMonth = this.months[fromDate.getMonth()];
        const fromYear = fromDate.getFullYear();
        const toDate = new Date(to);
        const toMonth = this.months[toDate.getMonth()];
        const toYear = toDate.getFullYear();
        return (fromDate.getDate() + '-' + fromMonth + '-' + fromYear + ' to ' + toDate.getDate() + '-' + toMonth + '-' + toYear);
    }

    public getMonth(value){
        let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return months[value]
    }
}
