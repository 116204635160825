import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthService} from '../auth/auth.service';
import {Observable} from "rxjs";
import * as CryptoJS from 'crypto-js';


@Injectable({
  providedIn: 'root'
})
export class SessionGuard implements CanActivate {

    constructor(
        private router: Router,
        private authService: AuthService,
    ) { }

    canActivate(): boolean {
        const data: any = localStorage.getItem('LH52NZe7Av') || '';
        const dataDecrypted = this.authService.decrypt(data, 'LH52NZe7Av').toString(CryptoJS.enc.Utf8);
        const user = (dataDecrypted) ? JSON.parse(dataDecrypted) : '';

        if (user) {
            return true;
        } else {
            // console.log('failed session guard');
            this.router.navigate(['login']).then(() => {
                return false;
            });
        }
        // this.router.navigate(['login']).then(() => {
        //     return false;
        // });
    }
}
