<h2 class="canada-color text-subtitle">RESET PASSWORD</h2>
<div mat-dialog-content class="Panel-act-2">
  <!--------------->
  <!-- searchbar -->
  <div class="w-100 mt-2">
    <mat-form-field appearance="outline" class="custom-form-field">
      <mat-label>Password *</mat-label>
      <input type="password" matInput placeholder="Type a new password" id="passwordField" #passwordField [formControl]="password">
    </mat-form-field>
</div>
<div class="btn-flex-row mt-2">
  <button type="button" class="mexicoButton btn-secondary-dim" matRipple (click)="dismiss()">CANCEL</button>
  <button type="button" class="canada-color-bg mexicoButton" matRipple (click)="sendEmail()">SEND EMAIL</button>
  <button type="button" class="canada-color-bg mexicoButton" matRipple (click)="confirm()">{{data.button | uppercase}}</button>
</div>
