<h2 class="canada-color text-title {{ data.titleClass || '' }}">{{data.title | uppercase}}</h2>
<div>
  <h4 *ngIf="data.subtitle">{{data.subtitle}}</h4>
  <div *ngFor="let line of data.message">
    <p>{{line}}</p>
  </div>
  <!-- <div class="Hover-Copy-4"></div> -->
<form [formGroup]="confirmForm" class="confirmForm">
    <div class="row">
      <div class="col-12">
        <!-- <div class="Hover-Copy-4"></div> -->
        <mat-form-field appearance="outline" class="custom-form-field" >
          <mat-label for="remarks" class="inputLabel">{{data.inputLabel}}</mat-label>
          <input *ngIf="data.type && data.type != 'textArea' || !data.type" [type]="data.inputType ? data.inputType : 'text'" min="1" [max]="data.maxValue" matInput [placeholder]="data.inputLabel" id="remarks" #remarks formControlName="remarks">
          <textarea *ngIf="data.type && data.type == 'textArea'" [type]="data.inputType ? data.inputType : 'text'" min="1" [max]="data.maxValue" matInput [placeholder]="data.inputLabel" id="remarks" #remarks formControlName="remarks" rows="3"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div *ngIf="data.checkbox" class="col-7 col-md-7 checkboxContainer mb-2">
        <mat-checkbox *ngIf="data.checkbox" id="checkbox" #checkbox formControlName="checkbox" class="custom-checkbox">  
        </mat-checkbox>
        <p class="checkText text-subtitle-modal">{{ data.checkLabel }}</p>
      </div>
       <div class="d-flex" [ngClass]=" {'col-12 col-md-12 justify-content-end': !data.checkbox}">
        <div>
          <button class="mexicoButton btn-secondary-dim ml-3 mr-2 mt-1" type="button" mat-button (click)="dismiss()">{{ data.button == 'Cancel' ? 'Close' : 'Cancel' }}</button>
        </div>
        <div>
          <button class="canada-color-bg mexicoButton mt-1" type="button" mat-button (click)="confirm()">{{data.button}}</button>
        </div>
      </div>
    </div>
</form>

<p *ngIf="moreSpecific" class="body-regular-bold"> Specify more </p>