<!-- ============================================================== -->
<!-- Main wrapper -->
<!-- ============================================================== -->
<div class="horizontalGradient fullWScreen fullHScreen">
    <div id="loginBackWrapper"></div>
    <div class="rectangle" id="particles-js"></div>
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPasswordSubmit()">
        <div class="center disabledAutofill" id="formWrapper">
            <div id="circle" class="center">
                <img id="logoImg" src="../../assets/img/logo-original.png" alt="logo adroit">
            </div>
            <div class="d12 labelAuth">Enter your email address to retrieve your password.</div>
            <div class="block loginSizes">
                <i class="icon-lock login-icon"></i>
                <mat-form-field class="fullWidth loginInput whiteOutline left bottom">
                    <mat-label class="loginWhiteLabel">Enter your email</mat-label>
                    <input matInput type="email" formControlName="email" required>
                    <button mat-button type="button" *ngIf="forgotPasswordForm.get('email').value" matSuffix mat-icon-button aria-label="Clear" (click)="forgotPasswordForm.get('email').patchValue('')">
                        <mat-icon class="xButton">close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <button mat-raised-button class="whiteRoundedButton block loginSizes" id="loginButton" type="submit" aria-label="Iniciar sesión">Recover Password</button>
            <span class="login_label" (click)="login()">Log In</span>
            <p></p>
        </div>
    </form>
</div>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
