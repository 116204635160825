import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent, LoaderComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { LoginComponent } from './login/login.component';
import { APP_ROUTES } from './app.routes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './helpers/token.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { CommonModule } from '@angular/common';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { MessageModalModule } from './modals/desicion-modals/message-modal/message-modal.module';
import { ApiService } from './services/api/api.service';
import { AuthService } from './services/auth/auth.service';
import { LoadingService } from './services/loading/loading.service';
import { SharingService } from './services/sharing/sharing.service';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { DataTableColumnTypes } from './models/datatables/data-table-column-types';

import { PasswordComponent } from './forgot_password/forgot_password.component';
import { ResetComponent } from './reset_password/reset_password.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getEnglishPaginatorIntl } from './material/paginator-intl';
import { RailcarsModalComponent } from './modals/crud-modals/railcars-modal/railcars-modal.component';
import { NgxImageCompressService } from 'ngx-image-compress';
import { PlantPricesComponent } from './plant_prices/plant_prices.component';
import { PlantPricesModule } from './plant_prices/plant_prices.module';
import { WinnipegFeedDealsComponent } from './modals/crud-modals/winnipeg-feed-deals/winnipeg-feed-deals.component';
import { WinnipegGrainDealsComponent } from './modals/crud-modals/winnipeg-grain-deals/winnipeg-grain-deals.component';
import { SimpleTableComponent } from './generic-components/data-visualization/simple-table/simple-table.component';
import { SimpleDataTableColumnTypes } from './models/simpleTable/simpleTable-column-types';
import { SpinnerInterceptor } from './structure/shared/interceptors/spinner.interceptor';
import { AuthorizeBuyerViewComponent } from './structure/mexico/mexico-load-orders/authorize-buyer-view/authorize-buyer-view.component';


export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        LoaderComponent,
        PasswordComponent,
        ResetComponent,
        RailcarsModalComponent,
        WinnipegFeedDealsComponent,
        WinnipegGrainDealsComponent,
        AuthorizeBuyerViewComponent,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        APP_ROUTES,
        NgxMaskModule.forRoot(options),
        MessageModalModule,
        HttpClientModule,
        ServiceWorkerModule.register('ngsw-worker.js', { 
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            registrationStrategy: 'registerWhenStable:5000'
        }),
        PlantPricesModule,
        // SocketIoModule.forRoot(config)
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
        { provide: MatPaginatorIntl, useValue: getEnglishPaginatorIntl() },
        ApiService,
        AuthService,
        LoadingService,
        SharingService,
        [NgxImageCompressService],
        // SidebarService,
        DataTableColumnTypes,
        SimpleDataTableColumnTypes
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
  public constructor() { }
}
