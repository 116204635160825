import { Component, ElementRef, Inject, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Observable, Subject} from 'rxjs';
import * as uuid from 'uuid';
import {ApiService} from '../../../../services/api/api.service';
import {debounceTime, startWith, switchMap, takeUntil} from 'rxjs/operators';
export interface dataIn {
  detailsArray: any[];
}

@Component({
  selector: 'app-import-details-modal',
  templateUrl: './import-details-modal.component.html',
  styleUrls: ['./import-details-modal.component.scss']
})


export class ImportDetailsModalComponent implements OnInit {

  @ViewChild('containerNo') containerNo: ElementRef;
  private onDestroy = new Subject<void>();
  constructor(
    private dialogRef: MatDialogRef<ImportDetailsModalComponent>,
    private fb: UntypedFormBuilder,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public dataIn: dataIn,
    private snackBar: MatSnackBar,
  ) { }
  public fileName = "";
  public viewFileName = "Add File";
  public imageObj;
  public startUpload = false;
  addForm = this.fb.group({
    containerNo : new UntypedFormControl({value: '', disabled: false}, Validators.required),
    actualWeightMt : new UntypedFormControl({value: 0, disabled: false}, Validators.required),
    unloadWeightMt : new UntypedFormControl({value: 0, disabled: true}, Validators.required),
    shortage : new UntypedFormControl({value: 0, disabled: false}, Validators.required),
    clearanceDate: new UntypedFormControl({value: '', disabled: false}, Validators.required)
  });

  public myDetailsArray = [

  ]

  ngOnInit(): void {
    console.log(this.dataIn.detailsArray)
    this.myDetailsArray = this.dataIn.detailsArray;
    this.addForm.get('actualWeightMt').valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((status) => {
      let unload = status - this.addForm.get('shortage').value;
      this.addForm.get('unloadWeightMt').patchValue(unload);
    });
    this.addForm.get('shortage').valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((status) => {
      let unload = this.addForm.get('actualWeightMt').value - status;
      this.addForm.get('unloadWeightMt').patchValue(unload);
    });
  }

  addContainer(){
    if(this.addForm.status === 'INVALID'){
      this.presentToast('Please fill every field', 'yellow-snackbar');
    } else {
      const imageSelect = document.getElementById('upload');
      if(imageSelect){
        const imagesF = imageSelect['files'];
        if(imagesF.length > 0){
          this.uploadFileNew();
        } else{
          this.Adding();
        }
      }else{
        this.Adding();
      }
    }
  }

  uploadFileNew(){
    this.startUpload = true;
    const imageSelect = document.getElementById('upload');
    const imagesF = imageSelect['files'];
    const file = imagesF[0];
    this.fileName = file.name.split('.');
    if(file.size > 50000000){
      this.presentToast('Max file size 50MB', 'yellow-snackbar');
      this.startUpload = false;
    }else{
      this.fileName = this.fileName[this.fileName.length - 1];
      this.fileName = uuid.v4() + "." + this.fileName;
      let uploadFiles = new Promise<void>((resolve, reject) => {
        this.apiService.addFile(file, this.fileName, 'container').pipe(takeUntil(this.onDestroy)).subscribe((res: any) => {
          const docProp = {
            url: "",
            filename: ""
          };
          docProp.url = this.fileName;
          docProp.filename = file.name;
          this.imageObj = docProp;
          resolve()
        });
      })
      uploadFiles.then(() => {
        this.Adding()
        this.startUpload = false;
      })
    }
  }

  Adding(){
    let newContainer = {
      ...this.addForm.value,
      unloadWeightMt: this.addForm.get('unloadWeightMt').value
    }
    if(this.imageObj){
      newContainer["url"] = this.imageObj.url;
      newContainer["fileName"] = this.imageObj.filename;
    }
    console.log(newContainer)
    this.myDetailsArray.unshift(newContainer)
    this.containerNo.nativeElement.focus()
    this.addForm.patchValue({
      containerNo: '',
      actualWeightMt: 0,
      clearanceDate: '',
      shortage: 0,
      unloadWeightMt: 0,
    })
    this.removeMedia()
  }

  clearFileInput(ctrl) {
    try {
      ctrl.value = null;
    } catch(ex) { }
    if (ctrl.value) {
      ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
    }
  }

  formatDate(tempDate: Date) {
    const date = new Date(tempDate);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    return ( date.getDate() + '/' + months[date.getMonth()] + '/' + date.getFullYear());
  }
  removeContainer(i){
    this.myDetailsArray.splice(i, 1)
  }

  returnArray(){
    this.onNoClick()
  }
  preAddImage() {
    const imageSelect = document.getElementById('upload');
    const imagesF = imageSelect['files'];
    if(imagesF.length > 0){
      const file = imagesF[0];
      const size = parseFloat(((imagesF[0].size/1024)/1024).toFixed(4));
      if (size > 50) return this.presentToast('Maximum size 50 MB', 'yellow-snackbar');
      this.fileName = file.name.split('.');
      this.fileName = this.fileName[this.fileName.length - 1];
      this.viewFileName = file.name.substring(0,14);
      this.fileName = uuid.v4() + "." + this.fileName;
    }
  }
  presentToast(message: string, style: string) {
    this.snackBar.open(message, 'Close', {
      duration: 3000,
      panelClass: [style],
      horizontalPosition: 'end',
      verticalPosition: document.documentElement.clientWidth >= 1050 ? 'top' : 'bottom'
    });
  }
  // -------------------//
  // Close modal method //
  // -------------------//
  onNoClick(): void {
    this.dialogRef.close({returnArray: this.myDetailsArray});
  }
  removeMedia() {
    this.clearFileInput(document.getElementById('upload'));
    this.imageObj = null;
    this.fileName = "";
    this.viewFileName = "Add File";
  }
}
