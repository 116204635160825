<!-- ============================================================== -->
<!-- Main wrapper -->
<!-- ============================================================== -->
<div class="fullWScreen fullHScreen login-screen">
    
    <div class="background-pattern"></div>

    <div class="row">
        <div class="offset-lg-6 col-lg-6 form-content">
            <form [formGroup]="loginForm" (ngSubmit)="performLogin()">
                <div class="center login disabledAutofill" id="formWrapper">
                    <div id="circle" class="center">
                        <img id="logoImg" src="../../assets/img/agswift_logo.png" alt="logo adroit">
                    </div>
                    <div class="block loginSizes">
                        <i class="icon-adroit-buyer-01 login-icon"></i>
                        <mat-form-field class="fullWidth loginInput whiteOutline left bottom">
                            <mat-label class="loginWhiteLabel">Email or username</mat-label>
                            <input matInput type="text" formControlName="username" tabindex="1" required>
                            <button mat-button type="button" *ngIf="loginForm.get('username').value" matSuffix mat-icon-button aria-label="Clear" (click)="loginForm.get('username').patchValue('')">
                                <mat-icon class="xButton">close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="block loginSizes">
                        <i class="icon-adroit-candado login-icon"></i>
                        <mat-form-field class="fullWidth loginInput whiteOutline left bottom">
                            <mat-label class="loginWhiteLabel">Password</mat-label>
                            <input matInput type="password" formControlName="password" tabindex="2" required class="whiteOutline">
                            <button mat-button type="button" *ngIf="loginForm.get('password').value" matSuffix mat-icon-button aria-label="Clear" (click)="loginForm.get('password').patchValue('')">
                                <mat-icon class="xButton">close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <button mat-raised-button class="whiteRoundedButton block loginSizes" id="loginButton" type="submit" aria-label="Iniciar sesión">Log In</button>
                    <span class="forgot_password" (click)="password()">Forgot Password?</span>
                    <p></p>
                </div>
            </form>
        </div>
    </div>

    <div class="background-pattern-mobile"></div>
    
</div>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->

