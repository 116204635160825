<div *ngIf="!loaded" class="external-container fullbody">
  <div class="internal-container fullbody">
    <div class="spinner-container">
      <mat-spinner class="spinner"></mat-spinner>
    </div>
  </div>
</div>

<div
  class="external-container"
  [style.background-image]="getImageUrl(imageUrl)"
  *ngIf="loaded"
>

  <div
    #plantSwiper
    class="swiper-container"
    [swiper]="swiperConfig"
    (indexChange)="indexChange()"
  >


    <div [@fInT1]="'in'" class="toolbar" [ngClass]="{ hiden: ishiden }">

      <mat-icon tabindex="5" *ngIf="!isPlantPricesRole"  class="icon" (click)="goBack()" [ngClass]="{ hiden: ishiden }"> arrow_back_ios </mat-icon>


      <mat-icon  tabindex="10" class="icon" (click)="fullscreen()" [ngClass]="{ hiden: ishiden }">
          {{ isFullscreen ? "fullscreen_exit" : "fullscreen" }}</mat-icon
        >


      <mat-icon  tabindex="5" *ngIf="isPlantPricesRole"  class="icon" (click)="logout()" [ngClass]="{ hiden: ishiden }"> exit_to_app </mat-icon>

    </div>

    <ng-container *ngIf="(plants && plants.length <= 0) || !plants">
      <div class="no-data-container">
        PLANTS PRICES NOT FOUND
      </div>
      
    </ng-container>
    <!--  -->
    <ng-container *ngIf="plants && plants.length > 0">
      <div class="swiper-wrapper">
        <div class="main-container swiper-slide" *ngFor="let plant of plants">
          <div class="time">
            <h2>{{ currentDate }}</h2>
          </div>
          <div class="title">
            <h1>{{ plant.name | uppercase }}</h1>
          </div>
          <div class="logo">
            <img src="assets/img/whiteLogo.png" alt="" />
          </div>
          <div class="table">
            <h3 *ngIf="!plant.productPrices.length > 0" class="align-center">
              No Products
            </h3>
            <table
              #table
              *ngIf="plant.productPrices.length > 0"
              mat-table
              [dataSource]="plant.productPrices"
              class="fullWidth"
            >
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let product">
                  <h3>
                    {{ product.name }}
                  </h3>
                </td>
              </ng-container>
              <ng-container matColumnDef="price1">
                <th mat-header-cell *matHeaderCellDef>
                  <h3 class="text-center">#1</h3>
                </th>
                <td mat-cell *matCellDef="let product">
                  <h3 class="text-center">
                    {{
                      product.price1
                        ? (product.units ? getSymbol(product.units) : "$") +
                          (product.price1 | number: "0.2-2") +
                          (product.units ? getUnits(product.units) : "")
                        : "---"
                    }}
                  </h3>
                </td>
              </ng-container>
              <ng-container matColumnDef="price2">
                <th mat-header-cell *matHeaderCellDef>
                  <h3 class="text-center">#2</h3>
                </th>
                <td mat-cell *matCellDef="let product">
                  <h3 class="text-center">
                    {{
                      product.price2
                        ? (product.units ? getSymbol(product.units) : "$") +
                          (product.price2 | number: "0.2-2") +
                          (product.units ? getUnits(product.units) : "")
                        : "---"
                    }}
                  </h3>
                </td>
              </ng-container>
              <ng-container matColumnDef="price3">
                <th mat-header-cell *matHeaderCellDef>
                  <h3 class="text-center">#3</h3>
                </th>
                <td mat-cell *matCellDef="let product">
                  <h3 class="text-center">
                    {{
                      product.price3
                        ? (product.units ? getSymbol(product.units) : "$") +
                          (product.price3 | number: "0.2-2") +
                          (product.units ? getUnits(product.units) : "")
                        : "---"
                    }}
                  </h3>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
              <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
            </table>
          </div>
          <div class="extra">
            <h4>*Prices are subject to change without notice</h4>
          </div>
          <div class="figure">
            <img src="assets/img/adroit_figure.svg" class="" alt="" />
          </div>
          <div class="call"><h3>{{ plant && plant.phone ? 'Please text/Call '+ processPhone(plant.phone) : ''}}</h3></div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
      <div [class.hiden]="!navigation" class="swiper-button-prev"></div>
      <div [class.hiden]="!navigation" class="swiper-button-next"></div>
    </ng-container>
  </div>
</div>
