import {Component, Inject, OnInit, AfterViewInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {BranchService} from '../../../services/branch/branch.service';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';

export interface dataIn {
    button: string;
    title: string;
    subtitle: string;
    message: string[];
    maxValue: number;
    inputLabel: string;
    inputType: string;
    titleClass: string;
    required: boolean;
    checkbox: boolean;
    checkLabel: string;
    value: string;
    type: string;
    minText: number;
}

@Component({
  selector: 'app-modal-confirm-input',
  templateUrl: './modal-confirm-input.component.html',
  styleUrls: ['./modal-confirm-input.component.scss']
})

export class ModalConfirmInputComponent implements OnInit, AfterViewInit {
    confirmForm = this.fb.group({
        remarks: new UntypedFormControl({value: '', disabled: false},Validators.required),
        checkbox: new UntypedFormControl({value: false, disabled: false}),
    });
    moreSpecific: boolean;
    // input = new FormControl('', [Validators.required]);

    ngOnInit(){
        if( this.data.value ){
            this.confirmForm.get('remarks').patchValue(this.data.value)
        }
    }
    ngAfterViewInit(): void {
        if(this.data.checkLabel == 'Left Over'){
            this.confirmForm.get('checkbox').valueChanges.subscribe((value) =>{
                if(value) this.confirmForm.get('remarks').patchValue('Merma');
                else this.confirmForm.get('remarks').patchValue('');
            });
        }
    }

    constructor(
        public dialogRef: MatDialogRef<ModalConfirmInputComponent>,
        private branchService: BranchService,
        @Inject(MAT_DIALOG_DATA) public data: dataIn,
        private fb: UntypedFormBuilder,
    ) { }

    getBranch(){
        return this.branchService.currentBranch.getValue().name.toLowerCase()
    }

    confirm(){
        this.confirmForm.get('remarks').markAsTouched();
        if (this.data.required && this.confirmForm.get('remarks').value) {   
            // this.confirmForm.get('remarks').setErrors({'incorrect': true});
            if (this.data.checkbox){
                this.dialogRef.close({ confirmation: true, remarks:  this.confirmForm.get('remarks').value, checkbox:  this.confirmForm.get('checkbox').value });
            } else {
                if ( this.data.minText &&  this.confirmForm.get('remarks').value.length < this.data.minText){
                    this.moreSpecific = true;
                } else {
                    this.dialogRef.close({ confirmation: true, remarks:  this.confirmForm.get('remarks').value });    
                }
                
            }
        } else if (!this.data.required){
            if (this.data.checkbox){
                this.dialogRef.close({ confirmation: true, remarks:  this.confirmForm.get('remarks').value, checkbox:  this.confirmForm.get('checkbox').value });
            } else {
                this.dialogRef.close({ confirmation: true, remarks:  this.confirmForm.get('remarks').value });
            }
        } else {
            this.confirmForm.get('remarks').setErrors({'incorrect': true});
        }
    }
    dismiss(): void {
        this.dialogRef.close({ confirmation: false });
    }
}
