import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../../services/api/api.service';
import {SharingService} from '../../../services/sharing/sharing.service';
import {LoadingService} from '../../../services/loading/loading.service';
import {QueryFactory} from '../../../tableQueries/queryFactory';
import {debounceTime, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {BranchService} from '../../../services/branch/branch.service';
import {AuthService} from '../../../services/auth/auth.service';
import {ModalConfirmComponent} from '../../desicion-modals/modal-confirm/modal-confirm.component';
import * as uuid from 'uuid';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DateFormats } from 'src/app/models/date-format/date-format-input';
import * as fileSaver from 'file-saver';

export interface dataIn {
  subject: any;
  filterProp: string;
  relatedTo: string;
  isPurchases: boolean;

  title: string;
  isNew: boolean;
  prevContract: any;
  branch: any;
  isClone: boolean;
}

export class objectValidator {
  static validData(control: UntypedFormControl) {
    if (typeof control.value !== 'object') {
      return ({validData: true});
    } else {
      return (null);
    }
  }
}
@Component({
  selector: 'app-railcars-modal',
  templateUrl: './railcars-modal.component.html',
  styleUrls: ['./railcars-modal.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: DateFormats }
  ]
})
export class RailcarsModalComponent implements OnInit, AfterViewInit, OnDestroy {
  editTransloader: boolean;
  
  getBranch(){
    return this.branchService.currentBranch.getValue().name.toLowerCase()
  }
  // --------------------------- //
  // Local variables declaration //
  // --------------------------- //
  private onDestroy = new Subject<void>();
  public ownerOptions: Observable<any>;
  public availableOptions: Observable<any>;
  public productOptions: Observable<any>;
  public plantOptions: Observable<any>;
  public transloaderOptions: Observable<any>;
  public buyerOptions: Observable<any>;
  public supplierOptions: Observable<any>;
  public participantBelongs: any[] = [];
  public plants = [];
  public today = new Date();
  public noteContent = '';
  private datePipe: DatePipe;
  public rowId: Object;
  public quillConfig = {
    toolbar: [
      ['bold', 'underline'],        // toggled buttons

      [{ list: 'bullet' }],
      [{ indent: '-1'}, { indent: '+1' }],          // outdent/indent
      [{ size: ['small', false, 'large'] }],  // custom dropdown

      [{ color: [] }, { background: [] }],          // dropdown with defaults from theme
    ]
  };
  public currentRole: any = 'purchases';
  private prevReminder: any = false;
  private hasChanges = false;
  public isPurchases = false;
  public userRole = '';

  public isPulses = false;
  public isWinnipeg = false;
  productGrades: any = [
    { name: '1' },
    { name: 'X2' },
    { name: '2' },
    { name: 'X3' },
    { name: '3' },
    { name: 'feed' },
  ];
  public isTransports = false;
  public isClients = false;
  public isBuyers = false;
  public isSuppliers = false;
  public lastPurchaseCounter = 0;
  public lastSalesCounter = 0;

  public fileName = "";  
  public sampleImgLbl = "Add Sample Image";
  sampleImg = null;

  public sgsImgLbl = "Add SGS Cert Image";
  sgsImg = null;

  public scaleImgLbl = "Add Rail Bol Image";
  scaleImg = null;
  
  public cfiaImgLbl = "Add CFIA ID Image";
  cfiaImg = null;
  
  public recfiaImgLbl = "Add CFIA Re-Submission Image";
  recfiaImg = null;

  public bulkWeightImgLbl = "Add Bulk Weight Image";
  bulkWeightImg = null;

  public images = ['sampleImg','sgsImg','scaleImg','cfiaImg','recfiaImg','bulkWeightImg']

  public noRail = true;
  public noSample = true;
  public noSgs = true;
  public noCfia = true;

  public isValidDocs = null;
  public isValidSgs = null;
  public isValidScale = null;
  public isValidCfia = null;
  public isValidcfiaReSub = null;
  public isValidbulkWeight = null;

  public disableButton = false;

  // Component constructor //
  constructor(
      private dialogRef: MatDialogRef<RailcarsModalComponent>,
      private fb: UntypedFormBuilder,
      public router: Router,
      private apiService: ApiService,
      private snackBar: MatSnackBar,
      private loadingService: LoadingService,
      private queryFactory: QueryFactory,
      private dialog: MatDialog,
      private branchService: BranchService,
      private sharingService: SharingService,
      @Inject(MAT_DIALOG_DATA) public dataIn: dataIn,
      private authService: AuthService
  ) { }

  get getDeleteAccess(): boolean {
    return this.authService.currentUserValue?.user?.isPlantManager && !this.dataIn.prevContract.isLoaded && !this.dataIn.prevContract.isReleased;
  }

  // Form crud-inputs & validations declaration //
  addForm = this.fb.group({
    plant : new UntypedFormControl({ value: '', disabled: true }),
    product : new UntypedFormControl({ value: '', disabled: true }),
    productGrade : new UntypedFormControl({ value: '', disabled: true }),
    dateLoaded : new UntypedFormControl({ value: null, disabled: true }),
    dateReceived : new UntypedFormControl({ value: new Date(), disabled: true }, Validators.required),
    dateReleased : new UntypedFormControl({ value: null, disabled: true }),
    datePickedUp : new UntypedFormControl({ value: null, disabled: true }),
    railCarNo : new UntypedFormControl({ value: '', disabled: false }, Validators.required),
    demmuragePeriod : new UntypedFormControl({ value: '', disabled: false }),
    destination : new UntypedFormControl({ value: '', disabled: true }),
    transloader : new UntypedFormControl({ value: '', disabled: true }),
    railSealsNo : new UntypedFormControl({ value: '', disabled: true }),
    loadWeight : new UntypedFormControl({ value: '', disabled: true }),
    bolWeight : new UntypedFormControl({ value: '', disabled: false }),
    cfiaWeight : new UntypedFormControl({ value: false, disabled: false }),
    bolNo : new UntypedFormControl({ value: '', disabled: false }),
    unloadWeight : new UntypedFormControl({ value: '', disabled: true }),
    sgsImg : new UntypedFormControl({ value: null, disabled: true }),
    difference : new UntypedFormControl({ value: '', disabled: true }), 
    shiftLoaded : new UntypedFormControl({ value: '', disabled: true }),
    comments : new UntypedFormControl({ value: '', disabled: false }),
    sgsWaybill : new UntypedFormControl({ value: null, disabled: false }),
    cfiaWaybill: new UntypedFormControl({ value: null, disabled: false }),
    cfiaReSub : new UntypedFormControl({ value: false, disabled: false }),
    bulkWeight : new UntypedFormControl({ value: false, disabled: false }),
    cfiaId : new UntypedFormControl({ value: false, disabled: false }),
    railBol : new UntypedFormControl({ value: false, disabled: false }),
    sgsCert : new UntypedFormControl({ value: false, disabled: false }),
    cfiaWaybillRe : new UntypedFormControl({ value: null, disabled: false }),
    sampleImg : new UntypedFormControl({ value: null, disabled: false }),
    scaleImg : new UntypedFormControl({ value: null, disabled: false }),
    cfiaImg : new UntypedFormControl({ value: null, disabled: false }),
    recfiaImg : new UntypedFormControl({ value: null, disabled: false }),
    bulkWeightImg : new UntypedFormControl({ value: null, disabled: false }),
    validDocs : new UntypedFormControl({ value: null, disabled: false })
  });
  
  downloadSigned(data: any) {
    window.open( data, '_blank' ); 
  }

  async getPlants() {
    try {
      const whereObj = {
        and: [
          { branchId: this.branchService.currentBranch.getValue().id },
          { ownPlant: true }
        ]
      };
  
      const plantsQuery = this.queryFactory.generateSimpleGetQuery('Plants', whereObj);
      const plants: any = await this.apiService.getDataObjects(plantsQuery).toPromise();
      this.plants = plants.length ? plants : [];
      const patchPlant = this.plants.find((p) => p.id === '5cda19826afb80377a7717d6') ? this.plants.find((p) => p.id === '5cda19826afb80377a7717d6') : this.plants[0];
      if (this.dataIn.isNew) this.addForm.get('plant').setValue(patchPlant.id);
    } catch (error) {
      console.log(error);
    }
  }

  changeReminder(data: any) {
    if (data.checked) {
      this.addForm.get('reminderDate').validator = Validators.required;
    } else {
      this.addForm.get('reminderDate').setErrors(null);
    }
  }

  setPrevTime(date: Date) {
    const tempDate = new Date(date);
    // console.log(tempDate);
    const time: string = (tempDate.getHours() > 9 ? tempDate.getHours() : '0' + tempDate.getHours()) + ':' + (tempDate.getMinutes() > 9 ? tempDate.getMinutes() : '0' + tempDate.getMinutes());
    return time;
  }

  ngOnInit() {
    // this.setDocs();
    this.setControls();
    this.setProductControl();
    this.setTransloaderControl();
    
    this.userRole = this.authService.currentUserValue.user.role;
    this.setPermitions();

    let contract = this.dataIn.prevContract;
    if (!this.dataIn.isNew || this.dataIn.isClone) {
      this.addForm.patchValue({
        ...contract,
        grade: this.productGrades.find(grade => grade.name == this.dataIn.prevContract.grade),
        plant: this.dataIn.prevContract.plant ? this.dataIn.prevContract.plant.id : '',
        cfiaWeight: contract.cfiaWeight ? contract.cfiaWeight.toFixed(2) : ''
      });

      for (const image of this.images) {
        if (this.dataIn.prevContract[image]){
          this[image] = this.dataIn.prevContract[image];
          this[image + 'Lbl'] = (image == 'scaleImg' ? 'Rail Bol' : (image.charAt(0).toUpperCase() + image.substring(0, (image.length - 3)).slice(1))) +  ' Image Added';
        }
      }

      if (contract.difference) this.addForm.get('difference').patchValue(contract.difference.toFixed(2));
      if (contract.bolWeight) this.addForm.get('bolWeight').patchValue(contract.bolWeight.toFixed(2));
    }

    if(this.dataIn.isClone){
      this.addForm.patchValue({
        railCarNo: ''
      });
    }

    if (!this.dataIn.isNew) {
      const railcar = this.dataIn.prevContract;
      this.noSample = railcar.sampleImg ? false : true;
      this.noSgs = railcar.sgsImg ? false : true;
      this.noRail = railcar.scaleImg ? false : true;
      this.noCfia = railcar.cfiaImg ? false : true;
    }

    this.isPurchases = this.dataIn.isPurchases;
  }

  async setControls() {
    this.setLoadControl();
    this.setUnloadControl();
    await this.getPlants();
  }

  setLoadControl() {
    this.addForm.get('loadWeight').valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((data) => {
      this.setDiference();
    });
  }

  setUnloadControl() {
    this.addForm.get('unloadWeight').valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((data) => {
      this.setDiference();
    });
  }

  setDiference() {
    const loadWeight = this.addForm.get('loadWeight').value ? this.addForm.get('loadWeight').value : 0;
    const unloadWeight = this.addForm.get('unloadWeight').value ? this.addForm.get('unloadWeight').value : 0;
    const diference =  loadWeight - unloadWeight;
    this.addForm.get('difference').patchValue(diference.toFixed(2));
  }

  isValidId(id) {
    return id && id !== '' && id !== '-';
  }

  setPermitions() {
    if ( this.userRole === 'branchTrader') {
      this.isPulses = true;
    } else if (this.userRole === 'winnipeg') {
      this.isWinnipeg = true;
    } else if (this.userRole === 'generalAdmin' || this.userRole === 'branchAdmin') {
      this.isWinnipeg = true;
      this.isPulses = true;
    }

    if (this.userRole === 'generalAdmin' ){
      this.addForm.get('loadWeight').enable();
    }

    if (this.authService.currentUserValue.user.editStuffingReport ){
      this.editTransloader = true;
      this.addForm.get('transloader').enable();
    }

    
  }

  setProductControl() {
    this.productOptions = this.addForm.get('product').valueChanges.pipe(
        debounceTime(250),
        startWith(''),
        switchMap(value => this.loadProductOptions(value))
    );
  }

  setPlantControl() {
    this.plantOptions = this.addForm.get('plant').valueChanges.pipe(
        debounceTime(250),
        startWith(''),
        switchMap(value => this.loadPlantOptions(value))
    );
  }

  setTransloaderControl() {
    this.transloaderOptions = this.addForm.get('transloader').valueChanges.pipe(
        debounceTime(250),
        startWith(''),
        switchMap(value => this.loadTransloaderOptions(value))
    );
  }

  ngAfterViewInit() {
    (this.authService.currentUserValue.user.role == 'generalAdmin' || this.authService.currentUserValue.user.editLoadWeight) ? this.addForm.get('loadWeight').enable(): this.addForm.get('loadWeight').disable();
  }

  displayFn(user?: any): string | undefined {
    return user ? user.name : undefined;
  }

  loadProductOptions(dataSearch: string) {
    const searchQuery = this.queryFactory.setSearchQuery(dataSearch, ['name']);
    const andObject: any[] = [
      searchQuery,
      {branchId: this.branchService.currentBranch.getValue().id}
    ];
    const getClientsQuery = this.queryFactory.generateGetQuery('Products', {and: andObject}, 25, 0, 'name ASC', []);
    return new Observable<any>((objs) => this.apiService.getDataObjects(getClientsQuery).subscribe((data: any) => {
      objs.next(data);
    }));
  }

  loadPlantOptions(dataSearch: string) {
    const searchQuery = this.queryFactory.setSearchQuery(dataSearch, ['name']);
    const andObject: any[] = [
      searchQuery,
      {branchId: this.dataIn.branch.id}
    ];
    //console.log(andObject)
    const getClientsQuery = this.queryFactory.generateGetQuery('Plants', {and: andObject}, 25, 0, 'name ASC', []);
    return new Observable<any>((objs) => this.apiService.getDataObjects(getClientsQuery).subscribe((data: any) => {
      //console.log(data)
      objs.next(data);
    }));
  }

  loadTransloaderOptions(dataSearch: string) {
    const searchQuery = this.queryFactory.setSearchQuery(dataSearch, ['name']);
    const andObject: any[] = [
      searchQuery,
      {branchId: this.dataIn.branch.id},
    ];
    const getClientsQuery = this.queryFactory.generateGetQuery('Transloaders', {and: andObject}, 25, 0, 'name ASC', []);
    return new Observable<any>((objs) => this.apiService.getDataObjects(getClientsQuery).subscribe((data: any) => {
      objs.next(data);
    }));
  }

  // on submit we check wich images will be added after that we do add the railcar
  // we have a add file per image afer add one of them we check if we need to add another one
  async performRequest() {
    if(this.addForm.status === 'INVALID'){
      this.presentToast('Error in form', 'yellow-snackbar');
    } else {
      
      this.disableButton = true;
      this.loadingService.showLoader.next(true);

      for (const [i, image] of this.images.entries()) {
        if (this[image + 'Lbl'].includes('File: ')) {
          const fileSelect = document.getElementById(image);
          const files = fileSelect['files'];
          const file = files[0];
          let fileName = file.name.split('.');
          fileName = fileName[fileName.length - 1];
          fileName = uuid.v4() + '.' + fileName;

          const res: any = await this.apiService.addFile(file, fileName, 'container').pipe(takeUntil(this.onDestroy)).toPromise();
            
          this[image] = res.result.files.container[0].providerResponse.location;
        }
        if (i == (this.images.length - 1)) {
          this.setRequest()
        }
      }
    }
  }

  preAddImage(id) {
    const imageSelect = document.getElementById(id);
    const imagesF = imageSelect['files'];
    console.log(imagesF);

    if(imagesF.length > 0){
      const file = imagesF[0];
      this.fileName = file.name.split('.');
      this.fileName = this.fileName[this.fileName.length - 1];
      this[id+"Lbl"] = "File: " + file.name;
      this[id] = file;
    }
  }

  setRequest(){
    if(this.sampleImg){
      this.isValidSgs = ((!this.addForm.get('sgsCert').value && this.sgsImg) || this.addForm.get('sgsCert').value) ? true : false;
      this.isValidScale = ((!this.addForm.get('railBol').value && this.scaleImg) || this.addForm.get('railBol').value) ? true : false;
      this.isValidCfia = ((!this.addForm.get('cfiaId').value && this.cfiaImg) || this.addForm.get('cfiaId').value) ? true : false;
      this.isValidcfiaReSub = ((!this.addForm.get('cfiaReSub').value && this.recfiaImg) || this.addForm.get('cfiaReSub').value) ? true : false;
      this.isValidbulkWeight = ((!this.addForm.get('bulkWeight').value && this.bulkWeightImg) || this.addForm.get('bulkWeight').value) ? true : false;
    }

    this.isValidDocs = (this.isValidSgs || this.isValidScale || this.isValidCfia || this.isValidcfiaReSub || this.isValidbulkWeight) ? true : false;
    
    let formRailcar = {
      ...this.addForm.value,
      transloaderId : this.addForm.get('transloader').value ? this.addForm.get('transloader').value.id : null,
      productId : this.addForm.get('product').value ? this.addForm.get('product').value.id : null,
      plantId : this.addForm.get('plant').value ||  null,
      sampleImg : this.sampleImg,
      sgsImg : this.sgsImg,
      scaleImg : this.scaleImg,
      cfiaImg : this.cfiaImg,
      recfiaImg : this.recfiaImg,
      bulkWeightImg : this.bulkWeightImg,
      isTruck : false,
      validDocs: this.isValidDocs
    };
    delete formRailcar.transloader
    delete formRailcar.product
    delete formRailcar.plant
    // delete formRailcar.validDocs
  
    if(this.dataIn.isNew || this.dataIn.isClone){
      formRailcar = {
        ...formRailcar,
        createdAt : new Date(),
        branchId: this.dataIn.branch.id,
        enterById: this.authService.currentUserValue.user.id
      }
      
      this.apiService.addDataObject(formRailcar, 'RailCars').pipe(takeUntil(this.onDestroy)).subscribe((data:any) => {
        this.rowId = data.id;
        this.hasChanges = true;
        this.presentToast('Railcar created successfully', 'green-snackbar');
        this.loadingService.showLoader.next(false);
        this.onNoClick();
      }, () => {
        this.presentToast('Connection rejected', 'red-snackbar');
        this.disableButton = false;
        this.loadingService.showLoader.next(false);
      });
    } else {  
      console.log('formulario editado',formRailcar);
      
      this.apiService.editDataObject(this.dataIn.prevContract.id, formRailcar, 'RailCars').pipe(takeUntil(this.onDestroy)).subscribe(() => {
          this.rowId= this.dataIn.prevContract.id;
          this.hasChanges = true;
            this.presentToast('Railcar edited successfully', 'green-snackbar');
            this.loadingService.showLoader.next(false);
            this.hasChanges = true;
            this.onNoClick();
        }, (e) => {
          this.presentToast('Connection rejected', 'red-snackbar');
          this.disableButton = false;
          this.loadingService.showLoader.next(false);
      });
    }
  }

  deleteObject() {
    const dialogRef = this.dialog.open(ModalConfirmComponent, {
      data: {
        button: 'Delete',
        title: 'Railcar',
        subtitle: 'Are you sure about deleting this Railcar?',
        message: [
        ]
      },
      panelClass: 'usaModal',
      autoFocus: false
    });
    dialogRef.afterClosed().pipe(takeUntil(this.onDestroy)).subscribe(result => {
      if (result?.confirmation) {
        this.apiService.deleteDataObject('Railcars', this.dataIn.prevContract.id).pipe(takeUntil(this.onDestroy)).subscribe(() => {
          this.hasChanges = true;
          this.presentToast('Railcar deleted successfully', 'green-snackbar');
          this.onNoClick();
        });
      }
    });
  }

  onUserHasNotReply() {
    if (this.dataIn.prevContract.hasNotReply) {
      const tagIndex = this.dataIn.subject ? this.dataIn.subject.tags.findIndex(tag => tag === 'Not reply') : -1;
      if (tagIndex !== -1) {
        this.dataIn.subject.tags.splice(tagIndex, 1);
        this.apiService.editDataObject(this.dataIn.subject.id, {
          tags: this.dataIn.subject.tags
        }, 'Clients').pipe(takeUntil(this.onDestroy)).subscribe(() => {
          this.presentToast('Note deleted successfully', 'green-snackbar');
          this.hasChanges = true;
          this.onNoClick();
        }, () => {
          this.presentToast('Note deleted, but tag was not removed', 'yellow-snackbar');
          this.hasChanges = true;
          this.onNoClick();
        });
      } else {
        this.hasChanges = true;
        this.presentToast('Note deleted successfully', 'green-snackbar');
        this.onNoClick();
      }
    } else {
      this.presentToast('Note deleted successfully', 'green-snackbar');
      this.hasChanges = true;
      this.onNoClick();
    } 
  }

  presentToast(message: string, style: string) {
    this.snackBar.open(message, 'Close', {
      duration: 3000,
      panelClass: [style],
      horizontalPosition: 'end',
      verticalPosition: document.documentElement.clientWidth >= 1050 ? 'top' : 'bottom'
    });
  }

  onNoClick(): void {
    this.dialogRef.close({changes: this.hasChanges, rowId: this.rowId});
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.unsubscribe();
  }
}