import { Injectable, Injector, OnDestroy} from '@angular/core';
import { BehaviorSubject, Observable, Subject} from 'rxjs';
import { map, takeUntil} from 'rxjs/operators';
import { Router} from '@angular/router';
import {ApiService} from '../api/api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment'
import { ROLES } from './Roles';
import { Location } from '@angular/common';
import * as CryptoJS from 'crypto-js';

enum validations {
  'basic',
  'navigation',
  'custom'
}
@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

  // --------------------------- //
  // Local variables declaration //
  // --------------------------- //
  private url = environment.apiUrl;
  // private url = 'https://req.adroitoverseas.net/api/';
  // private url = 'https://winnipegapi.adroitoverseas.net/api/';
  // private url = 'http://localhost:3000/api/';
  // private url = 'http://4c70e2c78084.ngrok.io/api/';

  private onDestroy = new Subject<void>();
  private key = 'LH52NZe7Av';

  private currentUserSubject: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public userObservable: Observable<any> = this.currentUserSubject.asObservable();

  public currentUser: Observable<any>;
  public firstLoad = true;
 
  public searchBarData: any;
  public reimBarData: any;
  public searchBar = '';

  public socketAuthored = false;

  // ------------------- //
  // Service constructor //
  // ------------------- //
  constructor(
      private http: HttpClient,
      private router: Router,
      private apiService: ApiService,
      // private socket: Socket
      private injector: Injector,
      private _location: Location
  ) {

    /* listener to save user on localStorage every time it changes */
    this.currentUserSubject.pipe(
        takeUntil(this.onDestroy)
    ).subscribe((changedUser) => {

      if (changedUser && !this.firstLoad) {
        localStorage.setItem('LH52NZe7Av', this.encrypt(JSON.stringify(changedUser), 'LH52NZe7Av'));
      }
    });

    const data: any = localStorage.getItem('LH52NZe7Av') || '';
    const decryptedData = this.decrypt(data, this.key);
    const user: any = decryptedData ? JSON.parse(decryptedData) : '';

    /* 1.- next of found user 2.- logout */
    if (user) {
      this.currentUserSubject.next(user);
      this.firstLoad = false;
    }

  }

  private isJsonString(str: string): boolean {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
}

  public encrypt(txt: any, key: string): string {
    return CryptoJS.AES.encrypt(txt, key).toString();
  }

  public decrypt(txtToDecrypt: string, key: string) {
    try {
      const decryptedText = CryptoJS.AES.decrypt(txtToDecrypt, key).toString(CryptoJS.enc.Utf8);
      return decryptedText;
    } catch (error) {
      return '';
    }
  }

  // ---------------- //
  // Update user data //
  // ---------------- //
  public updateUserData() {

    let userObject = this.currentUserValue;
    this.apiService.getDataObject('AppUsers', userObject.user.id).pipe(takeUntil(this.onDestroy)).subscribe((updatedUser) => {
      if (JSON.stringify( userObject.user) !== JSON.stringify(updatedUser)) {
        userObject.user = updatedUser;
        this.currentUserSubject.next(userObject);
      }
    });
  }

  // ------------------------ //
  // Get value of logged user //
  // ------------------------ //
  public get currentUserValue(): any {
    return this.currentUserSubject.getValue();
  }

  // --------------------- //
  // Perform login request //
  // --------------------- //
  login(credentials: any) {
    return new Observable(obj => this.http.post(this.url + 'AppUsers/login?include=user', credentials).subscribe((user: any) => {
      if (user && user.id) {
        localStorage.setItem('LH52NZe7Av', this.encrypt( JSON.stringify(user), 'LH52NZe7Av' ));
        this.currentUserSubject.next(user);
        obj.next(user);
      }
    }, () => {
      obj.next(false);
    }));
  }

  setUserManually(userObj: any) {
    localStorage.setItem('LH52NZe7Av', this.encrypt(JSON.stringify(userObj), 'LH52NZe7Av'));
    this.currentUserSubject.next(userObj);
  }

  // ---------------------- //
  // Perform logout request //
  // ---------------------- //
  logout(req: boolean) {

    /*if (this.socket) {
      this.socket.emit('disconnect');
    }*/
    if(req) { 
      const dataLSBranch = localStorage.getItem('zIeNztZGUg') || '';
      const decryptedData = this.decrypt(dataLSBranch, 'zIeNztZGUg');
      const branch: any = (decryptedData) ? JSON.parse(decryptedData) : '';
  
      const dataLSUser: any = localStorage.getItem('LH52NZe7Av') || '';
      const dataDecrypted = this.decrypt(dataLSUser, 'LH52NZe7Av').toString(CryptoJS.enc.Utf8);
      const dataUser = (dataDecrypted) ? JSON.parse(dataDecrypted) : '';
  
      if (dataUser && branch) {
        this.apiService.editDataObject(dataUser.user.id, {prevBranch:branch.id}, "AppUsers")
        .pipe(takeUntil(this.onDestroy)).subscribe((data)=>{
            this.http.post<any>(
                this.url + 'AppUsers/logout',
                '',
            ).pipe(takeUntil(this.onDestroy)).subscribe(() => {
              this.cleanSession();
            }, () => {
              this.cleanSession();
            });
        });  
      }
    } else {
      this.cleanSession();

    }
    

  }

  // ------------------ //
  // Clean localForage //
  // ------------------ //
  cleanSession() {
    localStorage.clear();
    this.currentUserSubject.next(false);
    this.router.navigate(['/login']).catch();
  }

  // ------------------ //
  // useRemoteMethod    //
  // ------------------ //
  useRemoteMethod(model: string, method: string, params: object){
    let body = {
      ...params
    };
    return this.http.post(this.url + model + '/' + method, body);
  }

  // ------------------ //
  // useRemoteMethod    //
  // ------------------ //
  useRemoteMethodWithToken(model: string, method: string, params: object, token: string){
    let body = {
      ...params
    };
    return this.http.post(this.url + model + '/' + method, body, {headers: new HttpHeaders({Authorization: token}) });
  }

  validateRoles(type: keyof typeof validations, roles:string[], country?: string){
    const userRole = this.currentUserValue.user.role;
    let checkRole = roles.some(role => role === userRole || userRole === ROLES.BRANCH_ADMIN || userRole === ROLES.GENERAL_ADMIN ? true : false);
    if (country && country == 'Mexico') checkRole = true;

    switch (type) {
      case validations[0]:
        return checkRole
      case validations[1]:
        return checkRole ? true : this._location.back() 
      case validations[2]:
        return roles.some(role => role === userRole ? true : false)
    } 
  }

  // -------------------- //
  // On destroy lifecycle //
  // -------------------- //
  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  // -------------------- //
  // Contract permissions //
  // -------------------- //
  public getContractPermissions(action: string): boolean {
    const permissionsObject = this.currentUserValue.user.contractPermissions;
    return (permissionsObject ? permissionsObject[action] : true);
  }
  public getContractPermissionsMex(action: string): boolean {
    const permissionsObject = this.currentUserValue.user.mexicoContractPermissions;
    return (permissionsObject ? permissionsObject[action] : true);
  }
}
