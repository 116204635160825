import {Component, OnDestroy} from '@angular/core';
import {Overlay, OverlayRef} from "@angular/cdk/overlay";
import {LoadingService} from "./services/loading/loading.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {ComponentPortal} from "@angular/cdk/portal";
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'loader',
  template: '<mat-spinner></mat-spinner>',
})
export class LoaderComponent {}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements  OnDestroy {
  private onDestroy = new Subject<void>();
  private overlayRef: OverlayRef;
  private loaderPortal;
  title = 'Adroit CRM';
  constructor(
      private overlay: Overlay,
      private loadingService: LoadingService,
      private swUpdate: SwUpdate
  ) {

    //This is for update the app when new version is available
    if (this.swUpdate.isEnabled) {
      // Check for updates every hour

      this.swUpdate.available.subscribe(() => {
        if (confirm('New version available. Load New Version?')) {
          this.swUpdate.activateUpdate().then(() => document.location.reload());
        }
      });

      this.swUpdate.activated.subscribe(() => {
        console.log('Service worker activated');
      });
    }

    this.loaderPortal = new ComponentPortal(LoaderComponent);
    this.loadingService.onLoaderChange().pipe(takeUntil(this.onDestroy)).subscribe((res) => {
      if (res) {
        const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();
        this.overlayRef = this.overlay.create({
          hasBackdrop: true,
          backdropClass: 'loader-backdrop',
          positionStrategy
        });
        this.overlayRef.attach(this.loaderPortal);
        setTimeout(() => {
          if (this.overlayRef) {
            this.overlayRef.dispose();
          }
        }, 5000);
      } else {
        if (this.overlayRef) {
          this.overlayRef.dispose();
        }
      }
    });
  }
  // --------------------- //
  // ON DESTROY LIFE CYCLE //
  // --------------------- //
  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.unsubscribe();
  }
}
