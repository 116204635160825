import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BranchService } from '../../../services/branch/branch.service';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DateFormats } from '../dubai-inward-modal/dubai-inward-modal.component';
import * as moment from 'moment';
import { AuthService } from '../../../services/auth/auth.service';
import { QueryFactory } from '../../../tableQueries/queryFactory';
import { ApiService } from '../../../services/api/api.service';
import { debounceTime, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadingService } from '../../../services/loading/loading.service';
import { ModalConfirmComponent } from '../../desicion-modals/modal-confirm/modal-confirm.component';
import { ModalConfirmInputComponent } from '../../../modals/desicion-modals/modal-confirm-input/modal-confirm-input.component';
import { TimezoneService } from '../../../services/timezone/timezone.service';
import { fadeIn, fadeInBottom, fadeInTop } from 'src/app/animations/animatedComponents';
import * as uuid from 'uuid';
import { GeneralService } from 'src/app/services/general/general.service';

export interface DataIn {
  subject: any;
  relatedTo: string;
  model: string;
  filterProp: string;
  isPurchases: boolean;
  isTrading: boolean;
  prevDeal: any;
  selectType: boolean;
  dissableContent: boolean;

  title: string;
  isNew: boolean;
  yearNumber: number;
  branch: any;
}

export class objectValidator {
  static validData(control: UntypedFormControl) {
    if (typeof control.value !== 'object') {
      return ({validData: true});
    } else {
      return (null);
    }
  }
}

@Component({
  selector: 'app-winnipeg-grain-deals',
  templateUrl: './winnipeg-grain-deals.component.html',
  styleUrls: ['./winnipeg-grain-deals.component.scss'],
  providers: [ 
    { provide: MAT_DATE_FORMATS, useValue: DateFormats }
  ],
  animations: [fadeIn, fadeInBottom, fadeInTop]
})
export class WinnipegGrainDealsComponent implements OnInit {
  packs: any;
  branchCurrency: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dataIn: DataIn,
    private dialogRef: MatDialogRef<WinnipegGrainDealsComponent>,
    private branchService: BranchService,
    public authService: AuthService,
    private queryFactory: QueryFactory,
    private apiService: ApiService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private loadingService: LoadingService,
    private dialog: MatDialog,
    private _timeZone: TimezoneService,
    public generalService: GeneralService
  ) { }

  public reviewedContractForm = new UntypedFormControl({ value: false, disabled: false });
  public isBroker = new UntypedFormControl({ value: this.dataIn?.prevDeal?.brokerName ? true: false , disabled: false });
  public brokerControls = ['brokerName','brokerage','brokerageType'];

  public isEditingClient = false;
  public isEditingDeal = false;

  public validUser = false;
  public reviewedContract = false;
  public validUsers = [
    'jose@adroitoverseas.com',
    'yashasvi@adroitoverseas.com'
  ];

  private onDestroy = new Subject<void>();
  public buyerOptions: Observable<any>;
  public supplierOptions: Observable<any>;
  public grainOptions: Observable<any>;
  public authorizeOptions: Observable<any>;


  public today = moment().tz("America/Vancouver").format();
  public todayPlus30 = moment().tz("America/Vancouver").add(30, 'days').format();
  public likedMail = true;
  public signedFileName = "Add Signed Contract";
  public unsignedContractName = "Add Unsigned Contract File";
  public fileName = "";
  public imageObj = [];

  public isBuyers = false;
  public client = ''

  public isProducts = false;
  public isGrains = false;
  
  public subjectConfigured = false;
  
  public isSpecifications = false;
  public grainLetter = '';

  public showResendo = true;
  public isResend = false;

  public confirmed = false;

  public statusHistoria;
  public isBas = false;
  public isCanceled = false;
  public isDone = false;
  public willDeal = true;

  public isFob = false;

  public disableButton = false;

  public hasChanges = false;
  public exchange;
  
  public origins: any[];
  OriginGrain = 'USA';

  productGrades: any = [
    { name: '1' },
    { name: 'X2' },
    { name: '2' },
    { name: 'X3' },
    { name: '3' },
    { name: 'N/A' },
  ];
  statusHistory: object = [
    { appUserId: "string"},
    { reason: "string"},
    { date: "string"},
    { type: "string"},
  ]
  public tagEmails: string[] = [
    this.authService.currentUserValue.user.email,
    
  ];

  public tagEmail = new UntypedFormControl({value: '', disabled: false});

  public isResendo = new UntypedFormControl({value: false, disabled: false});

  addForm = this.fb.group({
    // PRODUCT - GRAIN DATA
    quantity: new UntypedFormControl({value: '', disabled: false}, Validators.required),
    units: new UntypedFormControl({value: this.dataIn.isTrading ? 'MT' : 'BU', disabled: false}, Validators.required),
    grade: new UntypedFormControl({value: '', disabled: false}, Validators.required),
    // DEAL DATA    
    // contractReferenceNo: new FormControl({value: '', disabled: false}),
    adroitOrderId: new UntypedFormControl({value: '', disabled: (this.dataIn.isNew && !this.reviewedContract) || !this.dataIn.isNew}, Validators.required),
    price: new UntypedFormControl({value: '', disabled: false}, [Validators.required, Validators.min(1)]),
    paymentTerms: new UntypedFormControl({value: '', disabled: false}, Validators.required),
    profitability: new UntypedFormControl({value:'', disabled:false},[]),
    priceUnits: new UntypedFormControl({value: '', disabled: false}, Validators.required),
    shippingUnit: new UntypedFormControl({value: 'Truck', disabled: false}, !this.dataIn.isPurchases ? Validators.required : null),
    shipmentTerms: new UntypedFormControl({value: this.dataIn.isTrading ? 'TRACK' : 'DLVD', disabled: false}, Validators.required),
    shipmentAddress: new UntypedFormControl({value: '', disabled: false}, Validators.required),
    shipmentFrom: new UntypedFormControl({value: this.dataIn.isNew ? this.today : '', disabled: false}, Validators.required),
    shipmentTo: new UntypedFormControl({value: this.dataIn.isNew ? this.todayPlus30 : '', disabled: false}, Validators.required),    notificationReminder: new UntypedFormControl({value: true, disabled: false}),
    
    terms: new UntypedFormControl({value: '', disabled: false}, Validators.required),
    
    comments: new UntypedFormControl({value: '', disabled: false}),
    companyComments: new UntypedFormControl({value: '', disabled: false}),
    
    confirmRead: new UntypedFormControl({value: false, disabled: false}),
    emailTitle: new UntypedFormControl({value: null, disabled: false}),
    
    fx: new UntypedFormControl({value:'', disabled: true},[Validators.required]),

    
    brokerName: new UntypedFormControl({value: '', disabled: false}),
    brokerage: new UntypedFormControl({value: '', disabled: false}),
    brokerageType: new UntypedFormControl({value: '', disabled: false}),
    brokerNote: new UntypedFormControl({value: '', disabled:false}),
    
    //isBasis 
    isBasis: new UntypedFormControl({value: false, disabled: false}),
    basis: new UntypedFormControl({value: 0, disabled: false}),
    futureMonth: new UntypedFormControl ({value: '', disabled: false}),
    futureYear: new UntypedFormControl ({value: '', disabled: false}),
    futureBasis: new UntypedFormControl({value: 0, disabled: false}),

    exchangeRate: new UntypedFormControl({value: '', disabled:true}), 
    freightRate: new UntypedFormControl({value: null, disabled:false}, Validators.required),
    zone: new UntypedFormControl({value: '', disabled:false}),
    
    // counterParty: new FormControl({value: '', disabled:false})
    packing: new UntypedFormControl({value: 'Bulk', disabled: false}),

    
    //isSpecifications
    specifications: new UntypedFormControl({value: '', disabled: false}),
    qtyComments: new UntypedFormControl({value: '', disabled: false}),

    freight: new UntypedFormControl({value: '', disabled: false}),
    documents: new UntypedFormControl({value: '', disabled: false}),
    authorizedBy: new UntypedFormControl({value: '', disabled: false}, [Validators.required, objectValidator.validData]),
  });

  subjectForm = this.fb.group({
    name: new UntypedFormControl({value: '', disabled: false}, null),
    companyName: new UntypedFormControl({value: '', disabled: false}),
    address: new UntypedFormControl({value: '', disabled: false}),
    postalCode: new UntypedFormControl({value: '', disabled: false}),
    contact: new UntypedFormControl({value: '', disabled: false}),
    work: new UntypedFormControl({value: '', disabled: false}),
    phone: new UntypedFormControl({value: '', disabled: false}),
    fax: new UntypedFormControl({value: '', disabled: false}),
    email: new UntypedFormControl({value: '', disabled: false}),
    brokerage: new UntypedFormControl({value: '', disabled: false}, ((this.dataIn.subject && this.dataIn.subject.category == 'broker') || (this.addForm.get('clients') && this.addForm.get('clients').value && this.addForm.get('clients').value.category === 'broker')) ? Validators.required : null)
  });

  validateQbForm(){
    if(this.reviewedContractForm.value == true){
      this.reviewedContract = true;
      this.addForm.get('adroitOrderId').enable();
    }else{
      this.reviewedContract = false;
      this.addForm.get('adroitOrderId').disable();
    }
  }

  getBranch(){
    return this.branchService.currentBranch.getValue().name.toLowerCase()
  }

  getFormattedDate(format, date ?) {
    return moment(date ? date : new Date).format(format);
  }

  getOrigins(){
    const query = this.queryFactory.generateSimpleGetQuery('WinnipegOrigins',{branchId:this.branchService.currentBranch.getValue().id})
    this.apiService.getTableDataObjects(query).pipe(takeUntil(this.onDestroy)).subscribe((data:any[])=>{
      this.origins = data 
    })
  }

  getLatestFx(){
    var apiData = {
      where: {
        branchId: this.branchService.currentBranch.getValue().id
      },
      order: 'createdAt DESC',
      limit: 1
    };
    this.apiService.getDataObjects('Fxs?filter=' + JSON.stringify(apiData)).pipe(takeUntil(this.onDestroy)).subscribe((data: any[]) => {
      if(data.length){
        this.addForm.get('fx').setValue(Number(data[0].fx.toFixed(3)));
        this.exchange = (Number(1 / ((data[0].fx)+ 0.002)).toFixed(3) ) ;
      }
    }); 
  }

  searchMailingToken() {
    const whereObject = {appUserId: this.authService.currentUserValue.user.id};
    const tokenQuery = this.queryFactory.generateSimpleGetQuery('MailTokens', whereObject);
    this.apiService.getDataObjects(tokenQuery).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
      if (data && data.length > 0) {
        this.likedMail = true;
      } else {
        this.likedMail = false;
      }
    });
  }

  goToProfile() {
    this.onNoClick();
    
    this.router.navigate(['profile']).catch();
  }

  getPacks() {
    var apiData = {
      where: {
        branchId: this.branchService.currentBranch.getValue().id
      },
    };
    this.apiService.getDataObjects('Packings?filter=' + JSON.stringify(apiData)).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
      this.packs = data;
    });
  }


  ngOnInit(): void {
    
    this.setGrainControl();
    // this.getOrigins();
    this.getPacks();
    this.searchMailingToken();

    if(this.validUsers.includes(this.authService.currentUserValue.user.email)){
      this.validUser = true;
    }

    if(this.dataIn.isNew) {
      setTimeout(() => {
        this.addForm.patchValue({
          priceUnits: this.dataIn.relatedTo == 'Buyers' ? 'CAD/MT' : 'USD/MT',
          units: "MT",
          grade: this.productGrades.find(grade => grade.name == "1"),
          paymentTerms: "Within 15 Days of Delivery",
          terms: "NGFA Rules to Govern",
          documents: "Unload weights & grades.",
        });
        this.getLatestFx()
      }, 250);
      this.authService?.currentUserValue?.user?.isWinnipegTrader == true ? 
      this.addForm.get('authorizedBy').patchValue(this.authService.currentUserValue.user): '';

    } else {
      //this.addForm.disable();

      this.patchEditData();
      // Dont show resend checkbox
      if(this.dataIn.prevDeal.traderId != this.authService.currentUserValue.user.id) this.showResendo = false;

      this.onGrainSelect(this.dataIn.prevDeal.grain);
      // this.addForm.patchValue(this.dataIn.prevDeal)
      this.addForm.patchValue({
        quantity: this.dataIn.prevDeal.avalableQuantity
      })
      if(this.dataIn.prevDeal.grain){
        if(this.dataIn.prevDeal.specifications){
          this.addForm.get('specifications').patchValue(this.dataIn.prevDeal.specifications);
          this.isSpecifications = true;
        }
        else if(this.dataIn.prevDeal.grain.productSpecifications){
          this.addForm.get('specifications').patchValue(this.dataIn.prevDeal.grain.productSpecifications);
          this.isSpecifications = true;
        }        
      }
      setTimeout(() => {
        if(this.addForm.get('shipmentAddress')){
          if(this.dataIn.prevDeal.shipmentAddress){
            this.addForm.get('shipmentAddress').patchValue(this.dataIn.prevDeal.shipmentAddress);
          }
        }
      }, 250);
      if(this.dataIn.prevDeal.fx){
        this.addForm.get('fx').patchValue(this.dataIn.prevDeal.fx)
      }
      if (!this.dataIn.prevDeal.statusHistory){
        this.statusHistoria = []
      }else{
        this.statusHistoria = this.dataIn.prevDeal.statusHistory
        this.isDone = this.dataIn.prevDeal.isDone
        this.isCanceled = this.dataIn.prevDeal.isCanceled
      }
    }
    if(this.dataIn.prevDeal){
      if(this.dataIn.prevDeal.grain){
        if(this.dataIn.prevDeal.grain.grades && this.dataIn.prevDeal.grain.grades.length > 0){
          for (let i = 0; i < this.dataIn.prevDeal.grain.grades.length; i++ ) {
            var bandera = false;
            for (let x = 0; x < this.productGrades.length; x++ ) {
              if(this.productGrades[x].name ==  this.dataIn.prevDeal.grain.grades[i].name){
                bandera = true;
              }
            }
            if(!bandera){
              this.productGrades.push(this.dataIn.prevDeal.grain.grades[i]);
            }
          }
        }
      }
      if(this.dataIn.prevDeal.isBasis){
        this.isBas = true;
        if (this.isBas) {
          this.addForm.removeControl('price');
          this.addForm.addControl('price', new UntypedFormControl({value: '', disabled: false}));
        } else {
          this.addForm.removeControl('price');
          this.addForm.addControl('price', new UntypedFormControl({value: '', disabled: false}, Validators.required));
        }
      }
    }

    this.isBuyers = this.dataIn.relatedTo == 'Buyers' ? true : false;
    this.client = this.dataIn.relatedTo == 'Buyers' ? 'buyer' : 'supplier';    
    this.isGrains = true;
    this.setClientControl();
    this.setAuthorizedOptions();

    if (this.dataIn.dissableContent) {
      this.addForm.disable();
      this.subjectForm.disable();
    }
  }

  ngAfterViewInit(){
    if (this.dataIn.subject) {
      this.subjectForm.patchValue({
        ...this.dataIn.subject,
      });
    }
  }

  basis($event){
    this.isBas = $event.checked;
    this.addForm.removeControl('price');
    this.addForm.addControl('price', new UntypedFormControl({value: '', disabled: false}, !$event.checked ? Validators.required : []));
  }

  patchEditData() {
    if (!this.dataIn.isNew && this.dataIn.prevDeal && this.dataIn.prevDeal.signedDocUrl) {
      this.signedFileName = "Signed Contract Added";
    }
    if (!this.dataIn.isNew && this.dataIn.prevDeal && this.dataIn.prevDeal.unsignedContractFileDocUrl) {
      this.unsignedContractName = "Unsigned Contract File Added";
    }

    setTimeout(() => {
      this.addForm.patchValue({
        ...this.dataIn.prevDeal,
        origin: this.dataIn.prevDeal.origin == 'CANADA' ? 'Canada' : this.dataIn.prevDeal.origin == 'US' ? 'USA' : this.dataIn.prevDeal.origin
      });

      if(this.dataIn.prevDeal.grade && this.dataIn.prevDeal.grade !== ' ' ){
        this.addForm.patchValue({
          grade: this.productGrades.find(grade => grade.name == this.dataIn.prevDeal.grade)
        });
      } else {
        this.addForm.patchValue({
          grade: this.productGrades.find(grade => grade.name == 'N/A')
        });
      }

      this.addForm.patchValue({
        exchangeRate: (1/this.dataIn.prevDeal.fx).toFixed(3)
      })
    }, 250);

    this.subjectForm.patchValue({
      ...this.dataIn.subject
    });

    if(this.dataIn.subject && this.dataIn.subject.category == 'broker'){
      this.subjectForm.patchValue({
        brokerage: this.dataIn.prevDeal.brokerage
      });
    }

    if (this.dataIn.relatedTo === 'Buyers' || this.dataIn.relatedTo === 'Suppliers') {
      this.addForm.patchValue({
        companyName: this.dataIn.subject.name
      });
    }

    setTimeout(() => {
      // if(this.dataIn.isWinnipeg){
        this.addForm.patchValue({
          shipmentAddress: this.dataIn.prevDeal.shipmentAddress
        });
      // }
    }, 500);
  }

  //This control is for both buyers and suppliers
  setClientControl() {
    const clientValue = (this.dataIn.prevDeal?.buyer || this.dataIn.prevDeal?.client) || '';
    
    const validators = [Validators.required, objectValidator.validData]
    this.addForm.addControl(this.client + 's', new UntypedFormControl({value: clientValue, disabled: false}, validators));

    this[this.client + 'Options'] = this.addForm.get(this.client + 's').valueChanges.pipe(
      debounceTime(250),
      startWith(''),
      switchMap(value => this.loadAutoOptions(value, ['name'], [], this.isBuyers ? 'Buyers' : 'Suppliers', 'name ASC', {branchId: this.dataIn.branch.id, isActive:true}))
    )
    this.subjectConfigured = true;
  }

  setGrainControl() {
    const grain = this.dataIn.prevDeal?.grain ? this.dataIn.prevDeal.grain : '';
    this.addForm.addControl('grains', new UntypedFormControl({value: grain, disabled: false}, [Validators.required, objectValidator.validData]));
    this.addForm.addControl('origin', new UntypedFormControl({value:'', disabled: true},[Validators.required]));
    
    this.grainOptions = this.addForm.get('grains').valueChanges.pipe(
      debounceTime(250),
      startWith(''),
      switchMap(value => this.loadAutoOptions(value, ['name'], [], 'Grains', 'name ASC', {category: "Grains"}))
    );
  }

  setAuthorizedOptions() {

    this.authorizeOptions = this.addForm.get('authorizedBy').valueChanges.pipe(
      debounceTime(250),
      startWith(''),
      switchMap(value => this.loadAutoOptions(value, ['name'], [], 'AppUsers', 'name ASC', {isWinnipegTrader: true}))
    );
  }


  //This loads all the options for the selects in the form
  loadAutoOptions(searchText, searchProperties, includes, model, order, conditions) {
    const searchQuery = this.queryFactory.setSearchQuery(searchText, searchProperties);
    const andObject: any[] = [
      searchQuery,
      conditions
    ];
    if(model == 'Grains') andObject.push({isActive: true});
    const getQuery = this.queryFactory.generateGetQuery(model, { and: andObject }, 25, 0, order, includes);
    return new Observable<any>((objs) => this.apiService.getDataObjects(getQuery).pipe(takeUntil(this.onDestroy)).subscribe((data: any[]) => {
      objs.next(data);
    }));
  }

  displayFn(user?: any): string | undefined {
    return user ? user.name : undefined;
  }

  onClientSelect(client: any) {
    this.subjectForm.patchValue({
      companyName: client.name,
      address: client.address,
      postalCode: client.postalCode,
      contact: client.contactPerson,
      work: client.work,
      phone: client.phone,
      fax: client.fax,
      email: client.email,
    });
    if (client.paymentTerms){
      this.addForm.patchValue({
        paymentTerms: client.paymentTerms
      });
    }
  }

  onGrainSelect(grain: any){
    
    this.OriginGrain = grain.origin == 'CANADA' ? 'Canada' : grain.origin == 'US' ? 'USA' : grain.origin
    this.addForm.get('origin').patchValue(this.OriginGrain);
    this.grainLetter = "";

    this.isSpecifications = grain.productSpecifications ? true : false;
    this.addForm.get('specifications').patchValue(grain.productSpecifications ? grain.productSpecifications : '');
    this.grainLetter = grain.basisLetter;

    if( grain.isFeed ){
      this.addForm.get('grade').patchValue(this.productGrades.find(grade => grade.name == "N/A"))
    } else {
      this.addForm.get('grade').patchValue(this.productGrades.find(grade => grade.name == "1"))
    }
    if(grain.grades.length > 0){
      this.productGrades = grain.grades;
      this.addForm.get('grade').patchValue(this.productGrades.find(grade => grade.name ==  this.productGrades[0].name));
    }
  }

  onGradeSelect(grade: any) {
    if (grade.priceUnits && this.dataIn.isNew) {
      var producto = this.addForm.get('products').value;
      if(producto.name.includes('Peas') || producto.name.includes('Flax')){
        this.addForm.patchValue({
          priceUnits: '$/Bu'
        });
      } else{
        this.addForm.patchValue({
          priceUnits: grade.priceUnits
        });
      }
    }
  }


  validateQBSerial(qbSerial) {
    if (this.dataIn.isNew || (!this.dataIn.isNew && qbSerial.trim() != this.dataIn.prevDeal.adroitOrderId)){
      this.apiService.getDataObjects('WinnipegDeals/count?where=' + JSON.stringify({
        adroitOrderId: qbSerial.trim()
      })).pipe(takeUntil(this.onDestroy)).subscribe((count: any) => {
        if (count.count > 0){
          this.addForm.controls['adroitOrderId'].setErrors({'incorrect': true})
          this.generalService.presentToast('Error in form, can`t have repeating QB Serial N°', 'yellow-snackbar');
        }
      });
    }
  }
  
  validateForm() {    
    if (this.addForm.status === 'INVALID') {
      this.generalService.presentToast('Error in form', 'yellow-snackbar');
    } else {
      const titleString = this.dataIn.isPurchases ? 'Purchases' : 'Sales';
      const product = this.isProducts ? this.addForm.get('products').value.name : this.addForm.get('grains').value.name;
      const client = this.addForm.get(this.client+'s').value.name;
      this.addForm.patchValue({
        emailTitle: titleString + ' - Business Confirmation - ' + product + ' - ' + client
      });
    }
  }

  addTag() {
    const selected = this.tagEmail.value;
    if (selected !== '') {
      this.tagEmails.push(selected);
      this.tagEmail.patchValue('');
    }
  }

  removeTag(index: number) {
    this.tagEmails.splice(index, 1);
  }

  removeAllTags() {
    this.tagEmails = [];
  }

  toggleDirectResend(event) {
    if (this.isResend == false){
      // this.isResend = true;
      // this.addForm.get('emailTitle').disable();
      // this.addForm.get('emailTitle').patchValue('Amendment to Contract - ' + this.dataIn.prevDeal.adroitOrderId);  
    } else{
      // this.isResend = false;
      // this.addForm.get('emailTitle').enable();
      // this.addForm.get('emailTitle').patchValue(this.dataIn.prevDeal.emailTitle);
    }
    this.isResend = event.checked;
    this.addForm.get('emailTitle')[event.checked ? 'disable' : 'enable']();
    this.addForm.get('emailTitle').patchValue(event.checked ? 'Amendment to Contract - ' + this.dataIn.prevDeal.adroitOrderId : this.dataIn.prevDeal.emailTitle);
  }

  performRequest(){
    if (this.addForm.status === 'INVALID' || this.subjectForm.status === 'INVALID') {
      if (this.addForm.status  === 'INVALID') {
        this.generalService.presentToast('Business Confirmation information is incomplete', 'yellow-snackbar');
      } else {
        this.generalService.presentToast("Client's information is incomplete", 'yellow-snackbar');
      }
    } else {
      this.disableButton = true;
      if (this.dataIn.isNew) {
        this.loadingService.showLoader.next(true);
        this.addDeal();
      } else {
        this.loadingService.showLoader.next(true);
        const signedFileName = document.getElementById('signedFileName');
        const unsignedContractName = document.getElementById('unsignedContractName');
        if (signedFileName || unsignedContractName) {
          const centralF = unsignedContractName ? unsignedContractName['files'] : null;
          const signedF  = signedFileName ? signedFileName['files'] : null;
          if (centralF && centralF.length || signedF && signedF.length){
            this.uploadFile(signedFileName, unsignedContractName);
          }else this.editDeal();
        } else  this.editDeal();
      }
    }
  }

  addDeal() {
    const tmpDeal = {
      grainId: this.addForm.get('grains').value.id,
      origin: this.addForm.get('origin').value ,
      year: this.dataIn.yearNumber,
      [this.client+'Id']: this.addForm.get(this.client+'s').value?.id,
      traderId: this.authService.currentUserValue.user.id,
      availableQuantity: !this.isBuyers && this.addForm.get('units').value == 'ST' ?  this.addForm.get('quantity').value / 1.102 : this.addForm.get('quantity').value,
    };

    const dealObject = {
      ...tmpDeal,
      ...this.addForm.value,
      createdAt: this._timeZone.convertVanDate(new Date()),
      branchId: this.dataIn.branch.id,
      grade: this.addForm.get('grade').value.name == 'N/A' ? ' ' : this.addForm.get('grade').value.name ,
      
      isLead: false,
      isDeal: true,
      deductionPerTon: 0,
      deductionPerHandlingFee: 0,
      deductionPerDegrade: false,
      confirmationEmailIds: false,
      sendNotifications: false,
      confirmRead: false,
      confirmationEmails: this.tagEmails,
      emailTitle: this.addForm.get('emailTitle').value,
      isPurchases: this.dataIn.isPurchases,
      isTrading: this.dataIn.isTrading,
      isBasis: this.addForm.get('isBasis').value,
      isResend: false,
      fx: (this.addForm.get('fx').value + 0.002),
      authorizedById: this.addForm.get('authorizedBy').value?.id,
      dealCategory: "Grains",
    };

    dealObject.shipmentFrom = this._timeZone.convertVanDate(this.addForm.get('shipmentFrom').value)
    dealObject.shipmentTo = this._timeZone.convertVanDate(this.addForm.get('shipmentTo').value)

    const units = this.addForm.get('units').value;
    dealObject.quantity = (units == 'ST') ? (this.addForm.get('quantity').value / 1.102) : this.addForm.get('quantity').value;
    dealObject.units =  (units == 'ST') ? 'MT' : units;

    const priceUnits = this.addForm.get('priceUnits').value;
    dealObject.price = (priceUnits == 'USD/ST') ? (this.addForm.get('price').value / 0.907185) : this.addForm.get('price').value;
    dealObject.priceUnits =  (priceUnits == 'USD/ST') ? 'USD/MT' : priceUnits;

    delete dealObject.grains;
    delete dealObject[this.client+'s']

    if(this.dataIn.subject && this.dataIn.subject.category == 'broker') dealObject.brokerage = this.subjectForm.get('brokerage').value;
    if(dealObject.isBasis) dealObject.futureBasis = ( this.grainLetter) + (this.addForm.get('futureMonth')).value + (this.addForm.get('futureYear').value).toString().substring(2,4);
    if(dealObject.brokerage === "") dealObject.brokerage = null;
    if(dealObject.broker === "") dealObject.broker = null;

    if(!this.isFob){
      dealObject.zone === null;
      dealObject.brokerNote === null;
    }
    if (!this.isBroker.value) {
      delete dealObject.brokerName;
      delete dealObject.brokerage;
      delete dealObject.brokerageType;
      delete dealObject.brokerNote;
    }

    this.editSubject().pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.apiService.addDataObject(dealObject, this.dataIn.model).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
        this.finishDeal();
      }, () => {
        this.generalService.presentToast('Connection rejected', 'red-snackbar');
        this.disableButton = false;
        this.loadingService.showLoader.next(false);
      });
    });
  }

  editDeal() {
    const tmpDeal = {
      grainId: this.addForm.get('grains').value.id,
      origin: this.addForm.get('origin').value,
      year: this.dataIn.yearNumber,
      [this.client+'Id']: this.addForm.get(this.client+'s').value?.id,
      availableQuantity: !this.isBuyers && this.addForm.get('units').value == 'ST' ?  this.addForm.get('quantity').value / 1.102 : this.addForm.get('quantity').value,
    };

    const dealObject = {
      ...tmpDeal,
      ...this.addForm.value,
      branchId: this.dataIn.branch.id,
      grade: this.addForm.get('grade').value.name == 'N/A' ? ' ' : this.addForm.get('grade').value.name ,
      isLead: false,
      isDeal: true,
      deductionPerTon: 0,
      deductionPerHandlingFee: 0,
      deductionPerDegrade: false,
      confirmationEmailIds: false,
      sendNotifications: false,
      confirmRead: false,
      confirmationEmails: this.isResend ? this.dataIn.prevDeal.confirmationEmails : this.tagEmails,
      emailTitle: this.isResend ? this.dataIn.prevDeal.emailTitle : this.addForm.get('emailTitle').value,
      isPurchases: this.dataIn.isPurchases,
      isBasis: this.addForm.get('isBasis').value,
      isResend: this.isResend,

      statusHistory: this.statusHistoria,
      isDone: this.isDone,
      completed: this.isDone,
      isCanceled: this.isCanceled,
      authorizedById: this.addForm.get('authorizedBy').value?.id,
    };
    if (this.imageObj.length > 0) {
      for (let index = 0; index < this.imageObj.length; index++) {
        const porp = this.imageObj[index].propiedad;
        dealObject[porp] = this.imageObj[index].url;
      }
    }

    dealObject.shipmentFrom = this._timeZone.convertVanDate(this.addForm.get('shipmentFrom').value)
    dealObject.shipmentTo = this._timeZone.convertVanDate(this.addForm.get('shipmentTo').value)

    const units = this.addForm.get('units').value;
    dealObject.quantity = (units == 'ST') ? (this.addForm.get('quantity').value / 1.102) : this.addForm.get('quantity').value;
    dealObject.units =  (units == 'ST') ? 'MT' : units;

    const priceUnits = this.addForm.get('priceUnits').value;
    dealObject.price = (priceUnits == 'USD/ST') ? (this.addForm.get('price').value / 0.907185) : this.addForm.get('price').value;
    dealObject.priceUnits =  (priceUnits == 'USD/ST') ? 'USD/MT' : priceUnits;

    delete dealObject.grains;
    delete dealObject[this.client+'s']

    if(this.dataIn.subject && this.dataIn.subject.category == 'broker') dealObject.brokerage = this.subjectForm.get('brokerage').value
    if(dealObject.isBasis) dealObject.futureBasis = ( this.grainLetter) + (this.addForm.get('futureMonth')).value + (this.addForm.get('futureYear').value).toString().substring(2,4);
    if(dealObject.brokerage === "") dealObject.brokerage = null;
    if(dealObject.broker === "") dealObject.broker = null;
    
    if(!this.isFob){
      dealObject.zone === null;
      dealObject.brokerNote === null;
    }

    this.editSubject().pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.apiService.editDataObject(
          this.dataIn.prevDeal.id,
          dealObject,
          this.dataIn.model
      ).pipe(takeUntil(this.onDestroy)).subscribe(() => {
        this.finishDeal();
      }, () => {
        this.generalService.presentToast('Save deal rejected', 'red-snackbar');
        this.disableButton = false;
        this.loadingService.showLoader.next(false);
      });
    });
  }

  editSubject() {
    const editSubject: any = {
      ...this.subjectForm.value,
      contactPerson: this.subjectForm.get('contact').value,
      address: this.subjectForm.get('address').value,
      postalCode: this.subjectForm.get('postalCode').value,
      email: this.subjectForm.get('email').value,
      branchId: this.branchService.currentBranch.getValue().id,
      name: this.subjectForm.get('companyName').value
    };

    const subjectId = this.addForm.get(this.client+'s').value?.id;
    return new Observable(objs => this.apiService.editDataObject(subjectId, editSubject, this.dataIn.relatedTo).pipe(takeUntil(this.onDestroy)).subscribe(() => {
      objs.next(true);
      objs.complete();
    }, (e) => {
      this.generalService.presentToast('Edit subject rejected', 'red-snackbar');
      this.disableButton = false;
      this.loadingService.showLoader.next(false);
    }));

  }

  finishDeal() {
    this.generalService.presentToast(this.dataIn.isNew ? 'Contract Created' : 'Contract Edited' + 'successfully', 'green-snackbar');
    
    this.loadingService.showLoader.next(false);
    this.hasChanges = true;
    this.onNoClick();
  }

  openClose(status){
    const dialogRef = this.dialog.open(ModalConfirmInputComponent, {
      data: {
        title: 'Reason to ' + status +' deal',
        button: 'Save',
        inputLabel: 'Enter reason',
        inputType: 'text',
        required: true,
      },
      autoFocus: false,
      width: '500px',
      panelClass: 'usaModal' 
    })
    ;dialogRef.afterClosed().pipe(takeUntil(this.onDestroy)).subscribe(result => {
      if (result !== undefined) {
        if (result.confirmation) {
          this.isDone = status == 'open' ? false : true;
          this.isCanceled = status == 'cancel' ? true : false;

          this.statusHistory = {
            appUserId: this.authService.currentUserValue.user.id,
            reason: result.remarks,
            date: new Date().toISOString(),
            type: status
          }

          this.statusHistoria.push(this.statusHistory)
          this.editDeal();
        }
      }
    });
  }

  deleteObject() {
    const dialogRef = this.dialog.open(ModalConfirmComponent, {
      data: {
        button: 'Delete',
        title: 'Deal',
        subtitle: '¿Are you sure about deleting this deal?',
        message: [
        ]
      },
      autoFocus: false
    });
    dialogRef.afterClosed().pipe(takeUntil(this.onDestroy)).subscribe(result => {
      if (result !== undefined) {
        if (result.confirmation) {
          this.loadingService.showLoader.next(true);
          this.apiService.deleteDataObject('WinnipegDeals', this.dataIn.prevDeal.id).pipe(takeUntil(this.onDestroy)).subscribe(() => {
            this.generalService.presentToast('Deal product deleted successfully', 'green-snackbar');
            this.loadingService.showLoader.next(false);
            this.hasChanges = true;
            this.onNoClick();
          });
        }
      }
    });
  }

  processPhone(phone) {
    let formatedPhone = "";
    if (phone !== undefined && phone !== null && phone !== "") {
      if (typeof phone !== "string") phone = phone.toString();
      let regex = /[^0-9\s]+/g;
      phone = phone.replace(regex, "").replace(/ /g, "").trim();
      if (phone !== "" && phone !== "0") {
        if (phone.length == 10) {
          formatedPhone =
            "(" +
            phone.substring(0, 3) +
            ") " +
            phone.substring(3, 6) +
            " " +
            phone.substring(6, 10);
        } else if (phone.length == 11) {
          formatedPhone =
            phone.substring(0, 1) +
            " (" +
            phone.substring(1, 4) +
            ") " +
            phone.substring(4, 7) +
            " " +
            phone.substring(7, 11);
        } else if (phone.length == 12) {
          formatedPhone =
            phone.substring(0, 2) +
            " (" +
            phone.substring(2, 5) +
            ") " +
            phone.substring(5, 8) +
            " " +
            phone.substring(8, 12);
        } else {
          formatedPhone = phone
        }
      }
    } else {
      formatedPhone = phone;
    }
    return formatedPhone;
  }

  toggleBroker($event) {
    if ($event.checked) {
      for(let broker of this.brokerControls){
        this.addForm.get(broker).setValidators(Validators.required);
        this.addForm.get(broker).updateValueAndValidity();
      }
    } else {
      for(let broker of this.brokerControls){
        this.addForm.get(broker).clearValidators();
        this.addForm.get(broker).updateValueAndValidity();
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close({changes: this.hasChanges});
  }

  preAddImage(id) {
    const imageSelect = document.getElementById(id);
    const imagesF = imageSelect['files'];
    if (imagesF.length) {
      const file = imagesF[0];
      this.fileName = file.name.split('.');
      this.fileName = this.fileName[this.fileName.length - 1];
      this[id] = "File: " + file.name;
      this.fileName = uuid.v4() + "." + this.fileName;
    }
  }
  uploadFile( signedFileName = {}, unsignedContractName = {}) {
    const centralF = unsignedContractName ? unsignedContractName['files'] : null;
    const signedF  = signedFileName ? signedFileName['files'] : null;
    let files = []
    if (signedF) {
      if (signedF.length) {
        files.push({
          ...signedFileName,
          file: signedFileName['files'],
          nameDoc: '_Signed_Confirmation.',
          propiedad: 'signedDocUrl',
          flag: 'isSigned'
        });
      }
    }
    if (centralF) {
      if (centralF.length) {
        files.push({
          file: unsignedContractName['files'],
          nameDoc: '_Unsigned_Contract.',
          propiedad: 'unsignedContractFileDocUrl',
          flag: 'isUnsignedDoc'
        });
      }
    }
    let count = 0;
    files.forEach(element => {
      const file = element.file[0];
      this.fileName = file.name.split('.');
      this.fileName = this.fileName[this.fileName.length - 1];
      this.fileName = uuid.v4() + element.nameDoc + this.fileName;
      this.apiService.addFile(file, this.fileName, 'container').pipe(takeUntil(this.onDestroy)).subscribe((res: any) => {
        count = count + 1;
        const docProp = {
          url: "",
          filename: "",
          propiedad: "",
          flag: ""
        };
        docProp.url = res.result.files.container[0].name;
        docProp.filename = file.name;
        docProp.propiedad = element.propiedad,
        docProp.flag = element.flag;
        this.imageObj.push(docProp);
        if (count === files.length) {
          this.editDeal();
        }
      });
    });
  }

  downloadSigned(data: any) {
    window.open("https://s3.us-east-2.amazonaws.com/uploads.adroitoverseas.net/" + data, '_blank'); 
  }
}
