import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from "rxjs/operators";
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BranchService } from 'src/app/services/branch/branch.service';
import { GeneralService } from 'src/app/services/general/general.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { QueryFactory } from 'src/app/tableQueries/queryFactory';

@Component({
  selector: 'app-authorize-buyer-view',
  templateUrl: './authorize-buyer-view.component.html',
  styleUrls: ['./authorize-buyer-view.component.scss']
})
export class AuthorizeBuyerViewComponent implements OnInit,AfterViewInit {
  public userAuth = false;
  private onDestroy = new Subject<void>();
  public dataObject:any;

  constructor(
    public authService: AuthService,
    private fb: UntypedFormBuilder,
    private apiService: ApiService,
    private snackBar: MatSnackBar,
    private loadingService: LoadingService,
    private queryFactory: QueryFactory,
    private branchService: BranchService,
    private generalService:GeneralService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    if(!this.authService.currentUserValue.user) this.router.navigate(['/login']).catch();
    else {
      if(this.authService.currentUserValue.user.authorizeLoadOrders){
        this.userAuth = true;
        this.getToken();
      }
    }
  }
  ngAfterViewInit(): void {
  }
  getToken(){
    const url:string = this.router.url;
    const tokenId:string = url.substring(23,url.length);
    this.apiService.getDataObject('Tokens', tokenId + '?filter=' + JSON.stringify({include: []})).pipe(takeUntil(this.onDestroy)).subscribe((data) => {
      this.dataObject = data;
      this.unlockRemoteMethod();
    },() => {
      this.generalService.presentToast('Error loading authorization token', 'red-snackbar');
    })
  }

  unlockRemoteMethod(){
    this.apiService.useRemoteMethod("LoadOrders", "customerLoadUnlock", { buyerId: this.dataObject.client }).pipe(takeUntil(this.onDestroy)).subscribe((r: any) => {
        if(r) this.generalService.presentToast('Buyer unlocked successfully', 'green-snackbar');
      },
      (err) => {
        this.generalService.presentToast('Error in unlock method', 'red-snackbar');
      }
    );
  }

  getBranch() {
    return this.branchService.currentBranch.getValue().name.toLowerCase();
  }

  routeLoad(){
    if(this.getBranch() == 'canada') this.router.navigate(['/dashboard-purchases']).catch();
    else this.router.navigate(['/general-load-orders']).catch();
  }

}
