import {
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Location, DOCUMENT } from "@angular/common";
import { Observable, Subject, timer } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { ApiService } from "../services/api/api.service";
import { AuthService } from "../services/auth/auth.service";
import { BranchService } from "../services/branch/branch.service";
import { QueryFactory } from "../tableQueries/queryFactory"; 
import {
  SwiperComponent,
  SwiperDirective,
  SwiperConfigInterface,
  SwiperPaginationInterface,
} from "ngx-swiper-wrapper";
import { Swiper } from "swiper";
import {
  fadeInBottom,
  fadeInRight,
  fadeInTop,
} from "../animations/animatedComponents";
import { MatTable } from "@angular/material/table";
import { id } from "@swimlane/ngx-charts";
@Component({
  selector: "app-plant-prices",
  templateUrl: "./plant_prices.component.html",
  styleUrls: ["./plant_prices.component.scss"],
  animations: [fadeInBottom, fadeInTop, fadeInRight],
})
export class PlantPricesComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("plantSwiper") plantSwiper: Swiper;
  @ViewChild("table_pp") tablePP: MatTable<any>;
  private onDestroy = new Subject<void>();
  public plants: any[];
  public columnsToDisplay = ["name", "price1", "price2", "price3"];
  public currentIndexPlant = 0;
  private branchId;
  public currentUser;
  private currentPlants = [
    { "Neville Plant": "Neville_1.png" },
    { Shaunavon: "Shaunavon.jpg" },
    { "Briercrest Plant": "Briercrest.jpg" },
  ];
  private everyFiveSeconds: Observable<number> = timer(0, 10000);
  private time;
  public loaded = false;
  private element: HTMLElement;
  public isFullscreen = false;
  private images = ["Neville_1.png", "Shaunavon.jpg", "Briercrest.jpg"];
  public imageUrl = "";

  getImageUrl(image: string) {
    image = image ? image : this.images[0];
    return `url('../../assets/img/plants/${image}')`;
  }

  constructor(
    private _qf: QueryFactory,
    private _api: ApiService,
    private _auth: AuthService,
    private _branch: BranchService,
    private _location: Location,
    @Inject(DOCUMENT) private document: Document
  ) {}
  @ViewChild("back") back;
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (event.target.innerWidth < 768) {
      this.navigation = false;
    } else {
      this.navigation = true;
    }
  }
  @HostListener("document:keydown", ["$event"])
  @HostListener("window:mousemove")
  refreshUserState() {
    if (this.isFullscreen) {
      this.ishiden = false;
      clearTimeout(this.time);
      this.time = this.hideToolbar();
    }
  }
  public ishiden = false;
  public plant;
  public navigation = true;
  public swiperConfig: SwiperConfigInterface;
  get user() {
    return this._auth.currentUserValue;
  }
  get currentDate() {
    var currentDate = new Date();
    var dd = String(currentDate.getDate()).padStart(2, "0");
    var mm = currentDate.toLocaleString("en", { month: "long" }); //January is 0!
    var yyyy = currentDate.getFullYear();
    return `${mm} ${dd}, ${yyyy}`;
  }

  getSymbol(symbol: string) {
    const symbols = symbol.split("/");
    if (symbols.length > 0) {
      if (!(symbol[0].toLowerCase() != symbol[0].toUpperCase())) {
        return symbol[0];
      } else {
        return "$";
      }
    } else {
      return "$";
    }
  }

  getUnits(units: string) {
    const sunits = units.split("/");
    if (sunits.length > 0) {
      if (sunits[0].toLowerCase() != sunits[0].toUpperCase()) {
        return " " + units;
      } else {
        return "/" + sunits[1];
      }
    } else {
      return "";
    }
  }

  processPhone(phone) {
    let formatedPhone = "";
    if (phone !== undefined && phone !== null && phone !== "") {
      if (typeof phone !== "string") phone = phone.toString();
      let regex = /[^0-9\s]+/g;
      phone = phone.replace(regex, "").replace(/ /g, "").trim();
      if (phone !== "" && phone !== "0") {
        if (phone.length == 10) {
          formatedPhone =
            "(" +
            phone.substring(0, 3) +
            ") " +
            phone.substring(3, 6) +
            " " +
            phone.substring(6, 10);
        } else if (phone.length == 11) {
          formatedPhone =
            phone.substring(0, 1) +
            " (" +
            phone.substring(1, 4) +
            ") " +
            phone.substring(4, 7) +
            " " +
            phone.substring(7, 11);
        } else if (phone.length == 12) {
          formatedPhone =
            phone.substring(0, 2) +
            " (" +
            phone.substring(2, 5) +
            ") " +
            phone.substring(5, 8) +
            " " +
            phone.substring(8, 12);
        }
      }
    } else {
      formatedPhone = phone;
    }
    return formatedPhone;
  }

  fullscreen() {
    if (!this.isFullscreen) {
      this.element.requestFullscreen();
      this.isFullscreen = true;
    } else {
      this.document.exitFullscreen();
      this.isFullscreen = false;
    }
  }

  getPlant() {
    const query = this._qf.generateSimpleGetQuery("Plants", {});
    return this._api.getDataObject("Plants", this.currentUser.plantId);
  }

  getPlants() {
    const query = this._qf.generateSimpleGetQuery("Plants", {
      ownPlant: true,
      branchId: this.branchId,
    });
    return this._api.getTableDataObjects(query);
  }

  ngOnInit(): void {
    this.element = document.documentElement;
    document.documentElement.style.setProperty(
      "#202d42",
      " #ff7830"
    );
    this._auth.userObservable
      .pipe(takeUntil(this.onDestroy))
      .subscribe((user) => {
        if (user) {
          this.currentUser = user.user;
          if (this.currentUser.role === "plant_prices") {
            this.branchId = user.user.branchIds[0];
            this.loadDataPlant();
          } else {
            if (this._branch.branchesLoaded.getValue()) {
              this.loadDataPlants();
            }
            this._branch.branchesLoaded
              .pipe(takeUntil(this.onDestroy))
              .subscribe((state) => {
                if (state) {
                  this._branch.branchObservable
                    .pipe(takeUntil(this.onDestroy))
                    .subscribe((data) => {
                      if (data) {
                        this.loadDataPlants();
                      }
                    });
                }
              });
          }
        }
      });
  }
  hideToolbar() {
    return setTimeout(() => {
      if (this.isFullscreen) {
        this.ishiden = true;
      }
    }, 5000);
  }
  loadDataPlant() {
    this.hideToolbar();
    this.loadPlant();
    this.everyFiveSeconds.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      if (this.loaded !== false) {
        this.loadPlant();
        if (this.tablePP) {
          this.tablePP.renderRows();
        }
      }
    });
  }
  loadPlant() {
    this.getPlant()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((plant:any) => {
        this.plant = plant;
        this.imageUrl = this.checkPlant(plant.name) 
        this.loaded = true;
      });
  }

  loadDataPlants() {
    this.getPlants()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((plants: []) => {
        console.log(plants);
        this.loaded = true;
        this.plants = plants;
        console.log(plants);
        this.swiperConfig = {
          effect: "slide",
          direction: "horizontal",
          slidesPerView: "auto",
          navigation: true,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
            hideOnClick: false,
            dynamicBullets: false,
          },
          autoplay: { delay: 10000, disableOnInteraction: false },
        };
      });
    this.navigation = window.innerWidth > 768;
  }

  checkPlant(plant: string) {
    let ret = ""
    this.currentPlants.forEach(cplant => {
      if(Object.keys(cplant)[0].toLowerCase().includes(plant.toLowerCase())){
        ret = cplant[Object.keys(cplant)[0]]
      }
    });
    return ret
  }

  ngAfterViewInit() {}
  goBack() {
    this._location.back();
  }
  indexChange() {
    this.getPlants().subscribe((plants: []) => {
      this.plants = plants;
    });
  }
  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.unsubscribe();
  }
  logout() {
    this._auth.logout(true);
  }
}
