import { Injectable } from '@angular/core';
import { FormControl, Validators, AbstractControl, UntypedFormControl } from "@angular/forms";
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageModalComponent } from 'src/app/modals/desicion-modals/message-modal/message-modal.component';
import { ApiService } from '../api/api.service';

type Snackbars = 'red-snackbar' | 'yellow-snackbar' | 'green-snackbar' | 'blue-snackbar' | 'gray-snackbar';

@Injectable({
  providedIn: 'root'
})


export class GeneralService {

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private apiService: ApiService,
  ) { }

  public validateObject(control: UntypedFormControl) {
    if (typeof control.value !== 'object') {
      return ({ validData: true });
    } else {
      return (null);
    }

  }

  public presentToast(message: string, style: Snackbars) {
    this.snackBar.open(message, 'Close', {
        duration: 3000,
        panelClass: [style],
        horizontalPosition: 'end',
        verticalPosition: document.documentElement.clientWidth >= 1050 ? 'top' : 'bottom'
    });
  }

  presentAlert(title, msg) {
    this.dialog.open(MessageModalComponent, {
      data: {
        title: title,
        msg: msg
      }
    });
  }

  public checkPropertiesChanged(obj1: any, obj2: any, properties: string[]): boolean {
    for (const prop of properties) {
      // en caso de que las props sean arreglos la verificacion es diferente
      if (Array.isArray(obj1[prop]) && Array.isArray(obj2[prop])) {
        if (!this.arraysAreEqual(obj1[prop], obj2[prop])) {
          return true;
        }
      } else if ( obj1[prop] !==  obj2[prop]) {
        return true;
      }
    }
    return false;
  }
  
  private arraysAreEqual(arr1: any[], arr2: any[]): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  }
  
  // ordenamiento de plantas, primero las primary elevator y briecreast
  // cuando mostramos una lista de plantas hay que mostrar primero las que pertenecen a adroit y briecreast
  public sortAdroitPlantsFirst( plants: any[] ): Array<any>{
    plants.sort((a, b) => {
      const aPrimary = a.isPrimaryElevator; // Valor de isPrimaryElevator en a
      const bPrimary = b.isPrimaryElevator; // Valor de isPrimaryElevator en b
      const aName = a.name.toLowerCase(); // Nombre de a en minúsculas
      const bName = b.name.toLowerCase(); // Nombre de b en minúsculas
    
      // Comparación de isPrimaryElevator
      if (aPrimary && !bPrimary) {
        return -1; // Colocar a antes que b si a tiene isPrimaryElevator en true y b no
      } else if (!aPrimary && bPrimary) {
        return 1; // Colocar a después de b si a no tiene isPrimaryElevator en true y b sí
      }
    
      // Comparación de name para los que no tienen isPrimaryElevator en true
      if (!aPrimary && !bPrimary) {
        const aIncludesBriercrest = aName.includes('briercrest'); // Verificar si a incluye "briercrest"
        const bIncludesBriercrest = bName.includes('briercrest'); // Verificar si b incluye "briercrest"
    
        if (aIncludesBriercrest && !bIncludesBriercrest) {
          return -1; // Colocar a antes que b si a incluye "briercrest" y b no
        } else if (!aIncludesBriercrest && bIncludesBriercrest) {
          return 1; // Colocar a después de b si a no incluye "briercrest" y b sí
        }
      }
    
      return 0; // Mantener el orden original para los demás elementos
    });
    return plants;
  }

  toggleRequiredValidator(control: AbstractControl, addValidator: boolean): void {
  
    if (control) {
      if (addValidator) {
        control.setValidators([Validators.required, ...(control.validator ? [control.validator] : [])]);
      } else {
        const validators = control.validator ? (control.validator as any)['validators'] : [];
        const filteredValidators = validators.filter((validator: any) => validator !== Validators.required);
        control.setValidators(filteredValidators);
      }
  
      control.updateValueAndValidity();
    }
  }

  //Returns the text received with only the first uppercase letter and the rest of the lowercase letters.
  FirstCapitalLetterOnly(texto: string): string {
    return texto.charAt(0).toUpperCase() + texto.slice(1).toLowerCase();
  }

  /**
 * Replaces text after a regular expression in a given string.
 * @param {string} text - The original string.
 * @param {string} expression - The regular expression to be used to find the position from which the text will be replaced.
 * @param {string} replaceText - The text with which the found portion by the regular expression will be replaced.
 * @returns {string} - The modified string with the replaced text.
 */
 replaceTextAfterExpression(text: string, expression: string, replaceText: string): string {
  // Creates a RegExp instance with the regular expression and the 'g' modifier for global matches.
  const regex = new RegExp(`${expression}.*$`, 'g');

  // Uses the replace method to perform the replacement based on the regular expression.
  const result = text.replace(regex, replaceText).trim();

  // Returns the resulting string.
  return result;
 }


 
    /**
 * Function to round up the number if the decimal is over or equal to 5
 * @param {number} value - The original Value to be rounded.
 * @param {number} d - Number of decimals you need to be returned.
 * @returns {string} - The value returned.
 */
  public getRoundedDecimals(value, d) {
    const valueStr = value.toString(); // 1. Ensure that the value has the specified number of decimals
    // 2. Check if the value has more decimal than we need to round and if that decimal is 5 or greater
    const hasMoreDecimal = valueStr.split('.')[1]?.length >= (d+1);
    const roundDecimalValue = parseInt(valueStr.split('.')[1]?.charAt(d));
    if (hasMoreDecimal && roundDecimalValue >= 5) {
        // 3. If it has a third decimal and it is 5 or greater, increment the second decimal by 1
        const newValue = value + (1 / (10 ** d)); // Increment the second decimal by 1
        return newValue.toFixed(d); // Return the rounded value with 2 decimals
    } else {
        // 4. Otherwise, leave the value as it is
        return valueStr;
    }
  }

  generateDealSerial(branch, nutritional, purchases) {
    return new Promise((resolve, reject) => {
        // Determina la colección basada en los inputs
        const collection = purchases ? 'ControlSheetPurchases' : nutritional ? 'SalesNutritionalDivision' : 'ControlSheetSales';
        const branchValue = branch;
        let serial = '-';

        // Construye el filtro para la API
        const filter = JSON.stringify({
            where: {
                collection,
                branchId: branch.id
            }
        });

        // Obtén los datos del contador desde la API
        this.apiService.getDataObjects(`Counters?filter=${filter}`).subscribe(
            counter => {
                if (counter) {
                    const date = new Date();
                    const oldDate = new Date();
                    const letter = purchases ? 'P' : 'S';

                    // Ajusta el año fiscal basado en el mes actual
                    if (date.getMonth() >= 9) {
                        date.setFullYear(date.getFullYear() + 1);
                    } else {
                        oldDate.setFullYear(oldDate.getFullYear() - 1);
                    }

                    const fiscalYear = date.getFullYear().toString().substring(2, 4);
                    const baseSerial = branchValue?.initialSerial || '';
                    const serialNumber = this.getCerosSerial(counter[0].value + 1);

                    // Construye el número de serie
                    serial = `${baseSerial}${nutritional ? 'ND' : letter}${fiscalYear}${serialNumber}`;
                } else {
                    // Muestra un mensaje si no se encuentran datos del contador
                    this.presentToast('Connection rejected', 'red-snackbar');
                }
                resolve(serial);
            },
            error => {
                // Loguea y muestra un mensaje en caso de error
                console.error('Error fetching data:', error);
                this.presentToast('Connection error', 'red-snackbar');
                reject(serial);
            }
        );
    });
}


  getCerosSerial(number: any) {
    let length = number.toString().length;
    let missingLength = 4 - length;
    let stringToAnswer = '';
    for (let x = 0; x < missingLength; x++) {
      stringToAnswer += '0'
    }
    return stringToAnswer + number;
  }


}


