<!-- ============================================================== -->
<!-- Main wrapper -->
<!-- ============================================================== -->
<div class="horizontalGradient fullWScreen fullHScreen">
    <div id="loginBackWrapper"></div>
    <div class="rectangle" id="particles-js"></div>
    <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPasswordSubmit()">
        <div class="center login disabledAutofill" id="formWrapper">
            <div id="circle" class="center">
                <img id="logoImg" src="../../assets/img/logo-original.png" alt="logo adroit">
            </div>
            <div class="d12 labelAuth">Please enter and confirm your new password.</div>
            <div class="block loginSizes">
                <i class="icon-adroit-candado login-icon"></i>
                <mat-form-field class="fullWidth loginInput whiteOutline left bottom">
                    <mat-label class="loginWhiteLabel">Enter your new password</mat-label>
                    <input matInput type="password" formControlName="password" required>
                </mat-form-field>
            </div>
            <div class="block loginSizes">
                <i class="icon-adroit-candado login-icon"></i>
                <mat-form-field class="fullWidth loginInput whiteOutline left bottom">
                    <mat-label class="loginWhiteLabel">Confirm your new password</mat-label>
                    <input matInput type="password" formControlName="passwordConfirm" required>
                </mat-form-field>
            </div>
            <button mat-raised-button class="whiteRoundedButton block loginSizes" id="loginButton" type="submit" aria-label="Iniciar sesión">Update password</button>
            <p></p>
        </div>
    </form>
</div>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
