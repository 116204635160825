import { Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as fileSaver from 'file-saver';
import * as CryptoJS from 'crypto-js';


@Injectable()
export class ApiService {
  // Local variables //

  private url = environment.apiUrl;
  // private url = 'https://req.adroitoverseas.net/api/';
  // private url = 'http://localhost:3000/api/';
  // private url = 'http://4c70e2c78084.ngrok.io/api/';

  private fileUrl = this.url + 'Containers/uploads.adroitoverseas.net/upload';
  private fileDownoald = this.url + 'Containers/uploads.adroitoverseas.net/download/'
  // private fileUrl = this.url + 'https://apitest.adroitoverseas.net/api/Containers/uploads.adroitoverseas.net/upload/';
  
  private contractsUrl = this.url + 'LocalContainers/temporalfiles/upload';

  public currentUserValue = {};

  // Constructor //
  constructor(
      private http: HttpClient,
  ) {}

  // Obtain objects from 1 collection //
  getDataObjects( type: string) {
    /*let url: string = type;
    if (url.includes(/#/g)) {
      url.replace(/#/g, '%23');
    }*/
    type = type.replace(/#/g, '%23');
    return this.http.get(this.url + type, {headers:  new HttpHeaders({Authorization: this.getToken()}) });
  }

  // Obtain object per id //
  getDataObject( type: string, id: string) {
    // // console.log(this.url  + type + id);
    return this.http.get(this.url + type + '/' + id, {headers: new HttpHeaders({Authorization: this.getToken()}) });
  }
  // Renew User Tokens //
  deleteRelationInstances( type: string, id: string, relation:string) {
    // // console.log(this.url  + type + id);
    console.log(this.url + type + '/' + id + '/' + relation);
    return this.http.delete(this.url + type + '/' + id + '/' + relation, {headers: new HttpHeaders({Authorization: this.getToken()}) });
  }

  // Obtain object per id //
  getExistObject( type: string, id: string) {
    // // console.log(this.url  + type + id);
    return this.http.get(this.url + type + '/' + id + '/exists', {headers: new HttpHeaders({Authorization: this.getToken()}) });
  }

  // Add a new data object //
  addDataObject(model: any, type: string) {
    return this.http.post(this.url  + type, model, {headers: {Authorization: this.getToken()}});
  }

  // Add a new data object //
  addDataRelation(model: any, type: string, ralationType: string, id: string, fk: string) {
    return this.http.put(this.url  + type + '/' + id + '/' + ralationType + '/' + fk, model, {headers: new HttpHeaders({Authorization: this.getToken()}) });
  }

  // Edit object per id //
  editDataObject(id: string, model: any , type: string) {
    return this.http.patch(this.url + type + '/' + id, model, {headers: new HttpHeaders({Authorization: this.getToken()}) });
  }

  // Delete object per id //
  deleteDataObject(type: string, id: string) {
    return this.http.delete(this.url + type + '/' + id, {headers: new HttpHeaders({Authorization: this.getToken()}) });
  }

  // Obtain data to fill table //
  getTableDataObjects(query: string) {
    query = query.replace(/#/g, '%23');
    return this.http.get(this.url + query, {headers: new HttpHeaders({Authorization: this.getToken()}) });
  }


  // Obtain data from external services //
  getGenericDataObjects(query: string) {
    return this.http.get(query, {headers: new HttpHeaders({Authorization: this.getToken()}) });
  }

    // Obtain data from external services //
  getGoogleToken(code: string, type: string) {
    let clientId;
    let clientSecret;

    if(type == 'com'){
      clientId = "799780109579-v9ubl8n7rqpvh4fdveosll2ehhe8agup.apps.googleusercontent.com";
      clientSecret = "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";
    }else if(type == 'net'){
      clientId = "996010386618-o4ql34ruepppaacb2a1k72fq1j2kf8qi.apps.googleusercontent.com";
      clientSecret = "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";
    }
    
    const enviroment = 'prod';

    const url = 'https://www.googleapis.com/oauth2/v4/token';
    const body = 'code=' + code + '&client_id=' + clientId + '&client_secret=' + clientSecret
        + '&grant_type=authorization_code' + '&redirect_uri=' + 'https://adroitoverseas.net/profile/';

        console.log(url, body, 'requests');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',
        Authorization: 'authorization_code'
      })
    };

    return this.http.post(url, body, httpOptions);
  }

  useRemoteMethod(model: string, method: string, params: object) {
    const body = {
      ...params
    };
    return this.http.post(this.url + model + '/' + method, body, {headers: new HttpHeaders({Authorization: this.getToken()})});
  }

  useRemoteMethodString(model: string, method: string, data: string) {
    const body = {
      text:data
    }
    return this.http.post(this.url + model + '/' + method, body, {headers: new HttpHeaders({Authorization: this.getToken()})});
  }

  processImageAI(imageName){
    const body = {
      imageUrl: imageName
    }
    let url = this.url;
    /* let url = "http://localhost:3007/classifyUrlImage"; */
    return this.http.post(url + 'Containers' + '/' + 'processImage', body, {headers: new HttpHeaders({Authorization: this.getToken()})});
  }

  private isJsonString(str: string): boolean {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
}

  public decrypt(txtToDecrypt: string, key: string) {
    try {
      const decryptedText = CryptoJS.AES.decrypt(txtToDecrypt, key).toString(CryptoJS.enc.Utf8);
      return decryptedText;
    } catch (error) {
      console.error("Error during decryption:", error);
      return null;
    }
  }

  private getToken() {
    const data: any = localStorage.getItem('LH52NZe7Av') || '';
    const currentUser: any = (data) ? JSON.parse(this.decrypt(data, 'LH52NZe7Av')) : '';
    
    if (currentUser) {
      return currentUser.id;
    } else {
      return false;
    }
  }

  // Add file to the bucket
  addFile(file: File|Blob, fileName: string, fileType: string) {
    const formData = new FormData();
    formData.append(fileType, file, fileName);
    return this.http.post(this.fileUrl, formData);
  }

  // download data from container
  downloadFileScanned(filename: string){
    return this.http.get(this.fileDownoald + filename, {headers: new HttpHeaders({Authorization: this.getToken()}) });
  }

  // download data from container
  downloadFile(filename: string, downloadName){
    fileSaver.saveAs(this.fileDownoald + filename + '?access_token=' + this.getToken(), downloadName);  
  }

  

  // Add file to the bucket
  sendDailyReport(file: File, fileName: string, fileType: string, otherData: any) {
    const formData = new FormData();
    formData.append(fileType, file, fileName);
    return this.http.post(this.fileUrl, {...otherData, ...formData});
  }

  // Add image to the bucket
  addContracts(excel: File, id: string) {
    const formData = new FormData();
    formData.append('csv', excel, id);
    return this.http.post(this.contractsUrl, formData, {headers: new HttpHeaders({Authorization: this.getToken()})});
  }

  // Add image to the bucket
  addDailyReport(report: any, fileName: string) {
    const formData = new FormData();
    formData.append('image', report, fileName);
    return this.http.post(this.contractsUrl, formData, {headers: new HttpHeaders({Authorization: this.getToken()})});
  }

  // Obtain object per id //
  getCadDollar() {
    // // console.log(this.url  + type + id);
    return this.http.get('https://api.exchangeratesapi.io/latest?symbols=CAD&base=USD');
  }

  getDollarCad() {
    // // console.log(this.url  + type + id);
    return this.http.get('https://api.exchangeratesapi.io/latest?symbols=USD&base=CAD');
  }

  getDollarAED() {
    // // console.log(this.url  + type + id);
    return this.http.get('https://www.mycurrency.net/US.json');
  }

  getWeatherByAddress(city: string, country?: string, stateCode?: string) {
    let req = city;
    if (stateCode) {
      req = req + (',' + stateCode);
    }
    if (country) {
      req = req + (',' + country);
    }
    return this.http.get('https://api.openweathermap.org/data/2.5/weather?q=' + req +'&appid=806a1caca3b3dbd3bdbe0090c66cbfd6&units=metric');
  }

  getWeatherByLatLng(lat: number, lng: number) {
    return this.http.get('https://api.openweathermap.org/data/2.5/weather?lat=' + lat +'&lon=' + lng +'&appid=806a1caca3b3dbd3bdbe0090c66cbfd6&units=metric');
  }
  // Add image object (multiple images) to the bucket
  /*addImageObject(image: File[], id: string[]) {
    const formData = new FormData();
    for (let x = 0; x < image.length; x++) {
      formData.append('image', image[x], id[x]);
    }
  }*/
}
