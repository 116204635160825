<!-- title -->
<div class="row">
    <div class="col-12">
        <h2 class="canada-color text-title"> {{ dataIn.title | uppercase }} </h2>
    </div>
</div>
<!-- form -->
<form [formGroup]="addForm" (ngSubmit)="performRequest()">
    <!-- ORDER INFORMATION ROW -->
    <div class="row">
        <div class="col-12 formItem">
            <h2 class="canada-color">ORDER INFORMATION</h2>
        </div>

        <!-- Received Date -->
        <div class="col-6 col-md-4 col-lg-3">
            <mat-form-field appearance="outline" class="custom-disabled-form">
                <mat-label>Received Date</mat-label>
                <input matInput [matDatepicker]="picker1" formControlName="dateReceived" placeholder="Choose a date" autocomplete="off">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1 startView="month" ></mat-datepicker>
            </mat-form-field>
        </div>

        <!-- Plant -->
        <div class="col-6 col-md-4 col-lg-3">
            <mat-form-field appearance="outline" class="custom-disabled-form">
                <mat-label>Plant</mat-label>
                <mat-select placeholder="Plant" formControlName="plant">
                    <mat-option disabled>Select a plant</mat-option>
                    <mat-option *ngFor="let plant of plants" [value]="plant.id">{{ plant.name }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <!-- Product -->
        <div class="col-6 col-md-4 col-lg-3">
            <div class="basisInputContainer" *ngIf="dataIn.subject">
                <label class="inputLabel">Product</label>
                <div class="customInput">
                    <span class="fullHeight cut-text infoInput"> {{ dataIn.subject ? dataIn.subject.name : 'N/A' }}</span>
                    <mat-icon class="helpIcon" matTooltip="Product's name" matTooltipPosition="above">help_outline</mat-icon>
                </div>
            </div>
            <mat-form-field appearance="outline" class="custom-disabled-form">
                <mat-label>Product</mat-label>
                <input type="text" matInput placeholder="Available Products" id="product" #product formControlName="product" [matAutocomplete]="autoProduct" class="product">
                <mat-autocomplete #autoProduct="matAutocomplete" [displayWith]="displayFn">
                    <mat-option disabled>Product</mat-option>
                    <mat-option *ngFor="let product of (productOptions | async)" [value]="producto">
                        {{product.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div> 

        <!-- Plant Grade -->
        <div class="col-6 col-md-4 col-lg-3">
            <mat-form-field appearance="outline" class="custom-disabled-form">
                <mat-label>Plant Grade</mat-label>
                <mat-select placeholder="Plant grade" id="productGrade" formControlName="productGrade">
                    <mat-option disabled="">Select grade</mat-option>
                    <mat-option *ngFor="let grade of productGrades" [value]="grade.name" class="customOption">
                        <div class="d-flex flex-column justify-content-center pt-2 pb-2">
                            <p class="caption-black-bold">Grade: <span class="caption-black-regular"> {{grade.name ? grade.name : 'N/A'}} </span></p>
                        </div>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <!-- Loaded Date -->
        <div class="col-6 col-md-4 col-lg-3">
            <mat-form-field appearance="outline" class="custom-disabled-form">
                <mat-label>Loaded Date</mat-label>
                <input matInput [matDatepicker]="picker4" formControlName="dateLoaded" placeholder="Choose a date" autocomplete="off">
                <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                <mat-datepicker #picker4 startView="month" ></mat-datepicker>
            </mat-form-field>
        </div>

        <!-- Released Date -->
        <div class="col-6 col-md-4 col-lg-3">
            <mat-form-field appearance="outline" class="custom-disabled-form">
                <mat-label>Released Date</mat-label>
                <input matInput [matDatepicker]="picker2" formControlName="dateReleased" placeholder="Choose a date" autocomplete="off">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2 startView="month" ></mat-datepicker>
            </mat-form-field>
        </div>

        <!-- Picked Up Date -->
        <div class="col-6 col-md-4 col-lg-3">
            <mat-form-field appearance="outline" class="custom-disabled-form">
                <mat-label>Picked Up Date</mat-label>
                <input matInput [matDatepicker]="picker3" formControlName="datePickedUp" placeholder="Choose a date" autocomplete="off">
                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                <mat-datepicker #picker3 startView="month" ></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
    
    <!-- FREIGHT INFORMATION ROW -->
    <div class="row">
        <div class="col-12 formItem">
            <h2 class="canada-color">FREIGHT INFORMATION</h2>
        </div>

        <!-- Railcar Number -->
        <div class="col-6 col-md-4 col-lg-3">
            <mat-form-field appearance="outline" class="custom-form-field">
                <mat-label>Railcar Number</mat-label>
                <input type="text" matInput placeholder="Car Number" id="railCarNo" #railCarNo formControlName="railCarNo">
            </mat-form-field>
        </div>

        <!-- Demmurage Period -->
        <div class="col-6 col-md-4 col-lg-3">
            <mat-form-field appearance="outline" class="custom-form-field">
                <mat-label>Demmurage Period</mat-label>
                <input type="text" matInput placeholder="Demmurage Period" id="demmuragePeriod" #demmuragePeriod formControlName="demmuragePeriod">
            </mat-form-field>
        </div>

        <!-- Destination -->
        <div class="col-6 col-md-4 col-lg-3">
            <mat-form-field appearance="outline" class="custom-disabled-form">
                <mat-label>Destination</mat-label>
                <input type="text" matInput placeholder="Destination" id="destination" #destination formControlName="destination">
            </mat-form-field>
        </div>

        <!-- Transloader -->
        <div class="col-6 col-md-4 col-lg-3">
            <div class="basisInputContainer" *ngIf="dataIn.subject">
                <label class="inputLabel">Transloader</label>
                <div class="customInput">
                    <span class="fullHeight cut-text infoInput"> {{ dataIn.subject ? dataIn.subject.name : 'N/A' }}</span>
                    <mat-icon class="helpIcon" matTooltip="Transloader's name" matTooltipPosition="above">help_outline</mat-icon>
                </div>
            </div>
            <!----------------------->
            <!-- client (if !hasClient) -->
            <mat-form-field appearance="outline" [ngClass]="{'custom-form-field': this.editTransloader,'custom-disabled-form': !this.editTransloader}" >
                <mat-label>Transloader</mat-label>
                <input type="text" matInput placeholder="Available Transloaders" id="transloader" #transloader formControlName="transloader" [matAutocomplete]="autoTransloader" class="transloader">
                <mat-autocomplete #autoTransloader="matAutocomplete" [displayWith]="displayFn">
                    <mat-option disabled>Select One Transloader</mat-option>
                    <mat-option *ngFor="let transloader of (transloaderOptions | async)" [value]="transloader">
                        <div class="buyerContainer">
                            <p>{{transloader.name}}</p>
                        </div>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </div>

    <!-- LOAD INFORMATION ROW -->
    <div class="row">
        <div class="col-12 formItem">
            <h2 class="canada-color">LOAD INFORMATION</h2>
        </div>

        <!-- Railcar Seals -->
        <div class="col-6 col-md-4 col-lg-3">
            <mat-form-field appearance="outline" class="custom-disabled-form">
                <mat-label>Railcar Seals #</mat-label>
                <input type="text" matInput placeholder="Seal #" id="railSealsNo" #railSealsNo formControlName="railSealsNo">
            </mat-form-field>
        </div>

        <!-- Load Weight -->
        <div class="col-6 col-md-4 col-lg-3">
            <mat-form-field appearance="outline" class="custom-form-field" [ngClass]="{'custom-disabled-form': !this.addForm.get('loadWeight').enabled}">
                <mat-label>Load Weight</mat-label>
                <input type="number" matInput placeholder="Load Weight" id="loadWeight" #loadWeight formControlName="loadWeight">
            </mat-form-field>
        </div>

        <!-- BoL No -->
        <div class="col-6 col-md-4 col-lg-3">
            <mat-form-field appearance="outline" class="custom-form-field">
                <mat-label>BOL No</mat-label>
                <input type="text" matInput placeholder="BOL No" id="bolNo" #bolNo formControlName="bolNo">
            </mat-form-field>
        </div>

        <!-- BoL Weight -->
        <div class="col-6 col-md-4 col-lg-3">
            <mat-form-field appearance="outline" class="custom-form-field">
                <mat-label>BOL Weight</mat-label>
                <input type="number" matInput placeholder="BOL Weight" id="bolWeight" #bolWeight formControlName="bolWeight">
            </mat-form-field>
        </div>

        <!-- BoL Weight -->
        <div class="col-6 col-md-4 col-lg-3">
            <mat-form-field appearance="outline" class="custom-form-field">
                <mat-label>CFIA Weight</mat-label>
                <input type="number" matInput placeholder="CFIA Weight" id="cfiaWeight" formControlName="cfiaWeight">
            </mat-form-field>
        </div>

        <!-- Unload Weight -->
        <div class="col-6 col-md-4 col-lg-3">
            <mat-form-field appearance="outline" class="custom-disabled-form">
                <mat-label>Unload Weight</mat-label>
                <input type="number" matInput placeholder="Unload Weight" id="unloadWeight" #unloadWeight formControlName="unloadWeight">
            </mat-form-field>
        </div>

        <!-- SGS Grade -->
        <div class="col-6 col-md-4 col-lg-3">
            <mat-form-field appearance="outline" class="custom-disabled-form">
                <mat-label>SGS Grade</mat-label>
                <input type="text" matInput placeholder="SGS Grade" id="productGrade" #productGrade formControlName="productGrade">
            </mat-form-field>
        </div>

        <!-- Difference -->
        <div class="col-6 col-md-4 col-lg-3">
            <mat-form-field appearance="outline" class="custom-disabled-form">
                <mat-label>Difference</mat-label>
                <input type="number" matInput placeholder="Difference" id="difference" #difference formControlName="difference">
            </mat-form-field>
        </div>

        <!-- Shift Loaded -->
        <div class="col-6 col-md-4 col-lg-3">
            <mat-form-field appearance="outline" class="custom-disabled-form">
                <mat-label>Shift Loaded</mat-label>
                <mat-select placeholder="Shift Loaded" id="shiftLoaded" formControlName="shiftLoaded">
                    <mat-option disabled="">Select Option</mat-option>
                    <mat-option value="Morning">Morning</mat-option>
                    <mat-option value="Evening">Evening</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <!-- Comments -->
        <div class="col-12 formItem">
            <mat-form-field appearance="outline" class="custom-form-field">
                <mat-label>Comments</mat-label>
                <textarea matInput placeholder="Comments..." id="comments" formControlName="comments" rows="3"></textarea>
            </mat-form-field>
        </div>
    </div>

    <!-- CERTIFICATION SECTION-->
    <div class="row">
        <div class="col-6 col-md-6 col-sm-12 formItem">
            <h2 class="canada-color">CERTIFICATION SECTION</h2>
        </div>

        <div class="col-6 col-md-6 col-sm-12 d-flex justify-content-end align-items-center">
            <mat-label class="mr-3 text-subtitle-2">Not Aplicable: </mat-label>
            <mat-checkbox formControlName="sgsCert" class="custom-checkbox"><span class="text-subtitle-2">SGS Cert</span></mat-checkbox>
            <mat-checkbox formControlName="railBol" class="custom-checkbox"><span class="text-subtitle-2">Rail Bol</span></mat-checkbox>
            <mat-checkbox formControlName="cfiaId" class="custom-checkbox"><span class="text-subtitle-2">CFIA ID</span></mat-checkbox>
            <mat-checkbox formControlName="cfiaReSub" class="custom-checkbox"><span class="text-subtitle-2">CFIA Re-sub</span></mat-checkbox>
            <mat-checkbox formControlName="bulkWeight" class="custom-checkbox"><span class="text-subtitle-2">Bulk Weight</span></mat-checkbox>
        </div>
    </div>

    <!-- WAYBILL SECTION -->
    <div class="row">
        <!-- SGS Waybill -->
        <div class="col-6 col-md-4 col-lg-3" >
            <mat-form-field appearance="outline" class="custom-form-field">
                <mat-label>SGS Waybill</mat-label>
                <input type="text" matInput placeholder="Sgs Waybill" id="sgsWaybill" #sgsWaybill formControlName="sgsWaybill">
            </mat-form-field>
        </div>

        <!-- CFIA Waybill -->
        <div class="col-6 col-md-4 col-lg-3" >
            <mat-form-field appearance="outline" class="custom-form-field">
                <mat-label>CFIA Waybill</mat-label>
                <input type="text" matInput placeholder="Cfia Waybill #" id="cfiaWaybill" #cfiaWaybill formControlName="cfiaWaybill">
            </mat-form-field>
        </div>

        <!-- CFIA Waybill Re-sub -->
        <div class="col-6 col-md-4 col-lg-3" *ngIf="this.addForm.get('cfiaReSub').value === false">
            <mat-form-field appearance="outline" class="custom-form-field">
                <mat-label>CFIA Waybill Re-sub</mat-label>
                <input type="text" matInput placeholder="CFIA re-submission" id="cfiaWaybillRe" #cfiaWaybillRe formControlName="cfiaWaybillRe">
            </mat-form-field>
        </div>
    </div>

    <!-- DOCUMENTS SECTION ROW -->
    <div class="row">
        <!-- Sample pic + Tkt -->
        <div [ngClass]="{'col-12 col-md-4 col-sm-12' : sampleImgLbl.includes('Add '), 'col-12 col-md-3 col-sm-12' : !sampleImgLbl.includes('Add ')}">
            <input type="file" id="sampleImg" (change)="preAddImage('sampleImg')" hidden>
            <label for="sampleImg" class="uploadButton d-flex align-items-center">{{ sampleImgLbl }}
                <span *ngIf="!sampleImgLbl.includes('Add ')" class="material-icons-outlined ml-3 check-icon">check_circle_outline</span>
                <span *ngIf="sampleImgLbl.includes('Add ') " class="material-icons-outlined ml-3 advice-icon">info</span>
            </label>
        </div>

        <div *ngIf="!sampleImgLbl.includes('Add ')" class="col-2 col-md-1" (click)="downloadSigned(dataIn.prevContract.sampleImg)">
            <button class="btn uploadButton pb-0 pt-1" type="button">
                <span class="material-icons" >download</span>
            </button>
        </div>

        <!-- SGS cert -->
        <div [ngClass]="{'col-12 col-md-4 col-sm-12' : sgsImgLbl.includes('Add '), 'col-12 col-md-3 col-sm-12' : !sgsImgLbl.includes('Add ')}"  *ngIf="this.addForm.get('sgsCert').value === false">
            <input type="file" id="sgsImg" (change)="preAddImage('sgsImg')" hidden>
            <label for="sgsImg" class="uploadButton d-flex align-items-center">{{ sgsImgLbl }}
                <span *ngIf="!sgsImgLbl.includes('Add ')" class="material-icons-outlined ml-3 check-icon">check_circle_outline</span>
                <span *ngIf="sgsImgLbl.includes('Add ') " class="material-icons-outlined ml-3 advice-icon">info</span>
            </label>
        </div>

        <!-- SGS cert download Button -->
        <div *ngIf="!sgsImgLbl.includes('Add ') && this.addForm.get('sgsCert').value === false" class="col-2 col-md-1" (click)="downloadSigned(dataIn.prevContract.sgsImg)" >
            <button class="btn uploadButton pb-0 pt-1" type="button" >
                <span class="material-icons">download</span>
            </button>
        </div>

        <!-- Railbol -->
        <div  [ngClass]="{'col-12 col-md-4 col-sm-12' : scaleImgLbl.includes('Add '), 'col-12 col-md-3 col-sm-12' : !scaleImgLbl.includes('Add ')}" *ngIf="this.addForm.get('railBol').value === false">
            <input type="file" id="scaleImg" (change)="preAddImage('scaleImg')" hidden>
            <label for="scaleImg" class="uploadButton d-flex align-items-center">{{ scaleImgLbl }}
                <span *ngIf="!scaleImgLbl.includes('Add ')" class="material-icons-outlined ml-3 check-icon">check_circle_outline</span>
                <span *ngIf="scaleImgLbl.includes('Add ') " class="material-icons-outlined ml-3 advice-icon">info</span>
            </label>
        </div>

        <!-- Railbol download Button -->
        <div *ngIf="!scaleImgLbl.includes('Add ') && this.addForm.get('railBol').value === false" class="col-2 col-md-1" (click)="downloadSigned(dataIn.prevContract.scaleImg)">
            <button class="btn uploadButton pb-0 pt-1" type="button" >
                <span class="material-icons">download</span>
            </button>
        </div>

        <!-- Cfia -->
        <div [ngClass]="{'col-12 col-md-4 col-sm-12' : cfiaImgLbl.includes('Add '), 'col-12 col-md-3 col-sm-12' : !cfiaImgLbl.includes('Add ')}" *ngIf="this.addForm.get('cfiaId').value === false">
            <input type="file" id="cfiaImg" (change)="preAddImage('cfiaImg')" hidden>
            <label for="cfiaImg" class="uploadButton d-flex align-items-center">{{ cfiaImgLbl }}
                <span *ngIf="!cfiaImgLbl.includes('Add ')" class="material-icons-outlined ml-3 check-icon">check_circle_outline</span>
                <span *ngIf="cfiaImgLbl.includes('Add ') " class="material-icons-outlined ml-3 advice-icon">info</span>
            </label>
        </div>

        <!-- Cfia download Button -->
        <div *ngIf="!cfiaImgLbl.includes('Add ') && this.addForm.get('cfiaId').value === false" class="col-2 col-md-1" (click)="downloadSigned(dataIn.prevContract.cfiaImg)">
            <button class="btn uploadButton pb-0 pt-1" type="button" >
                <span class="material-icons">download</span>
            </button>
        </div>

        <!-- Cfia re sub -->
        <div [ngClass]="{'col-12 col-md-4 col-sm-12' : recfiaImgLbl.includes('Add '), 'col-12 col-md-3 col-sm-12' : !recfiaImgLbl.includes('Add ')}" *ngIf="this.addForm.get('cfiaReSub').value === false">
            <input type="file" id="recfiaImg" (change)="preAddImage('recfiaImg')" hidden>
            <label for="recfiaImg" class="uploadButton d-flex align-items-center">{{ recfiaImgLbl }}
                <span *ngIf="!recfiaImgLbl.includes('Add ')" class="material-icons-outlined ml-3 check-icon">check_circle_outline</span>
                <span *ngIf="recfiaImgLbl.includes('Add ') " class="material-icons-outlined ml-3 advice-icon">info</span>
            </label>
        </div>

        <!-- Cfia re sub download Button -->
        <div *ngIf="!recfiaImgLbl.includes('Add ') && this.addForm.get('cfiaReSub').value === false" class="col-2 col-md-1" (click)="downloadSigned(dataIn.prevContract.recfiaImg)">
            <button class="btn uploadButton pb-0 pt-1" type="button" >
                <span class="material-icons">download</span>
            </button>
        </div>

        <!-- Bulk weigh -->
        <div [ngClass]="{'col-12 col-md-4 col-sm-12' : bulkWeightImgLbl.includes('Add '), 'col-12 col-md-3 col-sm-12' : !bulkWeightImgLbl.includes('Add ')}" *ngIf="this.addForm.get('bulkWeight').value === false">
            <input type="file" id="bulkWeightImg" (change)="preAddImage('bulkWeightImg')" hidden>
            <label for="bulkWeightImg" class="uploadButton d-flex align-items-center">{{ bulkWeightImgLbl }}
                <span *ngIf="!bulkWeightImgLbl.includes('Add ')" class="material-icons-outlined ml-3 check-icon">check_circle_outline</span>
                <span *ngIf="bulkWeightImgLbl.includes('Add ') " class="material-icons-outlined ml-3 advice-icon">info</span>
            </label>
        </div>

        <!-- Bulk weigh download Button -->
        <div *ngIf="!bulkWeightImgLbl.includes('Add ') && this.addForm.get('bulkWeight').value === false" class="col-2 col-md-1" (click)="downloadSigned(dataIn.prevContract.bulkWeightImg)">
            <button class="btn uploadButton pb-0 pt-1" type="button" >
                <span class="material-icons">download</span>
            </button>
        </div>
    </div>

    <!-- BUTTONS ROW -->
    <div class="btn-flex-row mt-2">
        <button type="button" class="mexicoButton btn-secondary-dim" matRipple (click)="onNoClick()">
            <span class="">CANCEL</span>
        </button>

        <button *ngIf="!dataIn.isNew && !dataIn.isClone && getDeleteAccess" type="button" class="mexicoButton btn-secondary" matRipple (click)="deleteObject()">
            <span class="">DELETE</span>
        </button>

        <button class="canada-color-bg mexicoButton" matRipple type="submit" [disabled]="disableButton">
            <span class="">SAVE</span>
        </button>
    </div>
</form>


