import {AfterViewInit, Injectable, OnInit} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})

export class SharingService {

/** This variable is to know that we are in the nutritional section of the role menu.
 * 
 *
 * @memberof SharingService
 */
 public isNutritional = true;
  // --------------------------- //
  // Local variables declaration //
  // --------------------------- //
  private onDestroy = new Subject<void>();

  public serviceData: BehaviorSubject<any> = new BehaviorSubject(false);
  public serviceDataObservable: Observable<any> = this.serviceData.asObservable();

  public newClientSearch: BehaviorSubject<any>;

  public suppliersSearch: BehaviorSubject<any>;
  public transportsSearch: BehaviorSubject<any>;
  public buyersearch: BehaviorSubject<any>;

  public groupData: any = null;
  public plantData: any = null;
  public clientsScroll: number = 0;

  // indicadors form winnipeg dashboard
  public goToDealsFromDash: string = '';
  public goToFMOSFromDash: string = '';

  // service to set crm subject as tradibk or farm
  public isTrackSubject: boolean;

  /**
   * shared variable to know which column is selected in the SimpleTable
   *
   * @memberof SharingService
   */
  public selectedCol: string = '';


  // value from calculators
  public calculatorSharingValues = {
    track: 0,
    brokerageS: 0,
    exchangeUSDS: 0,
    workPriceS: 0
  };

  public trackValue = 0;
  public hasUnload = 0;
  public availableShipmentQty = 0;
  public indexRow = 0;

  // type on indicator, setting by the graphic
  public purchasesIndicator: Subject<boolean> = new Subject<boolean>();
  public purchasesIndicatorObservable: Observable<any> = this.purchasesIndicator.asObservable();

  public rootRole: BehaviorSubject<any> = new BehaviorSubject(false);

  constructor() {
    // // console.log('setting clientSearch');
    this.newClientSearch = new BehaviorSubject(JSON.parse(localStorage.getItem('clientSearch')));
    // // console.log('init sharing observable');
    this.newClientSearch.asObservable().pipe(takeUntil(this.onDestroy)).subscribe((clientSearch) => {
      // // console.log('clientSearch changed', clientSearch);
      if (clientSearch) {
        localStorage.setItem('clientSearch', JSON.stringify(clientSearch));
      } else {
        if (localStorage.getItem('clientSearch')) {
          localStorage.removeItem('clientSearch');
        }
      }
    });
  }

  // --------------------- //
  // ON DESTROY LIFE CYCLE //
  // --------------------- //
  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.unsubscribe();
  }
}
