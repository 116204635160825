import {Injectable, OnDestroy} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import { takeUntil} from 'rxjs/operators';
import {ApiService} from '../api/api.service';
import {SharingService} from '../sharing/sharing.service';
import { QueryFactory } from 'src/app/tableQueries/queryFactory';


@Injectable({
  providedIn: 'root'
})
export class BranchService implements OnDestroy {

  // --------------------------- //
  // Local variables declaration //
  // --------------------------- //
  private key = 'zIeNztZGUg';
  private onDestroy = new Subject<void>();
  public branchOptions: any[] = [];
  public currentBranch: BehaviorSubject<any> = new BehaviorSubject(false);
  public branchObservable: Observable<any>;
  public branchesLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public currentUserGroups: any[] = [];
  public currentUserGroupIds: string[] = [];
  public firstLoad = true;
  public prevBranch: string
  // ----------------------//
  // Component constructor //
  // ----------------------//
  constructor(
      private authService: AuthService,
      public queryFactory: QueryFactory,
      private apiService: ApiService,
      private sharingService: SharingService
  ) {
    /* OBTAIN BRANCH OPTIONS FROM LOGGED USER  */

    this.authService.userObservable.pipe(takeUntil(this.onDestroy)).subscribe((user) => {
      if (user) {
        this.getBranchOptions(user);
      }
    });
  }

  // ----------------------------------------------------//
  // OBTAIN BRANCH OPTIONS DEPENDING ON LOGGED USER ROLE //
  // ----------------------------------------------------//
  private getBranchOptions(user: any) {
    if (user.user.role === 'generalAdmin') {
      this.apiService.getDataObjects('Branches'  + '?filter=' + JSON.stringify({ where: { isActive: true }
      })).pipe(takeUntil(this.onDestroy)).subscribe((res: any) => {
        if (res) {
          this.branchOptions = res;
          this.checkUserBranch(user)
          this.getCurrentBranch();
        }
      });

    } else {

      this.apiService.getDataObject('AppUsers', user.user.id + '?filter=' + JSON.stringify({
        include: [{ relation: 'branches' }, { relation: 'groups' }],
        fields: ['id']
      })).pipe(takeUntil(this.onDestroy)).subscribe((res: any) => {

        if (res) {
          this.branchOptions = res.branches;
          this.checkUserBranch(user)
          this.currentUserGroups = res.groups ? res.groups : [];
          const groupIds: string[] = [];
          res.groups.forEach((group) => groupIds.push(group.id));
          this.currentUserGroupIds = groupIds;
          this.getCurrentBranch();
        }
      });

    }
  }

  getBranchColor() {
    return this.currentBranch ? this.currentBranch.getValue().color : '#202d42';
  }

  checkUserBranch(user){
    this.prevBranch = null;
    if(user)
    this.branchOptions.some((value)=>{
       if(value.id === (user && user.user && user.user.prevBranch ? user.user.prevBranch : false)){
        this.prevBranch = value;
        user.user.prevBranch = '';
        return true;
       } 
    })
  }

  // ------------------------------------------------------------------//
  // OBTAIN CURRENT SELECTED BRANCH FROM INDEXEDDB OR SELECT FIRST ONE //
  // ------------------------------------------------------------------//
  public getCurrentBranch() {
    const data: any = localStorage.getItem('zIeNztZGUg') || '';
    const decryptedData = this.authService.decrypt(data, 'zIeNztZGUg');
    const branch: any = (decryptedData) ? JSON.parse(decryptedData) : '';


    if (branch) {
      const index = this.branchOptions.findIndex(newBranch => newBranch.id === branch.id);
      if (index !== -1) {
        this.currentBranch.next(this.branchOptions[index]);
      } else {
        this.currentBranch.next(this.branchOptions[0]);
      }
    } else {
      if(this.prevBranch){
        this.currentBranch.next(this.prevBranch)
        this.prevBranch = null;   
      }else{
        this.currentBranch.next(this.branchOptions[0]);
      }
    }
    this.addObservables();
  }

  // ---------------------------------------------------------------------------- //
  // ADD OBSERVABLES TO SELECTED BRANCH, THIS SAVES CURRENT SELECTED ON INDEXEDDB //
  // ---------------------------------------------------------------------------- //
  private addObservables() {
    this.branchObservable = this.currentBranch.asObservable();
    this.branchObservable.pipe(takeUntil(this.onDestroy)).subscribe((branch) => {
      if (!this.firstLoad) {
        this.sharingService.serviceData.next(false);
        this.sharingService.groupData = null;
        if (localStorage.getItem('currentClient')) {
          localStorage.removeItem('currentClient');
        }
      } else {
        this.firstLoad = false;
      }

      if (branch) {
        localStorage.setItem('zIeNztZGUg', this.authService.encrypt(JSON.stringify(branch), 'zIeNztZGUg'));
      }
    });
    this.branchesLoaded.next(true);
  }

  public getGoogleRestrictions() {
    /*if (this.currentBranch.getValue().name === 'Canada') {
      return {country: ['ca', 'us']};
    } else if (this.currentBranch.getValue().name === 'Mexico') {
      return {country: ['mx', 'us']};
    }*/
    return false;
  }

  public getBranch() {
    return this.currentBranch.getValue().name.toLowerCase()
  }
  //Function to return the local currency of the current branch
  public async getBranchCurrency() {
    const whereQuery = { id: this.currentBranch.getValue().id };
    const getQuery = this.queryFactory
    .generateGetQuery('Branches', whereQuery, 0 , 0, 'createdAt desc', [], [])
    
    const data: any = await this.apiService.getDataObjects(getQuery).toPromise();
    return data[0].currency
  }

  // --------------------- //
  // ON DESTROY LIFE CYCLE //
  // --------------------- //
  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.unsubscribe();
  }
}
