import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from '../services/auth/auth.service';
// import * as CryptoJS from 'crypto-js';


@Injectable({
    providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService
    ) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const dataLS: any = localStorage.getItem('LH52NZe7Av') || '';
        let currentUserDecrypted = this.authService.decrypt(dataLS, 'LH52NZe7Av');
        let currentUser = (currentUserDecrypted) ? JSON.parse(currentUserDecrypted) : '' ;
        
        const isLogin = request.url.includes('login');
        const isInternal = request.url.includes('adroit');
        if (currentUser && currentUser.id && !isLogin && isInternal) {
            request = request.clone({
                setHeaders: {
                    Authorization: currentUser.id
                }
            });
        }
        return next.handle(request);
    }
}
