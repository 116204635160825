import {AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../../services/api/api.service';
import {SharingService} from '../../../services/sharing/sharing.service';
import {LoadingService} from '../../../services/loading/loading.service';
import {QueryFactory} from '../../../tableQueries/queryFactory';
import {debounceTime, pairwise, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {BranchService} from '../../../services/branch/branch.service';
import {AuthService} from '../../../services/auth/auth.service';
import {ModalConfirmComponent} from '../../desicion-modals/modal-confirm/modal-confirm.component';
import { detailExpand, fadeInBottom, fadeInTop } from 'src/app/animations/animatedComponents';
import { ImportDetailsModalComponent } from './import-details-modal/import-details-modal.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE  } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

export interface dataIn {
  subject: any;
  filterProp: string;
  relatedTo: string;
  isPurchases: boolean;

  title: string;
  isNew: boolean;
  prevContract: any;
  branch: any;
  isClone: boolean;
}

export class objectValidator {
  static validData(control: UntypedFormControl) {
    if (typeof control.value !== 'object') {
      return ({validData: true});
    } else {
      return (null);
    }
  }
}

export const DateFormats = {
  parse: {
      dateInput: ['DD-MMM-YYYY']
  },
  display: {
      dateInput: 'DD-MMM-YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-dubai-inward-modal',
  templateUrl: './dubai-inward-modal.component.html',
  styleUrls: ['./dubai-inward-modal.component.scss'],
  animations:[fadeInBottom,fadeInTop],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
      { provide: MAT_DATE_FORMATS, useValue: DateFormats }
  ]
})
export class DubaiInwardModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('secondRow') set secondRow(content:ElementRef){
    if(content){
      this.heightTop = content.nativeElement.offsetHeight
    }
    
  }
  public heightTop=0
  // --------------------------- //
  // Local variables declaration //
  // --------------------------- //
  public importDetails:any[] = [];
  public totalPurchaseCosto = 0;
  public totalCosto = 0;
  private onDestroy = new Subject<void>();
  public ownerOptions: Observable<any>;
  public availableOptions: Observable<any>;
  public productOptions: Observable<any>;
  public plantOptions: Observable<any>;
  public transloaderOptions: Observable<any>;
  public buyerOptions: Observable<any>;
  public supplierOptions: Observable<any>;
  public contractOptions: Observable<any>;
  public originOptions: Observable<any>;
  public participantBelongs: any[] = [];
  public contractSelected = false;
  public isThird = false;
  public plants = [];
  public loadTotal = 0;
  public totalQty = 0;
  public today = new Date();
  public packs = [];
  public noteContent = '';
  private datePipe: DatePipe;
  public genLots = new UntypedFormControl({value: '', disabled: false});
  public AED = new UntypedFormControl({value: 'USD', disabled: false});
  public wareHouseLocation = new UntypedFormControl({value: '', disabled: false}, Validators.required);
  public quillConfig = {
    toolbar: [
      ['bold', 'underline'],        // toggled buttons

      [{ list: 'bullet' }],
      [{ indent: '-1'}, { indent: '+1' }],          // outdent/indent
      [{ size: ['small', false, 'large'] }],  // custom dropdown

      [{ color: [] }, { background: [] }],          // dropdown with defaults from theme
    ]
  };
  public currentRole: any = 'purchases';
  private prevReminder: any = false;
  private hasChanges = false;
  public isPurchases = false;
  public userRole = '';

  public isPulses = false;
  public isWinnipeg = false;

  public isTransports = false;
  public isClients = false;
  public isBuyers = false;
  public isSuppliers = false;
  public lastPurchaseCounter = 0;
  public lastSalesCounter = 0;
  public disableButton = false;

  // Component constructor //
  constructor(
      private dialogRef: MatDialogRef<DubaiInwardModalComponent>,
      private fb: UntypedFormBuilder,
      public router: Router,
      private apiService: ApiService,
      private snackBar: MatSnackBar,
      private loadingService: LoadingService,
      private queryFactory: QueryFactory,
      private dialog: MatDialog,
      private branchService: BranchService,
      private sharingService: SharingService,
      @Inject(MAT_DIALOG_DATA) public dataIn: dataIn,
      private authService: AuthService
  ) { }

  // Form crud-inputs & validations declaration //
  addForm = this.fb.group({
    receivingDate : new UntypedFormControl({value: null, disabled: false}, Validators.required),
    product : new UntypedFormControl({value: '', disabled: false}, Validators.required),
    //supplier : new FormControl({value: '', disabled: false}, Validators.required),
    supplierName : new UntypedFormControl({value: '', disabled: false}, Validators.required),
    contract : new UntypedFormControl({value: '', disabled: false}, Validators.required),
    importer : new UntypedFormControl({value: '', disabled: true}, Validators.required),
    origin : new UntypedFormControl({value: '', disabled: true}, Validators.required),
    packing : new UntypedFormControl({value: '', disabled: true}, Validators.required),
    purposePlan : new UntypedFormControl({value: '', disabled: false}, Validators.required),
    bagSizes : new UntypedFormControl({value: '', disabled: false}, Validators.required),
    importBlNo : new UntypedFormControl({value: '', disabled: false}, Validators.required),
    containerNo : new UntypedFormControl({value: '', disabled: false}),
    qtyLoad : new UntypedFormControl({value: '', disabled: true}, Validators.required),
    qtyUnload : new UntypedFormControl({value: '', disabled: true}),
    qty : new UntypedFormControl({value: '', disabled: true}, Validators.required),
    currency : new UntypedFormControl({value: '', disabled: true}, Validators.required), 
    purchasePrice : new UntypedFormControl({value: '', disabled: true}, Validators.required),
    fxRate : new UntypedFormControl({value: 3.6725, disabled: false}, Validators.required),
    basisTerms : new UntypedFormControl({value: '', disabled: true}, ),
    basisLocation : new UntypedFormControl({value: '', disabled: true}, Validators.required),
    wastage : new UntypedFormControl({value: '', disabled: false}),
    supplierInvoiceNo : new UntypedFormControl({value: '', disabled: false}),
    importRemarks : new UntypedFormControl({value: '', disabled: false}),
    shortage : new UntypedFormControl({value: '', disabled: true}),
    clearanceDate : new UntypedFormControl({value: '', disabled: false}),
    
    // COST USD
    handlingFee : new UntypedFormControl({value: '', disabled: false}),
    unloadingCost : new UntypedFormControl({value: 20, disabled: false}),
    cleaningCost : new UntypedFormControl({value: 20, disabled: false}),
    costOfBags : new UntypedFormControl({value: 10, disabled: false}),
    costTotalUsd : new UntypedFormControl({value: '', disabled: true}),
    totalPurchaseCostUsd : new UntypedFormControl({value: '', disabled: true}),
    totalAfterWastage : new UntypedFormControl({value: '', disabled: true}),
    customClearance : new UntypedFormControl({value: '', disabled: false}),
    truckingCharges : new UntypedFormControl({value: '', disabled: false}),
    baggingLabourCost : new UntypedFormControl({value: '', disabled: false}),
    storageCost : new UntypedFormControl({value: '', disabled: false}),
    costTotal: new UntypedFormControl({value:'', disabled:true}),
    //cost usd to aed
    handlingFeeAED : new UntypedFormControl({value: '', disabled: false}),
    unloadingCostAED : new UntypedFormControl({value: (20 / 3.675).toFixed(3), disabled: false}),
    cleaningCostAED : new UntypedFormControl({value: (20 / 3.675).toFixed(3), disabled: false}),
    costOfBagsAED : new UntypedFormControl({value: (10 / 3.675).toFixed(3), disabled: false}),
    costTotalUsdAED : new UntypedFormControl({value: '', disabled: true}),
    totalPurchaseCostUsdAED : new UntypedFormControl({value: '', disabled: true}),
    totalAfterWastageAED : new UntypedFormControl({value: '', disabled: true}),
    customClearanceAED : new UntypedFormControl({value: '', disabled: false}),
    truckingChargesAED : new UntypedFormControl({value: '', disabled: false}),
    baggingLabourCostAED : new UntypedFormControl({value: '', disabled: false}),
    storageCostAED : new UntypedFormControl({value: '', disabled: false}),

    // advancedProcessingCost : new FormControl({value: '', disabled: false}),
    // seaPort : new FormControl({value:'', disabled: false},Validators.required)
    // totalCostUSD : new FormControl({value: '', disabled: false}),
    // fxRate : new FormControl({value: '', disabled: false}),
    //wareHouseLocation : new FormControl({value: '', disabled: false}),
  });

  getLatestFx(){
    let filter = {
      where: {
        branchId: this.branchService.currentBranch.getValue().id
      },
      order: 'createdAt DESC',
      limit: 1
    };

    this.apiService.getDataObjects('Fxs?filter=' + JSON.stringify(filter)).subscribe((data: any) => {
      if(data.length > 0){
        // this.addForm.get('fxRate').setValue(data[0].fx.toFixed(3));
      }
    });
  }

  setToEmpty(id:string){
    !this.addForm.get(id).value ? this.addForm.get(id).setValue('') : false
  }

  get totalWeight(){
    let acc=0;
    this.importDetails.forEach(element => {
      acc += Number(element.BLWeight)
    });
    return acc
  }

  get totalActualWeight(){
    let acc = 0;
    this.importDetails.forEach(element=>{
      acc += Number(element.actualWeight)
    })
    return acc
  }

  getNetWeight() {
    let loadTotal = 0;
    let shortageTotal = 0;
    let unloadTotal = 0;
    if (this.dataIn.isNew) {
      for(const array of this.importDetails){
        loadTotal += array.actualWeightMt;
        unloadTotal += array.unloadWeightMt;
        shortageTotal += array.shortage
      }
      //Unload Qty
      this.addForm.patchValue({
        qtyUnload: parseFloat(unloadTotal.toFixed(2))
      },{emitEvent: false})
      this.addForm.patchValue({
        qtyLoad: parseFloat(loadTotal.toFixed(2))
      },{emitEvent: false})

    } else {
      loadTotal = this.addForm.get('qtyLoad').value;
      shortageTotal = this.addForm.get('shortage').value;
      unloadTotal = this.addForm.get('qtyUnload').value;
    }
    this.totalQty = loadTotal - shortageTotal;
    if(loadTotal) {
      //Merma
      this.addForm.get('shortage').patchValue(parseFloat(shortageTotal.toFixed(2)));

      // Net Qty
      if(this.addForm.get('wastage').value){
        let individualWastage = this.addForm.get('wastage').value / this.importDetails.length;
        for (const container of this.importDetails) {

          container.wastage  = individualWastage;
        }
        this.addForm.get('qty').patchValue(parseFloat((this.totalQty - this.addForm.get('wastage').value).toFixed(2)));
      }else{
        this.addForm.get('qty').patchValue(parseFloat(this.totalQty.toFixed(2)));
      }


      // Update containers
      // for (const container of this.importDetails) {
      //   container.qtyLoad  = container.actualWeightMt + individualshortage;
      //   container.shortage = individualshortage;
      //   container.wastage  = individualWastage;
      // }

      this.costTotal()
    }
  }

  costTotal(){
    let purchasePrice = Number(this.addForm.get('purchasePrice').value);
    let currency = this.addForm.get('currency').value;
    if (currency) {
      purchasePrice = currency == 'USD' ? purchasePrice : purchasePrice / this.addForm.get('fxRate').value;
    }

    this.addForm.get('costTotal').setValue((
      Number(this.addForm.get('customClearance').value) +
      Number(this.addForm.get('truckingCharges').value) +
      Number(this.addForm.get('baggingLabourCost').value) +
      Number(this.addForm.get('storageCost').value)
    ))
    let handling = (Number(this.addForm.get('handlingFee').value) * (purchasePrice / 100));

    let costTotalUsd = (
      (Number(this.addForm.get('costTotal').value)) +
      handling + 
      Number(this.addForm.get('unloadingCost').value) + 
      Number(this.addForm.get('costOfBags').value) + 
      Number(this.addForm.get('cleaningCost').value)
    );

    costTotalUsd = parseFloat(costTotalUsd.toFixed(3));
    this.addForm.get('costTotalUsd').setValue(costTotalUsd);

    let purchaseCost = (Number(this.addForm.get('costTotalUsd').value) + purchasePrice);
    purchaseCost = parseFloat(purchaseCost.toFixed(3));
    this.addForm.get('totalPurchaseCostUsd').setValue(purchaseCost);

    this.addForm.get('costTotalUsdAED').setValue((costTotalUsd * this.addForm.get('fxRate').value).toFixed(3))
    this.addForm.get('totalPurchaseCostUsdAED').setValue((purchaseCost * this.addForm.get('fxRate').value).toFixed(3))
    // Add individual wastage
    let wastage = this.addForm.get('wastage').value;
    purchaseCost = ((purchaseCost / this.addForm.get('qty').value) * this.addForm.get('qtyLoad').value);
    purchaseCost = parseFloat(purchaseCost.toFixed(3));
    this.addForm.get('totalAfterWastage').setValue(purchaseCost);
    this.addForm.get('totalAfterWastageAED').setValue((purchaseCost * this.addForm.get('fxRate').value).toFixed(3));
  }

  public IDForm = this.fb.group({
    // description : new FormControl({value: '', disabled: false},[Validators.required]),
    details : new UntypedFormControl({value: '', disabled: false},[Validators.required]),
    BLWeight : new UntypedFormControl({value: '', disabled: false}),
    actualWeight : new UntypedFormControl({value: '', disabled: false}),
  })

  newID(){
    console.log(this.totalWeight)
    const newQty = this.totalWeight + Number(this.IDForm.get('BLWeight').value)
    const newAQty = this.totalActualWeight + Number(this.IDForm.get('actualWeight').value)
    if(this.IDForm.valid){
      if(true){
        this.importDetails.push(this.IDForm.value);
        this.presentToast('Import Detail added','green-snackbar')
        this.IDForm.reset()
        console.log(this.importDetails)
        return true
      }else{
        this.presentToast('Weight exceeded','yellow-snackbar')
        return false
      }
    }
    else{
      this.presentToast('Error in Import Detail','yellow-snackbar')
      return false
    }
  }

  addCostInputListeners(){
    // Contract
    this.addForm.get('fxRate').valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((status) => {
      this.getNetWeight();
    });
    this.addForm.get('purchasePrice').valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((status) => {
      this.costTotal();
    });
    this.addForm.get('wastage').valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((status) => {
      this.addForm.get('qty').patchValue(this.totalQty - status);
      this.getNetWeight();
    });
    this.addForm.get('qtyLoad').valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((status) => {
      this.getNetWeight();
    });
    this.addForm.get('qtyUnload').valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((status) => {
      this.getNetWeight();
    });
    // Cost AED
    this.addForm.get('customClearance').valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((status) => {
      this.costTotal();
    });
    this.addForm.get('truckingCharges').valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((status) => {
      this.costTotal();
    });
    this.addForm.get('baggingLabourCost').valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((status) => {
      this.costTotal();
    });
    this.addForm.get('storageCost').valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((status) => {
      this.costTotal();
    });
    // cost USD
    this.addForm.get('handlingFee').valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((status) => {
      this.costTotal();
    });
    this.addForm.get('unloadingCost').valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((status) => {
      this.costTotal();
    });
    this.addForm.get('costOfBags').valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((status) => {
      this.costTotal();
    });
    this.addForm.get('cleaningCost').valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((status) => {
      this.costTotal();
    });
    this.addForm.get('handlingFeeAED').valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((status) => {
      this.addForm.get('handlingFee').patchValue(this.addForm.get('handlingFeeAED').value);
      this.costTotal();
    });
    this.addForm.get('unloadingCostAED').valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((status) => {
      let unloading = this.addForm.get('unloadingCostAED').value;
      this.addForm.get('unloadingCost').patchValue(unloading ? (unloading / this.addForm.get('fxRate').value).toFixed(3) : 0)
      this.costTotal();
    });
    this.addForm.get('costOfBagsAED').valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((status) => {
      let bags = this.addForm.get('costOfBagsAED').value;
      this.addForm.get('costOfBags').patchValue(bags ? (bags / this.addForm.get('fxRate').value).toFixed(3) : 0)
      this.costTotal();
    });
    this.addForm.get('cleaningCostAED').valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((status) => {
      let cleaning = this.addForm.get('cleaningCostAED').value;
      this.addForm.get('cleaningCost').patchValue(cleaning ? (cleaning / this.addForm.get('fxRate').value).toFixed(3) : 0)
      this.costTotal();
    });
    this.addForm.get('customClearanceAED').valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((status) => {
      let customClearance = this.addForm.get('customClearanceAED').value;
      this.addForm.get('customClearance').patchValue(customClearance ? (customClearance / this.addForm.get('fxRate').value).toFixed(3) : 0)
      this.costTotal();
    });
    this.addForm.get('truckingChargesAED').valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((status) => {
      let truckingCharges = this.addForm.get('truckingChargesAED').value;
      this.addForm.get('truckingCharges').patchValue(truckingCharges ? (truckingCharges / this.addForm.get('fxRate').value).toFixed(3) : 0)
      this.costTotal();
    });
    this.addForm.get('baggingLabourCostAED').valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((status) => {
      let baggingLabourCost = this.addForm.get('baggingLabourCostAED').value;
      this.addForm.get('baggingLabourCost').patchValue(baggingLabourCost ? (baggingLabourCost / this.addForm.get('fxRate').value).toFixed(3) : 0)
      this.costTotal();
    });
    this.addForm.get('storageCostAED').valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((status) => {
      let storageCost2 = this.addForm.get('storageCostAED').value;
      this.addForm.get('storageCost').patchValue(storageCost2 ? (storageCost2 / this.addForm.get('fxRate').value).toFixed(3) : 0)
      this.costTotal();
    });
    this.AED.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((status) => {
      if(this.AED.value == "AED"){
        this.usdtoaed();
      }else{
        this.aedtousd();
      }
    });
    // this.addForm.get('advancedProcessingCost').valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((status) => {
    //   this.costTotal();
    // });
    // this.addForm.get('baggingLabourCost').valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((status) => {
    //   this.costTotal();
    // });
  }
  aedtousd(){
    // cost AED TO USD
    let handling = this.addForm.get('handlingFeeAED').value;
    let unloading = this.addForm.get('unloadingCostAED').value;
    let bags = this.addForm.get('costOfBagsAED').value;
    let cleaning = this.addForm.get('cleaningCostAED').value;
    let customClearance = this.addForm.get('customClearanceAED').value;
    let truckingCharges = this.addForm.get('truckingChargesAED').value;
    let baggingLabourCost = this.addForm.get('baggingLabourCostAED').value;
    let storageCost2 = this.addForm.get('storageCostAED').value;
    this.addForm.get('handlingFee').patchValue(handling ? (handling) : 0)
    this.addForm.get('unloadingCost').patchValue(unloading ? (unloading / this.addForm.get('fxRate').value).toFixed(3) : 0)
    this.addForm.get('costOfBags').patchValue(bags ? (bags / this.addForm.get('fxRate').value).toFixed(3) : 0)
    this.addForm.get('cleaningCost').patchValue(cleaning ? (cleaning / this.addForm.get('fxRate').value).toFixed(3) : 0)
    this.addForm.get('customClearance').patchValue(customClearance ? (customClearance / this.addForm.get('fxRate').value).toFixed(3) : 0)
    this.addForm.get('truckingCharges').patchValue(truckingCharges ? (truckingCharges / this.addForm.get('fxRate').value).toFixed(3) : 0)
    this.addForm.get('baggingLabourCost').patchValue(baggingLabourCost ? (baggingLabourCost / this.addForm.get('fxRate').value).toFixed(3) : 0)
    this.addForm.get('storageCost').patchValue(storageCost2 ? (storageCost2 / this.addForm.get('fxRate').value).toFixed(3) : 0)
    this.costTotal();
  }

  usdtoaed(){
    // cost USD TO AED
    let handling = this.addForm.get('handlingFee').value;
    let unloading = this.addForm.get('unloadingCost').value;
    let bags = this.addForm.get('costOfBags').value;
    let cleaning = this.addForm.get('cleaningCost').value;
    let customClearance = this.addForm.get('customClearance').value;
    let truckingCharges = this.addForm.get('truckingCharges').value;
    let baggingLabourCost = this.addForm.get('baggingLabourCost').value;
    let storageCost2 = this.addForm.get('storageCost').value;
    this.addForm.get('handlingFeeAED').patchValue(handling ? (handling) : 0)
    this.addForm.get('unloadingCostAED').patchValue(unloading ? (unloading * this.addForm.get('fxRate').value).toFixed(3) : 0)
    this.addForm.get('costOfBagsAED').patchValue(bags ? (bags * this.addForm.get('fxRate').value).toFixed(3) : 0)
    this.addForm.get('cleaningCostAED').patchValue(cleaning ? (cleaning * this.addForm.get('fxRate').value).toFixed(3) : 0)
    this.addForm.get('customClearanceAED').patchValue(customClearance ? (customClearance * this.addForm.get('fxRate').value).toFixed(3) : 0)
    this.addForm.get('truckingChargesAED').patchValue(truckingCharges ? (truckingCharges * this.addForm.get('fxRate').value).toFixed(3) : 0)
    this.addForm.get('baggingLabourCostAED').patchValue(baggingLabourCost ? (baggingLabourCost * this.addForm.get('fxRate').value).toFixed(3) : 0)
    this.addForm.get('storageCostAED').patchValue(storageCost2 ? (storageCost2 * this.addForm.get('fxRate').value).toFixed(3) : 0)
    this.costTotal();
  }

  changeReminder(data: any) {
    if (data.checked) {
      this.addForm.get('reminderDate').validator = Validators.required;
    } else {
      this.addForm.get('reminderDate').setErrors(null);
    }
  }

  setPrevTime(date: Date) {
    const tempDate = new Date(date);
    // console.log(tempDate);
    const time: string = (tempDate.getHours() > 9 ? tempDate.getHours() : '0' + tempDate.getHours()) + ':' + (tempDate.getMinutes() > 9 ? tempDate.getMinutes() : '0' + tempDate.getMinutes());
    return time;
  }

  // ------------------ //
  // On view init cycle //
  // ------------------ //
  ngOnInit() {
    this.getPacks();
    this.setProductControl();
    this.setSupplierControl();
    this.setContractControl();
    //this.setOriginControl();
    this.addCostInputListeners();
    //this.setPlantControl();
    //this.setTransloaderControl();
    //this.getPlants();
    this.userRole = this.authService.currentUserValue.user.role;
    this.setPermitions();

    
    let contract = this.dataIn.prevContract;
    console.log(contract)
    
    if(!this.dataIn.isNew || this.dataIn.isClone){
      this.addForm.get('containerNo').disable();
      this.addForm.get('qtyUnload').enable();
      //console.log(contract)
      let basis = contract.basis
      let basisTerms = ''
      let basisLocation = ''
      if(basis && basis.length > 0){
        if(basis.substring(0,3) === 'CIP' || basis.substring(0,3) === 'CFR' || basis.substring(0,3) === 'CIF' || basis.substring(0,3) === 'FOB'){
          basisTerms = basis.substring(0,3)
          basisLocation = basis.substring(3)
        } else {
          basisTerms = ''
          basisLocation = basis
        }
      }
      if(contract.bagSizes){
        this.addForm.patchValue({
          bagSizes: this.packs.find(bags => bags.name == contract.bagSizes)
        });
      }
      this.addForm.patchValue({
        ...contract,
        origin: contract.origin ? contract.origin : '',
        basisTerms : basisTerms,
        basisLocation : contract.seaPort ? contract.seaPort : '' 
      },{emitEvent: false})
    } else {
      this.getLatestFx();
    }
    // if(this.dataIn.isClone){
    //   this.addForm.patchValue({
    //     containerNo: '',
    //     qty: ''
    //   })
    // }

    this.isPurchases = this.dataIn.isPurchases;

    // const reminderDate: Date = this.addForm.get('reminderDate').value ? new Date(this.addForm.get('reminderDate').value) : new Date();
  }

  getPacks() {
    var apiData = {
      where: {
        branchId: this.branchService.currentBranch.getValue().id
      },
    };
    this.apiService.getDataObjects('Packings?filter=' + JSON.stringify(apiData)).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
      this.packs = data;
    });
  }

  isValidId(id) {
    return id && id !== '' && id !== '-';
  }

  setPermitions() {
    if ( this.userRole === 'branchTrader') {
      this.isPulses = true;
    } else if (this.userRole === 'winnipeg') {
      this.isWinnipeg = true;
    } else if (this.userRole === 'generalAdmin' || this.userRole === 'branchAdmin') {
      this.isWinnipeg = true;
      this.isPulses = true;
    }
  }

  setProductControl() {
    this.productOptions = this.addForm.get('product').valueChanges.pipe(
        debounceTime(250),
        startWith(''),
        switchMap(value => this.loadProductOptions(value))
    );
  }

  setProductFilterListener(){
    this.addForm.get('product').valueChanges.pipe(startWith(this.addForm.get('product').value),pairwise()).subscribe(([oldValue, newValue]) => {
      console.log(oldValue)
      console.log(newValue)
      if(newValue.id){
        this.filterByProduct()
      } else if(oldValue.id && !newValue.id){
        this.filterByProduct()
      }
    });
  }

  setSupplierFilterListener(){
    this.addForm.get('supplierName').valueChanges.pipe(startWith(this.addForm.get('supplierName').value),pairwise()).subscribe(([oldValue, newValue]) => {
      console.log(oldValue)
      console.log(newValue)
      if(newValue.id){
        this.filterByProduct()
      } else if(oldValue.id && !newValue.id){
        this.filterByProduct()
      }
    });
  }

  filterByProduct(){
    if(!this.addForm.get('contract').value.id){
      this.contractOptions = this.addForm.get('contract').valueChanges.pipe(
        debounceTime(250),
        startWith(''),
        switchMap(value => this.loadContractOptions(value))
    );
    }
  }

  checkEveryPort(){
    console.log(this.importDetails)
  }

  setSupplierControl() {
    this.supplierOptions = this.addForm.get('supplierName').valueChanges.pipe(
        debounceTime(250),
        startWith(''),
        switchMap(value => this.loadSupplierOptions(value))
    );
  }

  setContractControl() {
    this.contractOptions = this.addForm.get('contract').valueChanges.pipe(
        debounceTime(250),
        startWith(''),
        switchMap(value => this.loadContractOptions(value))
    );
  }

  setOriginControl() {
    this.originOptions = this.addForm.get('origin').valueChanges.pipe(
        debounceTime(250),
        startWith(''),
        switchMap(value => this.loadOriginOptions(value))
    );
  }

  
  // setPlantControl() {
  //   this.plantOptions = this.addForm.get('plant').valueChanges.pipe(
  //       debounceTime(250),
  //       startWith(''),
  //       switchMap(value => this.loadPlantOptions(value))
  //   );
  // }
  // setTransloaderControl() {
  //   this.transloaderOptions = this.addForm.get('transloader').valueChanges.pipe(
  //       debounceTime(250),
  //       startWith(''),
  //       switchMap(value => this.loadTransloaderOptions(value))
  //   );
  // }


  // --------------------- //
  // After view init cycle //
  // --------------------- //
  ngAfterViewInit() {
    // this.importDetails = this.dataIn.prevContract && this.dataIn.prevContract.importDetails 
    //                       ? this.dataIn.prevContract.importDetails 
    //                       : [];
    this.setProductFilterListener()
    this.setSupplierFilterListener()
    if(!this.dataIn.isNew){ 
        this.addForm.get('containerNo').setValidators([Validators.required])
    }
    // if(this.dataIn.isNew){
    //   this.addForm.get('actualQty').setValidators([Validators.required])
    // }
    const costs = [
    'customClearance',
    'truckingCharges',
    'baggingLabourCost',
    'storageCost',
    'handlingFee',
    'unloadingCost',
    'cleaningCost',
    'costOfBags',]
    costs.forEach(element => {
      this.setToEmpty(element)
    });

    /* on user search change (managing 250 ms) */
  }

  // ---------------------------------------------------//
  // Method to display name and load id on autocomplete //
  // ---------------------------------------------------//
  displayFn(user?: any): string | undefined {
    return user ? user.name : undefined;
  }

  displayFid(contract?: any): string | undefined {
    return contract ? contract.contractId : undefined;
  }



  // -------------------------------------------------------------//
  // Method to load available users to reminder with the autocomplete //
  // -------------------------------------------------------------//

  loadProductOptions(dataSearch) {
    //this.addForm.get('contract').setValue(this.addForm.get('contract').value,{emitEvent: false})
    const searchQuery = this.queryFactory.setSearchQuery(dataSearch, ['name']);
    const andObject: any[] = [
      searchQuery,
      {branchId: this.dataIn.branch.id}
    ];
    const getClientsQuery = this.queryFactory.generateGetQuery('DubaiProducts', {and: andObject}, 25, 0, 'name ASC', []);
    return new Observable<any>((objs) => this.apiService.getDataObjects(getClientsQuery).subscribe((data: any) => {
      objs.next(data);
    }));
  }

  loadSupplierOptions(dataSearch: string) {
    const searchQuery = this.queryFactory.setSearchQuery(dataSearch, ['name']);
    const andObject: any[] = [
      searchQuery,
      {branchId: this.dataIn.branch.id},
      {isPurchases: true}
    ];
    const getClientsQuery = this.queryFactory.generateGetQuery('DubaiCustomers', {and: andObject}, 25, 0, 'name ASC', []);
    return new Observable<any>((objs) => this.apiService.getDataObjects(getClientsQuery).subscribe((data: any) => {
      objs.next(data);
    }));
  }

  loadContractOptions(dataSearch: string) {
    console.log('we got here')
    console.log(this.addForm.get('product').value.name)
    this.chargeContractsData()
    const searchQuery = this.queryFactory.setSearchQuery(dataSearch, ['contractId']);
    const andObject: any[] = [
      searchQuery,
      {branchId: this.dataIn.branch.id},
      {isPurchases: true},
      {availableQuantity: {gt: 0}}
    ];
    if(this.addForm.get('product').value.name){
      andObject.push({productName: this.addForm.get('product').value.name})
    }
    console.log(this.addForm.get('supplierName').value)
    if(this.addForm.get('supplierName').value.id){
      andObject.push({customerId: this.addForm.get('supplierName').value.id})
    }
    const getClientsQuery = this.queryFactory.generateGetQuery('DubaiContracts', {and: andObject}, 25, 0, 'name ASC', ['product', 'customer']);
    return new Observable<any>((objs) => this.apiService.getDataObjects(getClientsQuery).subscribe((data: any) => {
      objs.next(data);
    }));
  }

  chargeContractsData(){
    let contrato = this.addForm.get('contract').value
    let basis = contrato.basis
    let basisTerms = ''
    let basisLocation = ''
    if(basis && basis.length > 0){
      if(basis.substring(0,3) === 'CIP' || basis.substring(0,3) === 'CFR' || basis.substring(0,3) === 'CIF' || basis.substring(0,3) === 'FOB'){
        basisTerms = basis.substring(0,3)
        basisLocation = basis.substring(3)
      } else {
        basisTerms = ''
        basisLocation = basis
      }
    }
    console.log(contrato)
    if(contrato.id){
      this.addForm.patchValue({
        //supplier: contrato.customer,
        supplierName: contrato.customer,
        packing: contrato.packing,
        importer: contrato.importer,
        purchasePrice: contrato.price,
        product: contrato.product,
        currency: contrato.currency,
        origin: contrato.origin ? contrato.origin : null,
        basisTerms : basisTerms,
        basisLocation :contrato.seaPort,
        seaPort:contrato.seaPort
      })
      console.log(this.addForm)
      this.contractSelected = true;
      this.isThird = true;
      this.costTotal();
    } else {
      this.contractSelected = false;
      this.isThird = false;
    }
  }

  loadOriginOptions(dataSearch: string) {
    const searchQuery = this.queryFactory.setSearchQuery(dataSearch, ['name']);
    const andObject: any[] = [
      searchQuery,
      {branchId: this.dataIn.branch.id}
    ];
    const getClientsQuery = this.queryFactory.generateGetQuery('DubaiOrigins', {and: andObject}, 25, 0, 'name ASC', []);
    return new Observable<any>((objs) => this.apiService.getDataObjects(getClientsQuery).subscribe((data: any) => {
      console.log(data)
      objs.next(data);
    }));
  }

  // -------------------//
  // Perform deal create / edit request //
  // -------------------//
  performRequest() {
    console.log(this.addForm)
    if(this.AED.value == "AED"){
      let handling = this.addForm.get('handlingFeeAED').value;
      let unloading = this.addForm.get('unloadingCostAED').value;
      let bags = this.addForm.get('costOfBagsAED').value;
      let cleaning = this.addForm.get('cleaningCostAED').value;
      let customClearance = this.addForm.get('customClearanceAED').value;
      let truckingCharges = this.addForm.get('truckingChargesAED').value;
      let baggingLabourCost = this.addForm.get('baggingLabourCostAED').value;
      let storageCost2 = this.addForm.get('storageCostAED').value;
      this.addForm.get('handlingFee').patchValue(handling ? (handling).toFixed(3) : 0)
      this.addForm.get('unloadingCost').patchValue(unloading ? (unloading / this.addForm.get('fxRate').value).toFixed(3) : 0)
      this.addForm.get('costOfBags').patchValue(bags ? (bags / this.addForm.get('fxRate').value).toFixed(3) : 0)
      this.addForm.get('cleaningCost').patchValue(cleaning ? (cleaning / this.addForm.get('fxRate').value).toFixed(3) : 0)
      this.addForm.get('customClearance').patchValue(customClearance ? (customClearance / this.addForm.get('fxRate').value).toFixed(3) : 0)
      this.addForm.get('truckingCharges').patchValue(truckingCharges ? (truckingCharges / this.addForm.get('fxRate').value).toFixed(3) : 0)
      this.addForm.get('baggingLabourCost').patchValue(baggingLabourCost ? (baggingLabourCost / this.addForm.get('fxRate').value).toFixed(3) : 0)
      this.addForm.get('storageCost').patchValue(storageCost2 ? (storageCost2 / this.addForm.get('fxRate').value).toFixed(3) : 0)
      this.costTotal();
    }
    if(this.addForm.status === 'INVALID' || !this.addForm.get('contract').value.contractId){
      this.presentToast('Error in form', 'yellow-snackbar');
    } else {
      if(this.dataIn.isNew){
        if(this.importDetails.length <=0 ){
          this.presentToast('Add containers', 'yellow-snackbar');
          return false;
        }
        // if(this.addForm.get('qty').value !== this.totalWeight || this.addForm.get('actualQty').value !== this.totalActualWeight ){
        // this.presentToast('Containers Weight dont match', 'yellow-snackbar');
        // return false;
        // }  
        if(this.genLots.value){
          if(!this.wareHouseLocation.value){
          this.presentToast('Enter Warehouse Location', 'yellow-snackbar');
            return false
          }
        }
      }
      this.disableButton = true
      const costs = [
        'handlingFee',
        'storageCost',
        'shortage',
        'advancedProcessingCost',
        'cleaningCost',
        'baggingLabourCost',
        'costOfBags',
        'unloadingCost',
        'truckingCharges',
        'customClearance']
    let formRailcar = {
      ...this.addForm.value,
      importer: this.addForm.get('importer').value,
      packing: this.addForm.get('packing').value,
      basisTerms: this.addForm.get('basisTerms').value,
      basisLocation: this.addForm.get('basisLocation').value,
      currency: this.addForm.get('currency').value,
      purchasePrice: this.addForm.get('purchasePrice').value,
      productName : this.addForm.get('product').value ? this.addForm.get('product').value.name : null,
      supplierId : this.addForm.get('supplierName').value ? this.addForm.get('supplierName').value.id : null,
      origin : this.addForm.get('origin').value ? this.addForm.get('origin').value : null,
      contractId : this.addForm.get('contract').value ? this.addForm.get('contract').value.contractId : null,
      basis : this.addForm.get('basisTerms').value + ' ' + this.addForm.get('basisLocation').value,
      qty: this.addForm.get('qty').value ? this.addForm.get('qty').value : 0,
      qtyUnload: this.addForm.get('qtyUnload').value ? this.addForm.get('qtyUnload').value : 0,
      qtyLoad: this.addForm.get('qtyLoad').value ? this.addForm.get('qtyLoad').value : 0,
      shortage: this.addForm.get('shortage').value ? this.addForm.get('shortage').value : 0,
      clearanceDate: this.addForm.get('clearanceDate').value ? this.addForm.get('clearanceDate').value : 0,
      // advancedProcessingCost: this.addForm.get('advancedProcessingCost').value ? this.addForm.get('advancedProcessingCost').value : 0,
      customClearance: this.addForm.get('customClearance').value ? this.addForm.get('customClearance').value : 0,
      truckingCharges: this.addForm.get('truckingCharges').value ? this.addForm.get('truckingCharges').value : 0,
      baggingLabourCost: this.addForm.get('baggingLabourCost').value ? this.addForm.get('baggingLabourCost').value : 0,
      storageCost: this.addForm.get('storageCost').value ? this.addForm.get('storageCost').value : 0,
      handlingFee: this.addForm.get('handlingFee').value ? this.addForm.get('handlingFee').value : 0,
      unloadingCost: this.addForm.get('unloadingCost').value ? this.addForm.get('unloadingCost').value : 0,
      cleaningCost: this.addForm.get('cleaningCost').value ? this.addForm.get('cleaningCost').value : 0,
      costOfBags: this.addForm.get('costOfBags').value ? this.addForm.get('costOfBags').value : 0,
    };
    delete formRailcar.handlingFeeAED
    delete formRailcar.unloadingCostAED
    delete formRailcar.cleaningCostAED
    delete formRailcar.costOfBagsAED
    delete formRailcar.totalAfterWastageAED
    delete formRailcar.costTotalUsdAED
    delete formRailcar.totalPurchaseCostUsdAED
    //delete formRailcar.supplier
    delete formRailcar.supplierName
    delete formRailcar.contract
    delete formRailcar.basisTerms
    delete formRailcar.basisLocation
    delete formRailcar.wareHouseLocation
    delete formRailcar.actualQty

    const getDataQuery = 'Counters?filter=' + JSON.stringify({
      where: {collection: 'DubaiOutward'}
    });

    if(this.dataIn.isNew){
      formRailcar = {
        ...formRailcar,
        createdAt : new Date(),
        branchId: this.dataIn.branch.id,
        createdById: this.authService.currentUserValue.user.id
      }
        let uploaded = 0
        let lotNo=""
        if(this.genLots.value){
          this.apiService.getDataObjects(getDataQuery).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
            console.log(data)
            let currentLot = data[0].value + 1
            console.log(data)
            console.log(currentLot)
            lotNo = this.lotNumberGenerator(currentLot)
            formRailcar = {...formRailcar, lotNo,wareHouseLocation:this.wareHouseLocation.value}
            this.uploadInwards(formRailcar)
        })
        }
        else{
          this.uploadInwards(formRailcar)
        }
  

    }else if(this.dataIn.isClone){      
      formRailcar = {
      ...formRailcar,
      createdAt : new Date(),
      branchId: this.dataIn.branch.id,
      createdById: this.authService.currentUserValue.user.id,
      qty: this.addForm.get('qty').value ? this.addForm.get('qty').value : 0,
      containerNo: this.addForm.get('containerNo').value
      }
      this.uploadSingleInward(formRailcar)
    } else {
      this.editInward(formRailcar)
    }

  }
  }

  lotNumberGenerator(nb) {
    if(nb > 0 && nb < 10)
         return "GD000"+nb;
    else if(nb >= 10 && nb < 100)
         return "GD00"+nb;
    else if(nb >= 100 && nb < 1000)
         return "GD0"+nb;
    else
         return "GD"+nb
  }

  uploadInwards(railcarData){
    this.loadingService.showLoader.next(true);
    let uploaded = 0;
    let railcarArray = [];

    let uploadEveryInward = new Promise<void>((resolve, reject) => {
      this.importDetails.forEach(element => {
        let inward = railcarData
        inward.containerNo = element.containerNo
        inward.shortage = element.shortage
        inward.wastage = element.wastage
        inward.qtyLoad = element.actualWeightMt
        inward.qtyUnload = element.unloadWeightMt
        inward.qty = inward.qtyUnload - (inward.wastage || 0)
        inward.isThird = this.isThird;
        this.apiService.addDataObject(inward, 'DubaiInwards').pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
          uploaded++
          this.totalPurchaseCosto += data.totalPurchaseCostUsd;
          this.totalCosto += 1;
          if(this.importDetails.length === uploaded){
            resolve()
            this.loadingService.showLoader.next(false);
          }
        }, (e) => {
          this.presentToast('Connection rejected', 'red-snackbar');
          this.disableButton = false;
          this.loadingService.showLoader.next(false);
      });
  
      })
    })
    uploadEveryInward.then(() => {
      
    if(this.genLots.value && this.wareHouseLocation.value){
      let totalContQty = 0

      this.importDetails.forEach(detail => {
        console.log(detail.actualWeightMt)
        totalContQty += Number(detail.actualWeightMt)
      })
      const outward = {       
        createdAt : new Date(),
        branchId: this.branchService.currentBranch.getValue().id,
        createdById: this.authService.currentUserValue.user.id,
        origin: railcarData.origin,
        productName: railcarData.productName,
        blNo: this.addForm.get('importBlNo').value,
        totalQty: totalContQty,
        availableQty: totalContQty,
        totalPurchaseCost: this.totalPurchaseCosto != 0 ? this.totalPurchaseCosto / this.totalCosto : 0,
        additionalStorageCost: 0,
        fxRate: railcarData.fxRate,
        bagSizes: railcarData.bagSizes,
        isOriginal: true,
        lotNo: railcarData.lotNo,
        inwardDate: this.addForm.get('receivingDate').value,
        isThird: this.isThird
      }
      this.apiService.addDataObject(outward, 'DubaiOutwards')
      .pipe(takeUntil(this.onDestroy))
      .subscribe((res) => {
        this.presentToast('Lot and inwards created succesfully', 'green-snackbar');
        this.hasChanges = true;
        this.onNoClick();
      }, err =>{
        this.presentToast('Error in lot creation', 'green-snackbar');
      })

    } else{
      this.presentToast('Inwards created succesfully', 'green-snackbar');
      this.hasChanges = true;
      this.onNoClick();
    }
    })
  }

  uploadSingleInward(inward){
    this.apiService.addDataObject(inward, 'DubaiInwards').pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.presentToast('Inward created succesfully', 'green-snackbar');
      //this.hasChanges = true;
      //this.loadingService.showLoader.next(false);
      return true 
  }, err => {
    this.presentToast('Connection rejected', 'red-snackbar');
    this.disableButton = false;
    //this.loadingService.showLoader.next(false);
    return false
  })
  }

  // createObject(object){
  //   this.apiService.addDataObject(object, 'DubaiInwards').pipe(takeUntil(this.onDestroy)).subscribe(() => {
  //       //this.hasChanges = true;
  //       //this.loadingService.showLoader.next(false);
  //       return true 
  //   }, err => {
  //     this.presentToast('Connection rejected', 'red-snackbar');
  //     //this.loadingService.showLoader.next(false);
  //     return false
  //   })
  // }

  // createOutward(object){
  //   this.apiService.addDataObject(object, 'DubaiOutwards')
  //   .pipe(takeUntil(this.onDestroy))
  //   .subscribe((res) => {
  //     this.presentToast('Lot created succesfully', 'green-snackbar');
  //   }, err =>{
  //     this.presentToast('Error in lot creation', 'green-snackbar');
  //   })
  // }

  editInward(inward){
    this.apiService.editDataObject(this.dataIn.prevContract.id, inward, 'DubaiInwards').pipe(takeUntil(this.onDestroy)).subscribe((data) => {
      this.presentToast('Inward edited succesfullly', 'green-snackbar');
      this.hasChanges = true;
      this.onNoClick();
  }, err => {
  });
  }

  deleteObject() {
    const dialogRef = this.dialog.open(ModalConfirmComponent, {
      data: {
        button: 'Delete',
        title: 'Note',
        subtitle: '¿Are you sure about deleting this note?',
        message: [
        ]
      },
      autoFocus: false
    });
    dialogRef.afterClosed().pipe(takeUntil(this.onDestroy)).subscribe(result => {
      if (result !== undefined) {
        if (result.confirmation) {
          this.apiService.deleteDataObject('Notes', this.dataIn.prevContract.id).pipe(takeUntil(this.onDestroy)).subscribe(() => {
            this.hasChanges = true;
            if (this.dataIn.prevContract.hasReminder) {
              this.apiService.editDataObject(this.prevReminder.id, {hasNote: false}, 'Reminders').pipe(takeUntil(this.onDestroy)).subscribe(( newReminder) => {
                // // console.log(newReminder);
                this.onUserHasNotReply();
              });
            } else {
              this.onUserHasNotReply();
            }
          });
        }
      }
    });
  }


  onUserHasNotReply() {
    if (this.dataIn.prevContract.hasNotReply) {
      const tagIndex = this.dataIn.subject ? this.dataIn.subject.tags.findIndex(tag => tag === 'Not reply') : -1;
      if (tagIndex !== -1) {
        this.dataIn.subject.tags.splice(tagIndex, 1);
        this.apiService.editDataObject(this.dataIn.subject.id, {
          tags: this.dataIn.subject.tags
        }, 'Clients').pipe(takeUntil(this.onDestroy)).subscribe(() => {
          this.presentToast('Note deleted succesfullly', 'green-snackbar');
          this.hasChanges = true;
          this.onNoClick();
        }, () => {
          this.presentToast('Note deleted, but tag was not removed', 'yellow-snackbar');
          this.hasChanges = true;
          this.onNoClick();
        });
      } else {
        this.presentToast('Note deleted succesfullly', 'green-snackbar');
        this.onNoClick();
      }
    } else {
      this.presentToast('Note deleted succesfullly', 'green-snackbar');
      this.hasChanges = true;
      this.onNoClick();
    }
  }

  openImportDetails(){
    const dialogRef = this.dialog.open(ImportDetailsModalComponent, {
      data: {
        detailsArray: this.importDetails,
      },
      autoFocus: false,
      width: '1500px',
      panelClass: 'dubaiModal',
      disableClose: true
    });
    dialogRef.afterClosed().pipe(takeUntil(this.onDestroy)).subscribe((result) => {
      if(result && result.returnArray){
        this.importDetails = result.returnArray
        this.getNetWeight();
      }
    });
  }
  // ---------------------//
  // Present toast method //
  // ---------------------//
  presentToast(message: string, style: string) {
    this.snackBar.open(message, 'Close', {
      duration: 3000,
      panelClass: [style],
      horizontalPosition: 'end',
      verticalPosition: document.documentElement.clientWidth >= 1050 ? 'top' : 'bottom'
    });
  }
  // -------------------//
  // Close modal method //
  // -------------------//
  onNoClick(): void {
    this.dialogRef.close({changes: this.hasChanges});
  }
  clearContract(){
    this.addForm.get('contract').setValue('');
    this.addForm.get('product').setValue('');
    this.addForm.get('supplierName').setValue('');
    this.addForm.get('importer').setValue('');
    this.addForm.get('origin').setValue('');
    this.addForm.get('packing').setValue('');
    this.addForm.get('basisTerms').setValue('');
    this.addForm.get('basisLocation').setValue('');
    this.addForm.get('currency').setValue('');
    this.addForm.get('purchasePrice').setValue('');
  }
  
  setHours(hours: number) {

  }

  linkedChange(event) {
    // console.log(event);
  }


  // -------------------- //
  // On destroy lifecycle //
  // -------------------- //
  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.unsubscribe();
  }
}