import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { PasswordComponent } from './forgot_password/forgot_password.component';
import { ResetComponent } from './reset_password/reset_password.component';
import {SessionGuard} from './services/guards/session.guard';
import { PlantPricesComponent } from './plant_prices/plant_prices.component';
import { AuthorizeBuyerViewComponent } from './structure/mexico/mexico-load-orders/authorize-buyer-view/authorize-buyer-view.component';


const appRoutes: Routes = [
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'forgot_password',
        component: PasswordComponent
    },
    {
        path: 'reset_password',
        component: ResetComponent
    },
    {
        path: 'authorize_transaction',
        canActivate: [ SessionGuard ],
        component: AuthorizeBuyerViewComponent,
        children: [
            {
                path: '',
                canActivate: [ SessionGuard ],
                component: AuthorizeBuyerViewComponent
            },
            {
                path: ':id',
                canActivate: [ SessionGuard ],
                component: AuthorizeBuyerViewComponent
            }
        ]
    },
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: '',
        canActivate: [ SessionGuard ],
        loadChildren: () => import('./structure/structure.module').then(m => m.StructureModule)
    },
    {
        path: 'plant_prices',
        canActivate: [ SessionGuard ],
        component: PlantPricesComponent
    },
    {
        path: '**',
        redirectTo: 'dashboard-purchases',
    }
];

export const APP_ROUTES = RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' });
