import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {

  constructor() { }

  public getVancuverTime(date){
    const tempDate = new Date(date)
    const convertDate = this.convertTZ(tempDate, "America/Vancouver");

    return convertDate;
  }

  public formatDate(date){
    const tempDate = new Date(date);
    return moment(tempDate).tz("America/Vancouver").format()
  }

  public convertVanDate(selectedDate){
    
    if(selectedDate.toDate){
      selectedDate = selectedDate.toDate()
    }else{
      selectedDate = selectedDate
    }

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    var d = new Date(selectedDate);


    var a = d.toLocaleString('en-US', {
      timeZone: 'America/Vancouver',
      timeZoneName: 'short',
    });
    var b = d.toLocaleString('en-US', {
      timeZone: timezone,
      timeZoneName: 'short',
    });

    let spreadVan;
    if (a.search('PST') != -1) {
      spreadVan = 8;
    } else if (a.search('PDT') != -1) {
      spreadVan = 7;
    }
    let otherSpread;
    if (b.search('PST') != -1) {
      otherSpread = 0;
      spreadVan = 0;
    } else if (b.search('PDT') != -1) {
      otherSpread = 0;
      spreadVan = 0;
    } else {
      otherSpread = (d.getTimezoneOffset() / 60) * -1;
    }
    let totalSpread = spreadVan + otherSpread;
    d.setHours(d.getHours() + totalSpread);
    return d;

  }

  public getFormatStartDay(date) {
    return new Date(moment(date).tz("America/Vancouver").startOf('day').format());
  }

  public getFormatEndDay(date) {
    return new Date(moment(date).tz("America/Vancouver").add(1, 'days').startOf('day').format());
  }


  public getStartOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  public getEndOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }

  public convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
  }

  public getCleanDay( date ){
    const tempData = new Date(date);
    const convertDate = tempData.setUTCHours(12,0,0,0);
    return new Date(convertDate);
  }

  setUTCHoursWithoutChangingDay(dateString) {
    // Crear una nueva fecha a partir de la cadena proporcionada
    const originalDate = new Date(dateString);
  
    // Establecer la hora deseada (12:00 UTC) sin modificar el día
    const utcDate = new Date(
      Date.UTC(
        originalDate.getUTCFullYear(),
        originalDate.getUTCMonth(),
        originalDate.getUTCDate(),
        12,
        0,
        0,
        0
      )
    );
  
    // Si la hora original es antes de las 12:00 UTC, retrocedemos un día para mantener el mismo día
    if (originalDate.getUTCHours() < 12) {
      utcDate.setUTCDate(utcDate.getUTCDate() - 1);
    }
  
    // Convertir la fecha a una cadena en formato ISO 8601 (UTC)
    const customDateString = utcDate.toISOString();
  
    return customDateString;
  }
  

  public getFormattedDate(date) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    const tempDate = new Date(date);
    const month = (tempDate.getMonth());
    const day = tempDate.getDate();
    const year = tempDate.getFullYear();
    return  day + '-' + months[month] + '-' + year;
  }


}
