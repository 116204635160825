import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "../material/material.module";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { PlantPricesComponent } from "./plant_prices.component";
import { MatButtonModule } from "@angular/material/button";
import { SwiperModule } from 'ngx-swiper-wrapper';
import { MatIconModule } from "@angular/material/icon";
import { MatTableModule } from "@angular/material/table";
@NgModule({
  declarations: [PlantPricesComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    SwiperModule,
    MatIconModule,
    MatTableModule,
  ],
  exports:[PlantPricesComponent]
})
export class PlantPricesModule {}
