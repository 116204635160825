import {MatPaginatorIntl} from "@angular/material/paginator";

export function getEnglishPaginatorIntl() {

    const rRangeLabel = (page: number, pageSize: number, length: number) => {
        return '';
    };

    const paginatorIntl = new MatPaginatorIntl();

    paginatorIntl.itemsPerPageLabel = 'Items per page:';
    paginatorIntl.nextPageLabel = 'Next page';
    paginatorIntl.previousPageLabel = 'Previous page';
    // paginatorIntl.getRangeLabel = rRangeLabel;
    paginatorIntl.itemsPerPageLabel = '';

    return paginatorIntl;
}

