export const ROLES = {
    LOGISTICS:'logistics',
    WINNIPEG:'winnipeg',
    ACCOUNTING:'accounting',
    GENERAL_ADMIN:'generalAdmin',
    BRANCH_ADMIN:'branchAdmin',
    BRANCH_TRADER:'branchTrader',
    DUBAI:'dubai',
    PLANT_PRICES: 'plant_prices',
    REPORTS:'reports',
    SALES: 'sales',
    PURCHASES_LOGISTICS: 'purchases_logistics',
    CERTIFICATION: 'certification',
    EXPENSES: 'expenses'
} 