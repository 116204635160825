<div class="row">
    <div class="col-11">
        <h2 class="boldDLabel blueText">IMPORT DETAILS</h2>
    </div>
    <div class="col d-flex justify-content-end">
        <button type="button" mat-icon-button matRipple (click)="onNoClick()"><mat-icon>close</mat-icon></button>
    </div>
</div>

<div class="col-12 d-flex align-content-center align-items-center justify-content-center flex-column spinBlue" *ngIf="startUpload">
    <p style="color:#202d42" class="h1-orange-bold mt-2">Uploading File...</p>
    <mat-spinner></mat-spinner>
</div>

<div class="whiteCard myContainer">
    <div class="row col-12">
        <div class="col col4">
            <h2>#</h2>
        </div>
        <div class="col col14 center">
            <h2 class="header-container centron">Clearance Date</h2>
        </div>
        <div class="col col24 center">
            <h2 class="header-container centron">Container #</h2>
        </div>
        <div class="col col10 center">
            <h2 class="header-container centron">Load Weight MT</h2>
        </div>
        <div class="col col10 center">
            <h2 class="header-container centron">Shortage</h2>
        </div>
        <div class="col col10 center">
            <h2 class="header-container centron">Unload Weight MT</h2>
        </div>
        <div class="col col24 center">
            <h2 class="header-container centron">Upload File</h2>
        </div>
        <div class="col col4">
        </div>
    </div>
    <hr>
    <form [formGroup]="addForm" (ngSubmit)="addContainer()">
        <div class="row col-12">
                <div class="col col4">

                </div>
                <div class="col col14 formItem">
                    <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small" (click)="picker3.open()">
                        <mat-label>Clearance Date</mat-label>
                        <input matInput [matDatepicker]="picker3" formControlName="clearanceDate" placeholder="Choose a date" autocomplete="off">
                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                        <mat-datepicker #picker3 startView="month" ></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="col col24 formItem">
                    <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
                        <mat-label>Container #</mat-label>
                        <input type="text" matInput placeholder="#" id="containerNo" #containerNo formControlName="containerNo" autofocus>
                    </mat-form-field>
                </div>

                <div class="col col10 formItem">
                    <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
                        <mat-label>Load Weight MT</mat-label>
                        <input type="number" matInput placeholder="Actual Weight MT" id="actualWeightMt" #actualWeightMt formControlName="actualWeightMt">
                    </mat-form-field>
                </div>

                <div class="col col10 formItem">
                    <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
                        <mat-label>Shortage</mat-label>
                        <input type="number" matInput placeholder="Shortage" id="shortage" #shortage formControlName="shortage">
                    </mat-form-field>
                </div>

                <div class="col col10 formItem">
                    <mat-form-field  appearance="outline" class="example-full-width my-custom-component-small">
                        <mat-label>Unload Weight MT</mat-label>
                        <input type="number" matInput placeholder="Unload Weight MT" id="unloadWeightMt" #unloadWeightMt formControlName="unloadWeightMt">
                    </mat-form-field>
                </div>

                <div class="row col col24 formItem" style="padding-top:4px">
                    <div class="col-10">
                        <input type="file" id="upload" (change)="preAddImage()" hidden/>
                        <label for="upload" class="uploadButton d-flex align-items-center">{{ viewFileName }}
                            <span *ngIf="!viewFileName.includes('Add ')" class="material-icons-outlined ml-1 check-icon">check</span>
                        </label>
                    </div>
                    <div class="col-2 d-flex align-items-center">
                        <span *ngIf="viewFileName.includes('Add ') " class="material-icons-outlined advice-icon">info</span>
                        <span *ngIf="!viewFileName.includes('Add ') " class="material-icons-outlined advice-icon deleteImg" (click)="removeMedia()">delete</span>
                    </div>
                </div>

                <div class="col col4">
                    <button class="fullWidth myCustomButton emptyButton" matRipple type="submit">
                        <i class="icon icon-adroit-add-01 iconAlign"></i>
                    </button>
                </div>
        </div>
    </form>
    <div class="fullWidth" *ngFor="let element of myDetailsArray; let i = index">
        <hr>
        <div class="row col-12">
            <div class="col col4 centron">
                <h2>{{myDetailsArray.length - i}}</h2>
            </div>
            <div class="col col14 centron">
                <h2>{{formatDate(element.clearanceDate)}}</h2>
            </div>
            <div class="col col24 centron">
                <h2>{{element.containerNo.length >= 24 ? element.containerNo.substr(0, 20) + "..." : element.containerNo}}</h2>
            </div>
            <div class="col col10 centron">
                <h2>{{element.actualWeightMt ? element.actualWeightMt.toFixed(3) : ''}}</h2>
            </div>
            <div class="col col10 centron">
                <h2>{{element.shortage ? element.shortage.toFixed(3) : ''}}</h2>
            </div>
            <div class="col col10 centron">
                <h2>{{element.unloadWeightMt ? element.unloadWeightMt.toFixed(3) : ''}}</h2>
            </div>
            <div class="col col24 centron">
                <h2>{{element.fileName ? element.fileName.substring(0,17) : ''}}</h2>
            </div>
            <div class="col col4 centron">
                <button class="fullWidth myCustomButton noPadding emptyButton" matRipple  (click)="removeContainer(i)">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>

<div class="row fullWidth">
    <div class="col-6 col-md-3 ml-auto mt-2">
        <button class="fullWidth myCustomButton blueBg" matRipple (click)="returnArray()">
            <span class="buttonText">SAVE</span>
        </button>
    </div>
</div>