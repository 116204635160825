import {AfterViewInit, Component, Inject, OnDestroy, OnInit, NgZone, ViewChild} from '@angular/core';
import {Subject} from "rxjs";
import {UntypedFormBuilder, UntypedFormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../services/auth/auth.service";
import {ApiService} from "../services/api/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from '@angular/material/snack-bar';
import {LoadingService} from "../services/loading/loading.service";
import {takeUntil} from "rxjs/operators";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MessageModalComponent} from "../modals/desicion-modals/message-modal/message-modal.component";
import {Title} from "@angular/platform-browser";
declare var particlesJS: any;

@Component({
  selector: 'app_forgot_password',
  templateUrl: './forgot_password.component.html',
  styleUrls: ['./forgot_password.component.scss']
})
export class PasswordComponent implements OnInit, OnDestroy {
  // --------------------------- //
  // Local variables declaration //
  // --------------------------- //
  private onDestroy = new Subject<void>();
  private firstLoad = true;
  // --------------------- //
  // Component constructor //
  // --------------------- //
  constructor(
      private authService: AuthService,
      private apiService: ApiService,
      private fb: UntypedFormBuilder,
      private router: Router,
      private zone: NgZone,
      private activateRoute: ActivatedRoute,
      private snackBar: MatSnackBar,
      private loadingService: LoadingService,
      private dialog: MatDialog,
      private titleService: Title
  ) {
    this.setTitle('Forgot Password - Adroit CRM');
  }
  public setTitle(newTitle: string) {
    this.titleService.setTitle( newTitle );
  }
  // --------------------- //
  // FormGroup declaration //
  // --------------------- //
  public forgotPasswordForm = this.fb.group({
    email: new UntypedFormControl({value: '', disabled: false}, Validators.required),
  });

  ngOnInit(): void {
    particlesJS.load('particles-js', 'assets/particles/particles.json', function() { console.log('callback - particles.js config loaded'); });
    if (this.authService.currentUserValue && this.firstLoad) {
      this.navigate(this.authService.currentUserValue);
    } else {
      this.authService.userObservable.pipe(takeUntil(this.onDestroy)).subscribe((user) => {
        if (user && this.firstLoad) {
          this.navigate(user);
        }
      });
    }
  }

  // ---------------------- //
  // Perform login function //
  // ---------------------- //
  forgotPasswordSubmit() {
    if (this.forgotPasswordForm.status === 'INVALID') {
      this.presentToast('Email is required');
    } else {
      let emailObject: any = {
        email: this.forgotPasswordForm.get('email').value
      };
      const userEmail: string = this.forgotPasswordForm.get('email').value;
      if (userEmail.includes('@')) {
        if (userEmail.includes('.')) {
          emailObject.email = userEmail;
        } else {
          return this.presentToast('The email is incorrect');
        }
      } else {
        emailObject.email = userEmail;
      }
      this.firstLoad = false;
      this.loadingService.showLoader.next(true);
      this.authService.useRemoteMethod('AppUsers', 'forgotPassword', emailObject)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((r: any) => {
        this.loadingService.showLoader.next(false);
        r = r.resp;
        // console.log(r);
        if (r.code == "EMAIL_NOT_FOUND") {
          this.presentAlert(r.message, '');
        }else{
          this.presentAlert('An email has been sent with instructions to recover your password.', '');
          this.router.navigate(['/login']).catch();
        }
      }, (err) => {
        this.loadingService.showLoader.next(false);
        this.presentAlert('Connection error', 'Error trying to connect to the server, verify your connection. ' + err);
      });
    }
  }


  // --------------------------- //
  // Show toast on invalid login //
  // --------------------------- //
  presentToast(message: string) {
    this.snackBar.open(message, 'Cerrar', {
      duration: 3000,
      panelClass: ['yellow-snackbar'],
      horizontalPosition: 'end',
      verticalPosition: document.documentElement.clientWidth >= 1050 ? 'top' : 'bottom'
    });
  }

  // --------------------------- //
  // Show alert on invalid login //
  // --------------------------- //
  presentAlert(title, msg) {
    this.dialog.open(MessageModalComponent, {
      width: '300px',
      data: {
        title: title,
        msg: msg
      }
    });
  }
  // -------------------- //
  // On destroy lifecycle //
  // -------------------- //
  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.unsubscribe();
  }


  navigate(user) {
    // console.log(user);

    let route: string = 'dashboard-purchases';
    if (user.user.role === 'winnipeg') {
      route = 'freight-movement'
    } else if (user.user.role === 'logistics') {
      route = 'grain-weeks'
    }

    this.router.navigate([route]).then(() => {
      this.loadingService.showLoader.next(false);
    }).catch();
  }

  login(){
    this.router.navigate(['/login']).catch();
  }
}
