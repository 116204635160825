import {AfterViewInit, Component, Inject, OnDestroy, OnInit, NgZone, ViewChild} from '@angular/core';
import {Subject} from "rxjs";
import {UntypedFormBuilder, UntypedFormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../services/auth/auth.service";
import {ApiService} from "../services/api/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from '@angular/material/snack-bar';
import {LoadingService} from "../services/loading/loading.service";
import {takeUntil} from "rxjs/operators";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MessageModalComponent} from "../modals/desicion-modals/message-modal/message-modal.component";
import {Title} from "@angular/platform-browser";
declare var particlesJS: any;
import { Buffer } from 'buffer'
import { LoginComponent } from "../login/login.component"

@Component({
  selector: 'app_reset_password',
  templateUrl: './reset_password.component.html',
  styleUrls: ['./reset_password.component.scss'],
  providers : [LoginComponent]
})
export class ResetComponent implements OnInit, OnDestroy {
  // --------------------------- //
  // Local variables declaration //
  // --------------------------- //
  private onDestroy = new Subject<void>();
  private firstLoad = true;
  // --------------------- //
  // Component constructor //
  // --------------------- //
  constructor(
      private authService: AuthService,
      private apiService: ApiService,
      private fb: UntypedFormBuilder,
      private router: Router,
      private zone: NgZone,
      private activateRoute: ActivatedRoute,
      private snackBar: MatSnackBar,
      private loadingService: LoadingService,
      private dialog: MatDialog,
      private titleService: Title,
      private loginService: LoginComponent
  ) {
    this.setTitle('Reset Password - Adroit CRM');
  }
  public setTitle(newTitle: string) {
    this.titleService.setTitle( newTitle );
  }
  // --------------------- //
  // FormGroup declaration //
  // --------------------- //
  public resetPasswordForm = this.fb.group({
    password: new UntypedFormControl({value: '', disabled: false}, Validators.required),
    passwordConfirm: new UntypedFormControl({value: '', disabled: false}, Validators.required),
  });

  ngOnInit(): void {
    particlesJS.load('particles-js', 'assets/particles/particles.json', function() { console.log('callback - particles.js config loaded'); });
  }

  // ---------------------- //
  // Perform login function //
  // ---------------------- //
  resetPasswordSubmit() {
    if (this.resetPasswordForm.status === 'INVALID') {
      this.presentToast('Password is required', 'yellow-snackbar');
    }else{
      if (this.resetPasswordForm.value.password.length < 6) {
        return this.presentToast('Enter at least 6 characters', 'yellow-snackbar');
      }
      if (this.resetPasswordForm.value.password !== this.resetPasswordForm.value.passwordConfirm) {
        return this.presentToast('The passwords entered do not match', 'yellow-snackbar');
      }else{
        this.loadingService.showLoader.next(true);
        let sendObject: any = {
          newPassword: this.resetPasswordForm.value.password
        };
        let email = window.location.href.split('&email=')
        let access_token = email[0].split('access_token=');
        email[1] = email[1].replace(/%3D/g, '=')
        email[1] = Buffer.from(email[1], 'base64').toString('utf-8');
        this.authService.useRemoteMethodWithToken('AppUsers', 'reset-password', sendObject, access_token[1])
        .pipe(takeUntil(this.onDestroy))
        .subscribe((r: any) => {
          this.presentToast('Password updated', 'green-snackbar');
          this.loginService.performLogin(email[1], this.resetPasswordForm.get('password').value, 'changed')
        }, (err) => {
          this.presentAlert('Rejected request', 'Please enter your email again to retrieve your password.');
          this.router.navigate(['/forgot_password']).catch();
          this.loadingService.showLoader.next(false);
        });
      }
    }
  }

  // --------------------------- //
  // Show alert on invalid login //
  // --------------------------- //
  presentAlert(title, msg) {
    this.dialog.open(MessageModalComponent, {
      width: '300px',
      data: {
        title: title,
        msg: msg
      }
    });
  }

  // --------------------------- //
  // Show toast on invalid login //
  // --------------------------- //
  presentToast(message: string, style: string) {
    this.snackBar.open(message, 'Cerrar', {
      duration: 3000,
      panelClass: style,
      horizontalPosition: 'center',
      verticalPosition: 'bottom'
    });
  }

  // -------------------- //
  // On destroy lifecycle //
  // -------------------- //
  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.unsubscribe();
  }
}
