<!----------------------->
<!-- title -->
<div class="header-deal-container">
    <h2 class="canada-color text-title" > {{ dataIn.title | uppercase}}</h2>
    
    <div class="d-flex">
        <h2 class="canada-color text-title-2" style="align-self: center;">{{dataIn.isNew ? getFormattedDate('M/D/YYYY') :
            getFormattedDate('M/D/YYYY', dataIn.prevDeal.createdAt)}}</h2>
        <button type="button" mat-icon-button class="justify-content-center"  matRipple (click)="onNoClick()">
            <mat-icon>close</mat-icon> 
        </button>
    </div>
</div>

<h3 class="text-subtitle-2 mt-1" *ngIf="!this.likedMail">Your account is not linked with gmail, please <span
    class="linkToProfile" (click)="goToProfile()">go to your a profile</span> and link it.</h3>
<h3 class="text-subtitle-2" *ngIf="!this.likedMail">Otherwise, the system will reject this deal.</h3>

<!-- form -->
<form [formGroup]="addForm" (ngSubmit)="performRequest()">
    <mat-horizontal-stepper class="canada-stepper" linear #stepper>
        <mat-step [stepControl]="addForm" [editable]="true">
            <ng-template matStepLabel>Data</ng-template>

            <!-- client information -->
            <div class="d-flex justify-content-between">
                <div class="">
                    <h2 class="canada-color text-title-2" *ngIf="isBuyers">CUSTOMER INFORMATION</h2>
                    <h2 class="canada-color text-title-2" *ngIf="isSuppliers">SUPPLIER INFORMATION</h2>
                </div>
                <div class="d-flex justify-content-end edit-style" (click)="isEditingClient = !isEditingClient">
                    <i class="icon-adroit-edit-011 mr-2 icons-size"></i>
                    <p class="">Edit</p>
                </div>
            </div>

            <div class="row section-divider">
                <!-- buyer -->
                <div class="col-sm-4">
                    <div *ngIf="isBuyers">
                        <mat-form-field appearance="outline" class="custom-form-field">
                            <mat-label > Buyer *</mat-label>
                            <input type="text" matInput placeholder="Available buyers" id="buyer"
                                #buyer formControlName="buyers" [matAutocomplete]="autoBuyers" class="buyer">
                            <button type="button" class="searchbarSuffix fsize22"
                                *ngIf="addForm.get('buyers').value && !dataIn.subject" matSuffix aria-label="Clear"
                                (click)="addForm.get('buyers').patchValue('')">
                                <mat-icon>close</mat-icon>
                            </button>
                            <mat-autocomplete #autoBuyers="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onBuyerSuplierSelect($event.option.value)">
                                <mat-option disabled>Select one buyer</mat-option>
                                <mat-option *ngFor="let client of (buyerOptions | async)" [value]="client">
                                    <div class="buyerContainer">
                                        <p>{{client.name}}</p>
                                    </div>
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
    
                    <!-- suplier -->
                    <div *ngIf="isSuppliers">
                        <mat-form-field appearance="outline" class="custom-form-field">
                            <mat-label> Supplier *</mat-label>
                                <input  type="text" matInput placeholder="Available suppliers" id="supplier" #supplier formControlName="suppliers" [matAutocomplete]="autoSupplier" class="">
                                <button type="button"  class="searchbarSuffix fsize22" *ngIf="addForm.get('suppliers').value && !dataIn.subject" matSuffix aria-label="Clear" (click)="addForm.get('suppliers').patchValue('')">
                                    <mat-icon>close</mat-icon>
                                </button>
                                <mat-autocomplete #autoSupplier="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onBuyerSuplierSelect($event.option.value)">
                                    <mat-option disabled>Select one supplier</mat-option>
                                    <mat-option *ngFor="let client of (supplierOptions | async)" [value]="client">
                                        <div class="buyerContainer">
                                            <p>{{client.name}}</p>
                                        </div>
                                    </mat-option>
                                </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </div>
                
                <div class="fadeIn col-sm-8 mt-1"  *ngIf="isEditingClient" [@fInT1]="'in'">
                    <ng-container [formGroup]="subjectForm" >
                        <div class="">
                            <div class="row">
                                <!-- subject company name -->
                                <div class="col-6">
                                    <mat-form-field appearance="outline" class="custom-form-field">
                                        <mat-label>Company Name *</mat-label>
                                        <input type="text" matInput placeholder="Company name" id="companyName" #companyName formControlName="companyName">
                                    </mat-form-field>
                                </div>  
                                <!-- subject address -->
                                <div class="col-6">
                                    <mat-form-field appearance="outline" class="custom-form-field">
                                        <mat-label>Address *</mat-label>
                                        <input type="text" matInput placeholder="Address" id="address" #address formControlName="address">
                                    </mat-form-field>
                                </div>                      
                                <!-- subject postalCode -->
                                <div class="col-6">
                                    <mat-form-field appearance="outline" class="custom-form-field">
                                        <mat-label>Postal Code *</mat-label>
                                        <input type="text" matInput placeholder="Postal Code" id="postalCode" #postalCode formControlName="postalCode">
                                    </mat-form-field>
                                </div>
                                <!-- contact -->
                                <div class="col-6">
                                    <mat-form-field appearance="outline" class="custom-form-field">
                                        <mat-label>Contact *</mat-label>
                                        <input type="text" matInput placeholder="Contact person" id="contact" #contact formControlName="contact">
                                    </mat-form-field>
                                </div>                      
                                <!-- subject work -->
                                <div class="col-6">
                                    <mat-form-field appearance="outline" class="custom-form-field">
                                        <mat-label>Work *</mat-label>
                                        <input type="text" matInput placeholder="Work" id="work" #work formControlName="work">
                                    </mat-form-field>
                                </div>    
                                <!-- phone -->
                                <div class="col-6">
                                    <mat-form-field appearance="outline" class="custom-form-field">
                                        <mat-label>Phone</mat-label>
                                        <input type="text" matInput placeholder="Phone" id="phone" #phone formControlName="phone" [mask]="subjectForm.get('phone') ? subjectForm.get('phone').value ? subjectForm.get('phone').value.length === 11 ? '0 (000) 000 0000' : '(000) 000 0000 0' : '0 (000) 000 0000' : '(000) 000 0000 0'" [validation]="false">
                                    </mat-form-field>
                                </div>                    
                                <!-- fax -->
                                <div class="col-6">
                                    <mat-form-field appearance="outline" class="custom-form-field">
                                        <mat-label>Fax</mat-label>
                                        <input type="text" matInput placeholder="Fax" id="fax" #fax formControlName="fax" [mask]="subjectForm.get('fax') ? subjectForm.get('fax').value ? subjectForm.get('fax').value.length === 11 ? '0 (000) 000 0000' : '(000) 000 0000 0' : '0 (000) 000 0000' : '(000) 000 0000 0'" [validation]="false">
                                    </mat-form-field>
                                </div>
                                <!-- subject email -->
                                <div class="col-6">
                                    <mat-form-field appearance="outline" class="custom-form-field">
                                        <mat-label>Email *</mat-label>
                                        <input type="text" matInput placeholder="Email" id="email" #email formControlName="email">
                                    </mat-form-field>
                                </div>
                            </div> 
                        </div>
                    </ng-container>

                </div>

                <div class="col-sm-8 mt-1" *ngIf="!isEditingClient">
                    <div class="row edit-labels">
                        <div class="col-6">
                            <div class="d-flex">
                                <p> Company Name:  <span> {{subjectForm.get('companyName').value || '-'}} </span> </p>
                            </div>
                            <div class="d-flex">
                                <p> Address:  <span> {{subjectForm.get('address').value || '-'}} </span> </p>
                            </div>
                            <div class="d-flex">
                                <p> Postal Code:  <span> {{subjectForm.get('postalCode').value || '-'}} </span> </p>
                            </div>
                            <div class="d-flex">
                                <p> Contact:  <span> {{subjectForm.get('contact').value || '-'}} </span> </p>
                            </div>
                        </div>

                        <div class="col-6">

                            <div class="d-flex">
                                <p> Work:  <span> {{subjectForm.get('work').value || '-'}} </span> </p>
                            </div>
                            <div class="d-flex">
                                <p> Phone:  <span> {{subjectForm.get('phone').value || '-'}} </span> </p>
                            </div>
                            <div class="d-flex">
                                <p> Fax:  <span> {{subjectForm.get('fax').value || '-'}} </span> </p>
                            </div>
                            <div class="d-flex">
                                <p> Email:  <span> {{subjectForm.get('email').value || '-'}} </span> </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <!-- general / product data -->
            <div class="d-flex justify-content-between mt-2">
                <div class="">
                    <h2 class="canada-color text-title-2">DEAL INFORMATION</h2>
                </div>
                <div class="d-flex" *ngIf="validUser && dataIn.isNew">
                    <span class="text-subtitle mr-1">REVISED CONTRACT</span> 
                    <div class="padding-checkbox">
                        <mat-checkbox (change)="validateQbForm()" class="custom-checkbox" [formControl]="reviewedContractForm"> </mat-checkbox>
                    </div>
                </div>
                <div class=" d-flex edit-style" (click)="isEditingDeal = !isEditingDeal">
                    <i class="icon-adroit-edit-011 mr-2 icons-size"></i>
                    <p class="">Edit</p>
                </div>
            </div>

            <div class="row section-divider">
                <div class="col-sm-8">
                    <div class="row">                   
                        <!-- grains -->
                        <!-- adroitOrderId -->
                        <div *ngIf="(dataIn.isNew && reviewedContract) || !dataIn.isNew" class="col-6">
                            <mat-form-field appearance="outline" class="custom-form-field">
                                <mat-label>QB Serial N°</mat-label>
                                <input type="text" matInput placeholder="QB Serial N°" id="adroitOrderId" #adroitOrderId formControlName="adroitOrderId" (ngModelChange)="validateQBSerial($event)">
                            </mat-form-field>
                        </div>   
                        
                        <div class="col-6" *ngIf="isGrains">
                            <mat-form-field appearance="outline" class="custom-form-field">
                                <mat-label>Commodity *</mat-label>
                                <input type="text" class="autoInput" matInput placeholder="Commodity" id="grainsWrapper"
                                    #grainsWrapper formControlName="grains" [matAutocomplete]="autoGrains"
                                    (focusout)="changeGrainGrade(addForm.get('grains').value)">
                                <button type="button" class="searchbarSuffix fsize22" *ngIf="addForm.get('grains').value"
                                    matSuffix aria-label="Clear" (click)="addForm.get('grains').setValue('')">
                                    <mat-icon>close</mat-icon>
                                </button>
                                <mat-autocomplete #autoGrains="matAutocomplete" [displayWith]="displayFn"
                                    (optionSelected)="onGrainSelect($event.option.value)">
                                    <mat-option disabled>Select one grain</mat-option>
                                    <mat-option *ngFor="let product of (grainOptions | async)" [value]="product">
                                        {{product.name}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <!-- number of loads -->
                        <div class="col-6">
                            <mat-form-field appearance="outline" class="custom-form-field">
                                <mat-label>Number Of Loads *</mat-label>
                                <input type="number" min="1" matInput placeholder="Number of Loads" id="noOfLoads" #quantity
                                    formControlName="noOfLoads">
                            </mat-form-field>
                        </div>
                        <!-- number of loads -->
                        <div class="col-6">
                            <mat-form-field appearance="outline" class="custom-form-field">
                                <mat-label>Quantity Per Load *</mat-label>
                                <input type="number" min="1" matInput placeholder="Quantity Per Load" id="qtyPerLoad" #quantityp
                                    formControlName="qtyPerLoad">
                            </mat-form-field>
                        </div>
                        <!-- quantity -->
                        <div class="col-6">
                            <mat-form-field [attr.disabled]="true"  appearance="outline" class="custom-form-field">
                                <mat-label>Contract Quantity *</mat-label>
                                <input ng-disabled="true" type="number" matInput placeholder="Quantity" id="quantity" #quantity
                                    formControlName="quantity" >
                            </mat-form-field>
                        </div>
                        <!-- price -->
                        <div class="col-6">
                            <mat-form-field appearance="outline" class="custom-form-field">
                                <mat-label>Price *</mat-label>
                                <input type="number" matInput placeholder="Price" id="price" #price formControlName="price">
                            </mat-form-field>
                        </div>
                         <!-- shipment terms -->
                        <div class="col-6">
                            <mat-form-field appearance="outline" class="custom-form-field">
                                <mat-label>Shipment Terms *</mat-label>
                                <mat-select placeholder="Shipment Terms" id="shipmentTerms" formControlName="shipmentTerms">
                                    <mat-option disabled="">Select terms</mat-option>
                                    <mat-option value="DLVD">DLVD </mat-option>
                                    <mat-option value="FOB">FOB</mat-option>
                                    <mat-option value="CFR" *ngIf="isBas">CFR </mat-option>
                                    <mat-option value="CIF" *ngIf="isBas">CIF </mat-option>
                                    <mat-option value="Ex Warehouse">Ex Warehouse</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <!-- Shipment Address -->
                        <div class="col-6">
                            <!-- *ngIf="((((addForm.get('shipmentTerms').value === 'FOB' || addForm.get('shipmentTerms').value === 'Ex Warehouse') && !dataIn.isTrading) || ((addForm.get('shipmentTerms').value === 'FOB' || addForm.get('shipmentTerms').value === 'Ex Warehouse') && dataIn.isTrading)) && isShipmentAddressReady) || dataIn.isWinnipeg"> -->
                            <mat-form-field appearance="outline" class="custom-form-field">
                                <mat-label *ngIf="!isBas" for="shipmentAddress">Shipment Address *</mat-label>
                                <mat-label *ngIf="isBas" for="shipmentAddress">Final Destination *</mat-label>
                                <input type="text" matInput placeholder="Address" id="shipmentAddress" #shipmentAddress
                                    formControlName="shipmentAddress" autocomplete="off">
                            </mat-form-field>
                        </div>    

                        <!-- Freight Rate -->
                        <div class="col-6">
                            <mat-form-field appearance="outline" class="custom-form-field">
                                <mat-label>Estimated Freight Rate *</mat-label>
                                <input type="number" matInput placeholder="Freight Rate" id="freightRate" #freightRate
                                    formControlName="freightRate">
                            </mat-form-field>
                        </div>

                        <!-- START DYNAMIC OPTIONS -->
                        <!-- contract # -->
                        <div class="col-6" *ngIf="isSuppliers">
                            <mat-form-field appearance="outline" class="custom-form-field">
                                <mat-label>Contract Ref N°</mat-label>
                                <input type="text" matInput placeholder="Contract Ref N°" id="contractReferenceNo" #contractReferenceNo formControlName="contractReferenceNo">
                            </mat-form-field>
                        </div>                           
                    </div>

                    <div class="row" *ngIf="isEditingDeal" [@fInT1]="'in'">

                        <!-- Shipping Unit -->
                        <div class="col-6">
                            <mat-form-field appearance="outline" class="custom-form-field">
                                <mat-label>Shipping Unit *</mat-label>
                                <mat-select class="input1rem" placeholder="Shipping Unit" id="shippingUnit" formControlName="shippingUnit">
                                    <mat-option  disabled="">Select a shipping unit</mat-option>
                                    <mat-option value="Rail - Hopper">Rail - Hopper</mat-option>
                                    <mat-option value="Truck">Truck</mat-option>
                                    <mat-option value="Container">Container</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <!-- packing -->
                        <div class="col-6">
                            <mat-form-field appearance="outline" class="custom-form-field">
                                <mat-label>Packing</mat-label>
                                <mat-select placeholder="Select Packing" id="paking" formControlName="packing">
                                    <mat-option *ngFor="let pack of packs" [value]="pack.name">
                                        {{pack.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <!-- documents -->
                        <div class="col-6">
                            <mat-form-field appearance="outline" class="custom-form-field">
                                <mat-label>Documents</mat-label>
                                <textarea matInput class="no-resize" placeholder="Documents" id="documents" #documents
                                    formControlName="documents" rows="3"></textarea>
                            </mat-form-field>
                        </div>
                        <!-- specifications -->
                        <div class="col-6">
                            <mat-form-field appearance="outline" class="custom-form-field">
                                <mat-label>Specifications</mat-label>
                                <textarea matInput class="no-resize" placeholder="Specifications" id="specifications" #specifications
                                    formControlName="specifications" rows="3"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="col-sm-4" *ngIf="isEditingDeal" [@fInT1]="'in'">
                    <div class="row">
                        <!-- grade -->
                        <div class="col-6 col-md-12">
                            <mat-form-field appearance="outline" class="custom-form-field">
                                <mat-label>Grade</mat-label>
                                <mat-select placeholder="Grade" id="grad" formControlName="grade"
                                    (selectionChange)="onSelectedGrade($event.value)">
                                    <mat-option disabled="">Select one grade</mat-option>
                                    <mat-option *ngFor="let grade of productGrades" [value]="grade" class="customOption">
                                        <div class="d-flex flex-column justify-content-center pt-2 pb-2">
                                            <p class="caption-black-bold">Grade: <span class="caption-black-regular">
                                                    {{grade.name ? grade.name : 'N/A'}} </span></p>
                                        </div>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <!-- origin -->
                        <div class="col-6 col-md-12" *ngIf="addForm.get('grains')">
                            <mat-form-field appearance="outline" class="custom-form-field">
                                <mat-label>Origin *</mat-label>
                                <mat-select placeholder="Origin" id="origin" #origin formControlName="origin">
                                    <mat-option disabled="">Select origin</mat-option>
                                    <!-- <mat-option *ngFor="let origin of origins" [value]="origin.name">{{origin.name}}
                                    </mat-option> -->
                                    <mat-option [value]="OriginGrain">{{OriginGrain}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>                    
                        <!-- units -->
                        <div class="col-6 col-md-12">
                            <mat-form-field appearance="outline" class="custom-form-field">
                                <mat-label>Unit *</mat-label>
                                <mat-select placeholder="Units" id="unit" formControlName="units">
                                    <mat-option disabled="">Select unit</mat-option>
                                    <mat-option *ngIf="isSuppliers" value="ST">ST</mat-option>
                                    <mat-option value="MT">MT</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <!-- priceUnit -->
                        <div class="col-6 col-md-12">
                            <mat-form-field appearance="outline" class="custom-form-field">
                                <mat-label>Price units *</mat-label>
                                <mat-select placeholder="Price units" id="priceUnit" formControlName="priceUnits">
                                    <mat-option disabled="">Select one price unit</mat-option>
                                    <mat-option *ngIf="isSuppliers" value="USD/ST">USD/ST</mat-option>
                                    <mat-option value="USD/MT">USD/MT</mat-option>
                                    <mat-option [value]="branchService.currentBranch.getValue().currency+'/MT'">{{branchService.currentBranch.getValue().currency}}/MT</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>                

                        <!-- terms -->
                        <div class="col-6 col-md-12">
                            <mat-form-field appearance="outline" class="custom-form-field">
                                <mat-label>Terms *</mat-label>
                                <input type="text" matInput placeholder="Terms" id="terms" #terms formControlName="terms">
                            </mat-form-field>
                        </div>
                        <!-- paymentTerms -->
                        <div class="col-6 col-md-12">
                            <mat-form-field appearance="outline" class="custom-form-field">
                                <mat-label>Payment Terms *</mat-label>
                                <input type="text" matInput placeholder="Payment Terms" id="paymentTerms" #paymentTerms
                                    formControlName="paymentTerms">
                            </mat-form-field>
                        </div>
                        <!-- Shipping Unit -->
                        <div class="col-6 col-md-12">
                            <mat-form-field appearance="outline" class="custom-form-field">
                                <mat-label>Shipping Unit *</mat-label>
                                <mat-select class="input1rem" placeholder="Shipping Unit" id="shippingUnit" formControlName="shippingUnit">
                                    <mat-option disabled="">Select a shipping unit</mat-option>
                                    <mat-option value="Rail - Hopper">Rail - Hopper</mat-option>
                                    <mat-option value="Truck">Truck</mat-option>
                                    <mat-option value="Container">Container</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="edit-labels mt-1 col-sm-4" *ngIf="!isEditingDeal">

                    <div class="d-flex">
                        <p [ngClass]="{'reject': !addForm.get('grade').value}">Grade: <span> {{ addForm.get('grade').value?.name || '-' }} </span></p>
                    </div>

                    <div class="d-flex">
                        <p [ngClass]="{'reject': !addForm.get('origin').value}">Origin:<span> {{ addForm.get('origin').value || '-' }} </span></p>
                    </div>

                    <div class="d-flex">
                        <p [ngClass]="{'reject': !addForm.get('units').value}">Units: <span> {{ addForm.get('units').value || '-' }} </span></p>
                    </div>

                    <div class="d-flex">
                        <p [ngClass]="{'reject': !addForm.get('priceUnits').value}">Price Units:<span> {{ addForm.get('priceUnits').value || '-' }} </span></p>
                    </div>
                    
                    <div class="d-flex">
                        <p [ngClass]="{'reject': !addForm.get('shippingUnit').value}">Shipping Unit: <span> {{ addForm.get('shippingUnit').value || '-' }} </span></p>
                    </div>

                    <div class="d-flex">
                        <p [ngClass]="{'reject': !addForm.get('packing').value}">Packing : <span> {{ addForm.get('packing').value || '-' }} </span></p>
                    </div>

                    <div class="d-flex">
                        <p>Terms: <span> {{ addForm.get('terms').value || '-' }} </span></p>
                    </div>

                    <div class="d-flex">
                        <p>Payment Terms:<span> {{ addForm.get('paymentTerms').value || '-' }} </span></p>
                    </div>

                    <div class="d-flex">
                        <p>Documents: <span> {{ addForm.get('documents').value || '-' }} </span></p>
                    </div>

                    <div class="d-flex">
                        <p>Specifications:<span> {{ addForm.get('specifications').value || '-' }} </span></p>
                    </div>

                </div>
            </div>

            <div class="d-flex mt-2">
                <span class="text-subtitle mr-1">BROKER</span> 
                <mat-checkbox (change)="toggleBroker($event)" class="custom-checkbox" [formControl]="isBroker"> </mat-checkbox>
            </div>
          
            <div class="row section-divider" *ngIf="isBroker.value">

                <div class="col-6 col-md-4 formItem">
                    <mat-form-field appearance="outline" class="custom-form-field"
                        (click)="broker.focus()">
                        <mat-label>Broker  *</mat-label>
                        <input class="input1rem" type="text" matInput placeholder="Broker " id="broker"
                            #broker formControlName="brokerName">
                    </mat-form-field>
                </div>
      
                <div  class="col-6 col-md-4 formItem">
                    <mat-form-field appearance="outline" class="custom-form-field">
                    <mat-label>Brokerage Type*</mat-label>
                    <mat-select placeholder="Brokerage Type" formControlName="brokerageType">
                        <mat-option disabled="">Select a type of Brokerage</mat-option>
                        <mat-option value="USD/MT">USD/MT</mat-option>
                        <mat-option [value]="branchService.currentBranch.getValue().currency+'/MT'">{{branchService.currentBranch.getValue().currency}}/MT</mat-option>
                        <mat-option value="%">%</mat-option>
                    </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-6 col-md-4 formItem" >
                    <mat-form-field appearance="outline" class="custom-form-field"
                        (click)="brokerage.focus()">
                        <mat-label class="hideOnMobile">Brokerage ({{this.addForm.get('brokerageType')?.value || ''}}) *</mat-label>
                        <mat-label class="showOnSmall">Brokerage ({{this.addForm.get('brokerageType')?.value || ''}}) *</mat-label>
                        <input class="input1rem" class="input1rem" type="number" matInput placeholder="{{this.addForm.get('brokerageType')?.value || ''}}"
                            id="brokerage" #brokerage formControlName="brokerage">
                        <button type="button" class="hideOnMobile searchbarSuffix fsize22"
                            *ngIf="addForm.get('brokerage').value" matSuffix aria-label="Clear"
                            (click)="addForm.get('brokerage').setValue('')">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                 <div class="col-6 col-md-4 formItem">
                    <mat-form-field appearance="outline" class="custom-form-field">
                        <mat-label>Broker Note #</mat-label>
                        <input type="text" matInput placeholder="Broker Note" id="brokerNote" #brokerNote formControlName="brokerNote">
                    </mat-form-field>
                 </div>
            </div>

            <!-- internal -->
            
            
            <div class="row">
                <!-- exchange rate-->
                <div class="col-6">
                    <div class="">
                        <h2 class="canada-color text-title-2 mt-3">INTERNAL INFORMATION</h2>
                    </div>
                    <mat-form-field appearance="outline" class="custom-form-field">
                        <mat-label>Exchange Rate</mat-label>
                        <input type="number" matInput placeholder="Exchange Rate" id="exchangeRate" #exchangeRate formControlName="exchangeRate" [value]="exchange">
                    </mat-form-field>
                </div>

                <!-- Signed contract -->
                <div class="col-6" *ngIf="!dataIn.isNew">
                    <div class="">
                        <h2 class="canada-color text-title-2 mt-3 mb-1">DOCUMENTS</h2>
                    </div>
                    <div class="row">
                        <div class="col-10" [ngClass]="{'col-12': !dataIn.prevDeal.signedDocUrl}">
                            <input type="file" id="signedFileName" (change)="preAddImage('signedFileName')" hidden />
                            <label for="signedFileName" class="uploadButton d-flex align-items-center">{{ signedFileName }}
                                <span *ngIf="!signedFileName.includes('Add ')" class="material-icons-outlined ml-3 check-icon">
                                    check_circle_outline
                                </span>
                                <span *ngIf="signedFileName.includes('Add ') " class="material-icons-outlined ml-3 advice-icon">
                                    info
                                </span>
                            </label>
                        </div>
                        <div *ngIf="!signedFileName.includes('Add ') && dataIn.prevDeal.signedDocUrl" class="col-2" (click)="downloadSigned(dataIn.prevDeal.signedDocUrl)">
                            <button class="btn uploadButton pb-0 pt-1" type="button" >
                                <span class="material-icons">download</span>
                            </button>
                        </div>
                    </div>
                </div>
                
            </div>
           
            

            <!-- shipment -->
            <h2 class="canada-color text-title-2">SHIPMENT INFORMATION</h2>
            <div class="row">
                <!-- shipmentFrom -->
                <div class="col-12 col-md-6 ">
                    <mat-form-field appearance="outline" class="custom-form-field">
                        <mat-label>Shipment From *</mat-label>
                        <input matInput [matDatepicker]="picker1" formControlName="shipmentFrom"
                            placeholder="Choose a date" autocomplete="off">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1 startView="month"></mat-datepicker>
                    </mat-form-field>
                </div>
                <!-- shipmentTo -->
                <div class="col-12 col-md-6 ">
                    <mat-form-field appearance="outline" class="custom-form-field">
                        <mat-label>Shipment To *</mat-label>
                        <input matInput [matDatepicker]="picker2" formControlName="shipmentTo"
                            placeholder="Choose a date" autocomplete="off">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2 startView="month"></mat-datepicker>
                    </mat-form-field>
              </div>
            </div>

            <!-- NOTES container -->
            <div class="row">
                <!-- notes -->
                <div class="col-12 col-md-6 ">
                    <mat-form-field appearance="outline" class="custom-form-field">
                        <mat-label>Comments</mat-label>
                        <textarea matInput class="no-resize" placeholder="Comments..." id="notes" #notes
                            formControlName="comments" rows="3"></textarea>
                    </mat-form-field>
                </div>
                <!-- companyComments -->
                <div class="col-12 col-md-6 ">
                    <mat-form-field appearance="outline" class="custom-form-field">
                        <mat-label>Internal Comments</mat-label>
                        <textarea matInput class="no-resize"
                            placeholder="Private comments for company members only (This information will not appear in the confirmation email"
                            id="companyComments" #companyComments formControlName="companyComments" rows="3"></textarea>
                    </mat-form-field>
                </div>
            </div>
             <!-- Authorized by  -->
             <h2 class="canada-color text-title-2">AUTHORIZE INFORMATION</h2>
             <div class="col-sm-4 mt-2 " >
                 <mat-form-field appearance="outline" class="custom-form-field">
                     <mat-label > Authorized by *</mat-label>
                     <input type="text" matInput placeholder="Authorized by" id="AuthorizedBy"
                         #buyer formControlName="authorizedBy" [matAutocomplete]="autoAuthorized" class="buyer">
                     <button type="button" class="searchbarSuffix fsize22"
                         *ngIf="addForm.get('authorizedBy').value" matSuffix aria-label="Clear"
                         (click)="addForm.get('authorizedBy').patchValue('')">
                         <mat-icon>close</mat-icon>
                     </button>
                     <mat-autocomplete #autoAuthorized="matAutocomplete" [displayWith]="displayFn" >
                         <mat-option disabled>Select authorized by</mat-option>
                         <mat-option *ngFor="let user of (authorizeOptions | async)" [value]="user">
                             <div class="buyerContainer">
                                 <p>{{user.name}}</p>
                             </div>
                         </mat-option>
                     </mat-autocomplete>
                 </mat-form-field>
             </div>

            <!-- buttons -->
            <div class="btn-flex-row mt-2" *ngIf="!dataIn.dissableContent">
                <!-- cancel button -->
                <button type="button" class="mexicoButton btn-secondary-dim" matRipple (click)="onNoClick()">
                    <span class="">CLOSE</span>
                </button>
                <!-- new button -->
                <button class="canada-color-bg mexicoButton" type="button" matRipple matStepperNext
                    (click)="validateForm()">
                    <span>REVIEW</span>
                </button>
            </div>
            
        </mat-step>

        <mat-step>
            <ng-template matStepLabel>Review</ng-template>
            <div class="row">
                <div class="col-12 email-container">
                    <!-- emailTitle -->
                    <div class="headers ">
                        <mat-form-field appearance="outline" class="custom-form-field">
                            <mat-label>Email Title</mat-label>
                            <input type="text" matInput placeholder="emailTitle" id="emailTitle" #emailTitle
                                formControlName="emailTitle">
                        </mat-form-field>
                    </div>

                    <!-- add tags to client -->
                    <form (ngSubmit)="addTag()">
                        <div class="flex-row">
                            <!-- reminder autocomplete -->
                            <div class="col-6 col-md-6">
                                <mat-form-field appearance="outline" class="custom-form-field">
                                    <mat-label>Add new Email</mat-label>
                                    <input type="text" matInput placeholder="Emails" id="tagsInput" class="fullHeight" #tagsInput [formControl]="tagEmail">
                                </mat-form-field>
                            </div>

                            <!-- add reminder button -->
                            <div class="col-2 col-md-1">
                                <button class="mexicoButton btn-secondary-dim" matRipple type="submit" >
                                    <mat-icon>add_circle_outline</mat-icon>
                                    </button>
                            </div>

                            <!-- add reminder button -->
                            <div class="col-2 col-md-1">
                                <button class="mexicoButton btn-secondary-dim" matRipple type="button" (click)="removeAllTags()">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </div>

                            <div class="col-2 col-md-4" *ngIf="!dataIn.isNew">
                                <mat-checkbox id="resend-email-checkbox" class="custom-checkbox" [formControl]="isResendo" (change)="toggleDirectResend($event)" *ngIf="(dataIn.prevDeal.adroitOrderId != '') && showResendo">
                                    <span class="text-subtitle-2">Resend Email</span>
                                </mat-checkbox>
                                <p *ngIf=" dataIn.prevDeal.adroitOrderId == '' " class="tableText">In order to resend email you need serial number assigned to contract</p>
                            </div>
                        </div>
                    </form>

                     <!-- add emails to deliver -->
                     <div class="row headers">
                        <!-- tagEmails-->
                        <div class="col-12">
                            <mat-chip-list *ngIf="tagEmails.length > 0">
                                <mat-chip *ngFor="let email of tagEmails" removable="true" selectable="false"
                                    (removed)="removeTag(email)" class="custom-email-chip branchChip">
                                    <p class="popUpMenuText">{{email}}</p>
                                    <mat-icon matChipRemove>close</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                            <p *ngIf="tagEmails.length === 0" class="tableText">Has not emails</p>
                            <br>
                            <p class="tableText"><b>Please review the email's list, all should be valid or email wont
                                    send.</b></p>
                        </div>
                    </div>

                    <!-- email content -->
                    <div class="external-info-container row headers">
                        <div class="email-info-container">
                            <p class="product-info-ns ml-1"><b>Email Content:</b></p>
                            <div class="">
                                <div class="">
                                    <p class="product-info-ns" *ngIf="isBuyers">{{'Dear ' + (addForm.get('buyers').value ? subjectForm.get('contact').value : '')}}</p>
                                    <p class="product-info-ns" *ngIf="isSuppliers">{{'Dear ' + (addForm.get('suppliers').value ? subjectForm.get('contact').value : '')}}</p>
                                    <br>
                                    <p class="headers" *ngIf="isBuyers && !isResend">Hereby we confirm the sale of the following commodity:</p>
                                    <p class="headers" *ngIf="isSuppliers && !isResend">Hereby we confirm the Purchase of the following commodity:</p>
                                    <p class="headers" *ngIf="isResend">As agreed, please find below details of the AMENDMENTS to contract {{ dataIn.prevDeal.adroitOrderId }}.</p> 
                                </div>

                                <!-- Commodity -->
                                <div class="d-flex">
                                    <p class="product-info-ns"><b>Commodity: </b></p>
                                    <p>
                                        {{commodityText()}}
                                    <!-- <p>{{(addForm.get('grains').value ? generalService.replaceTextAfterExpression(addForm.get('grains').value.name,',','') : '') + 
                                        (addForm.get('grade').value.name != 'Feed' ? ' #' : ' ') + 
                                        (addForm.get('grade').value && addForm.get('grade').value.name != 'N/A' ? addForm.get('grade').value.name : '')}}
                                        {{ addForm.get('grade').value ? (addForm.get('grade').value.name != 1 && addForm.get('grade').value.name != 'Feed' && addForm.get('grade').value.name != 'N/A' ) ? ' Or Better' : '' : ''}}
                                        {{addForm.get('origin').value ? ', ' +  addForm.get('origin').value + ' Origin' : ''}}
                                    </p> -->
                                    </p>
                                </div>

                                <!-- Quantity -->
                                <div class="d-flex">
                                   <p class="product-info-ns"><b>Quantity: </b></p>
                                   <p>{{addForm.get('quantity').value + ' ' + addForm.get('units').value}}</p>
                                </div>

                                <!-- Price -->
                                <div class="d-flex" *ngIf="addForm.get('price').value">
                                    <p class="product-info-ns"><b>Price: </b></p>
                                    <p>$ {{(this.addForm.get('price').value) + ' '+ addForm.get('priceUnits').value}}</p>
                                </div>

                                <!-- Loads -->
                                <div class="d-flex">
                                    <p class="product-info-ns"><b>Number of Loads: </b></p>
                                    <p>{{addForm.get('noOfLoads').value + ' ' + (addForm.get('noOfLoads').value > 1 ? 'Loads' : 'Load' ) }}</p>
                                </div>

                                <!-- Shipment Terms -->
                                <div class="d-flex" *ngIf="addForm.get('shipmentTerms').value">
                                    <p class="product-info-ns"><b>Shipment Terms: </b></p>
                                    <p>{{ addForm.get('shipmentTerms').value}}, {{addForm.get('shipmentAddress').value}}</p>
                                </div>

                                <!-- Delivery Period -->
                                <div class="d-flex">
                                    <p class="product-info-ns"><b>Delivery Period: </b></p>
                                    <p>{{formatDateMomentYear(addForm.get('shipmentFrom').value) + ' to ' + formatDateMomentYear(addForm.get('shipmentTo').value)}}</p>
                                </div>

                                <!-- Payment Terms -->
                                <div class="d-flex">
                                    <p class="product-info-ns"><b>Payment Terms: </b></p>
                                    <p>{{addForm.get('paymentTerms').value}}</p>
                                </div>

                                <!-- Contract # -->
                                <div class="d-flex" *ngIf="addForm.get('contractReferenceNo').value">
                                    <p class="product-info-ns"><b>Contract Ref N°: </b></p>
                                    <p>{{addForm.get('contractReferenceNo').value}}</p>
                                </div>

                                <br>

                                <!-- Documents -->
                                <div class="d-flex" style="white-space: pre-wrap;" *ngIf="addForm.get('documents').value">
                                    <p class="product-info-ns" ><b>Documents: </b></p>
                                    <p>{{addForm.get('documents').value}}</p>
                                </div>    

                                <!-- Terms -->
                                <div class="d-flex" *ngIf="addForm.get('terms').value">
                                    <p class="product-info-ns"><b>Terms: </b></p>
                                    <p>{{addForm.get('terms').value}}</p>
                                </div>
                           
                                <!--Broker Preview Disabled -->
                                <!-- <br *ngIf="isBroker.value"> -->

                                <!-- Broker -->
                                <!-- <div class="d-flex" *ngIf="addForm.get('brokerName').value">
                                    <p class="product-info-ns"><b>Broker: </b></p>
                                    <p>{{addForm.get('brokerName').value}}</p>
                                </div> -->

                                <!-- Brokerage -->
                                <!-- <div class="d-flex" *ngIf="addForm.get('brokerage').value">
                                    <p class="product-info-ns"><b>Brokerage: </b></p>
                                    <p>{{addForm.get('brokerage').value + ' ' + addForm.get('brokerageType').value}}</p>
                                </div> -->

                                <!-- Broker Note -->
                                <!-- <div class="d-flex" *ngIf="addForm.get('brokerNote').value">
                                    <p class="product-info-ns"><b>Broker Note: </b></p>
                                    <p>{{addForm.get('brokerNote').value}}</p>
                                    <br>
                                </div> -->

                                <!-- Specifications -->
                                <div class="d-flex" style="white-space: pre-wrap;" *ngIf="addForm.get('specifications').value">
                                    <p  class="product-info-ns" ><b>Specifications: </b></p>
                                    <p class="specificartions" >{{addForm.get('specifications').value}}</p>
                                </div>
                                
                                <!-- Comments -->
                                <div class="d-flex" style="white-space: pre-wrap;" *ngIf="addForm.get('comments').value">
                                    <br>
                                    <p class="product-info-sb"><b>Comments: </b></p>
                                    <br>
                                    <p>{{addForm.get('comments').value}}</p>
                                    <br>
                                </div>
                                
                                <!-- Seller -->
                                <div class="d-flex mt-4"  *ngIf="dataIn.isPurchases">
                                    <p class="product-info-ns"><b>Seller: </b></p>
                                    <p>{{subjectForm.get('companyName').value}}</p>
                                </div>
                                
                                <!-- Buyer -->
                                <div class="d-flex mt-2" *ngIf="!dataIn.isPurchases">
                                    <p class="product-info-ns"><b>Buyer: </b></p>
                                    <p>{{subjectForm.get('companyName').value}}</p>
                                </div>
                                
                                <!-- Contact -->
                                <div class="d-flex" *ngIf="subjectForm.get('contact').value">
                                    <p class="product-info-ns"><b>Contact: </b></p>
                                    <p>{{subjectForm.get('contact').value}}</p>
                                </div>
                                
                                <!-- Adress -->
                                <div class="d-flex" *ngIf="subjectForm.get('address').value">
                                    <p class="product-info-ns"><b>Address: </b></p>
                                    <p>{{subjectForm.get('address').value}}</p>
                                </div>
                                
                                <!-- Phone -->
                                <div class="d-flex" *ngIf="processPhone(subjectForm.get('phone').value)">
                                    <p class="product-info-ns"><b>Phone: </b></p>
                                    <p>{{processPhone(subjectForm.get('phone').value)}}</p>
                                </div>

                                <!-- Email -->
                                <div class="d-flex" *ngIf="subjectForm.get('email').value">
                                    <p class="product-info-ns"><b>Email: </b></p>
                                    <p>{{subjectForm.get('email').value}}</p>
                                </div>
                                

                                <br>
                                <!-- Buyer/Seller -->
                                <div class="d-flex" *ngIf="dataIn.isPurchases">
                                    <p class="product-info-ns"><b>Buyer: </b>Adroit Overseas Enterprises Ltd.</p>
                                </div>
                                
                                <div class="d-flex" *ngIf="!dataIn.isPurchases">
                                    <p class="product-info-ns"><b>Seller: </b>Adroit Overseas Enterprises Ltd.</p>
                                </div>

                                <!-- End Note -->
                                <div class="col-12">
                                    <br>
                                    <p class="product-info-sb">Kindly acknowledge the receipt of this email. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- buttons -->
            <div class="btn-flex-row mt-2" *ngIf="!dataIn.dissableContent">
                <div class="custom-check-deal">
                    <mat-checkbox id="confirm-checkbox" class="custom-checkbox" formControlName="confirmRead" (change)="confirmed = !confirmed">
                        <span class="text-subtitle-2 display-desktop">I've read the information and confirm that it's correct.</span>
                        <span class="text-subtitle-2 display-mobile">I've read the information and</span>
                        <br class="display-mobile">
                        <span class="text-subtitle-2 display-mobile">confirm that it's correct.</span>
                    </mat-checkbox>
                </div>
                <!-- return button -->
                <div>
                    <button type="button" class="mexicoButton btn-secondary-dim" matRipple matStepperPrevious
                        (click)="addForm.get('confirmRead').patchValue(false)">
                        <span>RETURN</span>
                    </button>
                </div>

                <!-- return button -->
                <div class=""
                    *ngIf="!dataIn.isNew ">
                    <button type="button" class="mexicoButton btn-secondary" matRipple (click)="deleteObject()">
                        <span>DELETE</span>
                    </button>
                </div>

                <!-- new button -->
                <div class="custom-btn-add-deal">
                    <!-- submit button -->
                    <button class="canada-color-bg mexicoButton canDisable" type="submit" matRipple
                        [disabled]="!addForm.get('confirmRead').value || !addForm.get('emailTitle').value || disableButton">
                        <span *ngIf="willDeal && dataIn.isNew">SEND CONFIRMATION EMAIL</span>
                        <span *ngIf="willDeal && !dataIn.isNew">UPDATE DEAL</span>
                        <!-- <span *ngIf="willCanceled">CANCEL DEAL</span>
                        <span *ngIf="willDone">FINISH DEAL</span> -->
                    </button>

                    <!-- expand deal types -->
                    <button class="canada-color-bg mexicoButton" type="button" matRipple
                        [matMenuTriggerFor]="dealTypes" *ngIf="!dataIn.isNew">
                        <mat-icon class="marg0">expand_more</mat-icon>
                    </button>

                    <!-- popoup deal types -->
                    <mat-menu #dealTypes="matMenu">
                        <button *ngIf="isDone" type="button" mat-menu-item class="popUpMenuButton popUpMenuText" (click)="openClose('open')">
                            <mat-icon class="popUpMenuIcon">playlist_add_check</mat-icon>
                            <span>Open deal</span>
                        </button>
                        <button *ngIf="!isDone" type="button" mat-menu-item class="popUpMenuButton popUpMenuText" (click)="openClose('close')">
                            <mat-icon class="popUpMenuIcon">done</mat-icon>
                            <span>Finish deal</span>
                        </button>
                        <button *ngIf="!isCanceled && !isDone" type="button" mat-menu-item class="popUpMenuButton popUpMenuText" (click)="openClose('cancel')">
                            <mat-icon class="popUpMenuIcon">close</mat-icon>
                            <span>Cancel deal</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
</form>