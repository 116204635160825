export interface conditions {
    grayConditions?: types,
    yellowConditions? : types,
    redConditions? : types,
    greenConditions? : types,
    orangeConditions? : types,
    blackConditions? : types,
    darkRedConditions?: types,
    greenBluConditions?: types,
    blueConditions?: types
    lilaConditions?: types
}
export interface types {
    [path: string]: any,
}


export interface files {
    prop: string,
    name: string
}


export class SimpleTableColumnType {
    constructor(
        public type: string = 'text',
        public formatter: any = (data) => data,
        public minWidthNumber: number = 200,
        public maxWidthText: number = 0,
        public editButton: any = false,
        public bgColor: string = '#202d42',
        public colorConditions: conditions = {},
        public textRight: boolean = false,
        public textCenter: boolean = false,
        public multipleFilesArray: any = null,
        public colorFunction: any = null,
    ) {}
}
