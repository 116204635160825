export class TableColumnType {
    constructor(
        public type: string = 'text',
        public formatter: any = (data) => data,
        public minWidthNumber: number = 200,
        public config: any = {},
        public hasCopyButton: boolean = true,
        public maxWidthText: string = '',
        public editButton: any = false,
        public dropDown: any = false,
        public formatterAppliedOnRow: any = false,
        public hasClickEvent: boolean = false,
        public hasCrmClickEvent: boolean = false,
        public bgColor: string = '#202d42' ,
        public isFirst: boolean = false,
        public isLast: boolean = false,
        public operationProp: string = "",                       //property from which you want to make operations with  (e.g. count expired days from the column expired date)
        public priorityProps: string[] = [],                     //properties to show in priority order (e.g. if first column is empty show second column)
        public disabled:any = (data) => false,
    ) {}
}
