<div class="main-container">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-12 d-flex justify-content-end">
                <button  (click)="routeLoad()"class="close" mat-button>X</button>
            </div>
            <div class="col-12 col-md-12 d-flex justify-content-center">
                <img *ngIf="userAuth" src="../../../../../assets/img/messageComplete.png" alt="message complete" class="image">
                <img *ngIf="!userAuth" src="../../../../../assets/img/notAllow.png" alt="not allow" class="image">
            </div>
            <div class="col-12 col-md-12 d-flex justify-content-center">
                <span class="title">{{ userAuth ? 'Load Order has been Authorized' : 'You are not allow to authorize this transaction' }}</span>
            </div>
            <div *ngIf="userAuth && dataObject" class="col-12 col-md-8 justify-content-center description-container mt-3 mb-2" style="display:grid;">
                <span class="header">Client: {{dataObject.buyer ? dataObject.buyer : ""}}</span>
                <span class="header">Product: {{dataObject.product.name || ''}} #{{dataObject.grade.name || ''}}</span>
                <span class="header">Quantity: {{dataObject.quantity || ""}} MT</span>
            </div>
            <br>
            <hr *ngIf="userAuth" class="line green-color"/>
            <hr *ngIf="!userAuth" class="line red-color mt-4 mb-3"/>
            <div class="col-12 col-md-12 d-flex justify-content-center description-container">
                <span class="description">{{userAuth ? 'Once this load order is generated the restriction for the client will continue unless he request his situation.' : 'If you need help please contact an administrator'}}</span>
            </div>
            <div class="col-12 col-md-12 d-flex justify-content-center description-container mt-2 mb-4">
                <button class="close-button" (click)="routeLoad()">Thank You</button>
            </div>
        </div>
    </div>
</div>
