<div class="panelCdk">
  <h2 class="canada-color">{{data.title | uppercase}}</h2>
  <div mat-dialog-content class="Panel-act-2">
    <h4>{{data.subtitle}}</h4>
    <div *ngFor="let line of data.message">
      <p>{{line}}</p>
    </div>
  </div>
  <div class="btn-flex-row mt-2">
    <div >
        <button *ngIf="data.removeCancel !== true"  type="button" class="mexicoButton btn-secondary-dim" matRipple 
            (click)="dismiss()">
            <span *ngIf="data.button2">{{data.button2}}</span>
            <span *ngIf="!data.button2">Cancel</span>
        </button>
    </div>
    <div>
      <button type="button" class="canada-color-bg mexicoButton fullWidth" matRipple 
          (click)="confirm()">
          <span>{{data.button}}</span>
      </button>
    </div>
  </div>
  <!-- <div mat-dialog-actions style="float: right;">
    <button *ngIf="!data.cancel" type="button" class="mexicoButton btn-secondary-dim mr-2" matRipple (click)="dismiss()">Cancel</button>
    <button type="button" class="canada-color-bg mexicoButton fullWidth" matRipple (click)="confirm()">{{data.button}}</button>
  </div> -->
</div>