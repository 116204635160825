import {AfterViewInit, Component, Inject, OnDestroy, OnInit, NgZone, ViewChild, ChangeDetectorRef} from '@angular/core';
import {Subject} from "rxjs";
import {UntypedFormBuilder, UntypedFormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../services/auth/auth.service";
import {ApiService} from "../services/api/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from '@angular/material/snack-bar';
import {LoadingService} from "../services/loading/loading.service";
import {takeUntil} from "rxjs/operators";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MessageModalComponent} from "../modals/desicion-modals/message-modal/message-modal.component";
import {Title} from "@angular/platform-browser";
declare var particlesJS: any;
import {ResetPasswordComponent} from '../modals/crud-modals/reset-password/reset-password.component';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  // Local variables declaration //
  private onDestroy = new Subject<void>();
  private firstLoad = true;
  private key = 'LSrFau';

  public loginForm = this.fb.group({
    username: new UntypedFormControl({value: '', disabled: false}, Validators.required),
    password: new UntypedFormControl({value: '', disabled: false}, Validators.required)
  });

  // Component constructor //
  constructor(
      private authService: AuthService,
      private apiService: ApiService,
      private fb: UntypedFormBuilder,
      private router: Router,
      private zone: NgZone,
      private activateRoute: ActivatedRoute,
      private snackBar: MatSnackBar,
      private loadingService: LoadingService,
      private dialog: MatDialog,
      private titleService: Title,
      public route: ActivatedRoute,
  ) {
    this.setTitle('Login - Adroit CRM');
  }
  public setTitle(newTitle: string) {
    this.titleService.setTitle( newTitle );
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params.token) {
        const userObj = JSON.parse(params.token);
        if (typeof userObj ==='object') {
          this.authService.setUserManually(userObj);
        }
      }
      //particles
      particlesJS.load('particles-js', 'assets/particles/particles.json', function() { console.log('callback - particles.js config loaded'); });
    });

    if (this.authService.currentUserValue && this.firstLoad) {
      this.loggedNavigate(this.authService.currentUserValue.user)
    } else {
      this.authService.userObservable.pipe(takeUntil(this.onDestroy)).subscribe((user) => {
        if (user && user.user && this.firstLoad) {
          this.loggedNavigate(user.user)
        }
      });
    }
  }
  // ---------------------- //
  // Perform login function //
  // ---------------------- //
  performLogin(email?, password?, isNew?) {
    if (this.loginForm.status === 'INVALID') {
      if (email && password){
        this.loadingService.showLoader.next(true);
        let loginObject: any = {
          ttl: (60 * 60 * 24 * 7 * 48),
          password: password,
          email: email
        };
        this.loginQuery(loginObject, isNew)
      }else {
        if(!this.loginForm.get('username').valid){
          this.presentToast('Please enter your enterprise email');
        } else if(!this.loginForm.get('password').valid){
          this.presentToast('System Requires Password');
        } else {
          this.presentToast('Something went wrong, please contact developers')
        }
      }
    } else {
        this.firstLoad = false;
        this.loadingService.showLoader.next(true);
        const propiertie = this.loginForm.get('username').value.includes('@') ? 'email' : 'username';
        const loginObject: any = {
          ttl: (60 * 60 * 24 * 7 * 48),
          password: this.loginForm.get('password').value,
          [propiertie]: this.loginForm.get('username').value.toLowerCase()
        }
        this.loginQuery(loginObject)
    }
  }

  loginQuery(loginObject, isNew?){
    this.authService.login(loginObject).pipe(takeUntil(this.onDestroy)).subscribe((loggedUser: any) => {
      const editUser = {
        passwordStatus: "changing"
      }
      if (loggedUser) {
        if(!loggedUser.user.isActive){
          this.authService.logout(true);
          this.presentAlert('Authentication error', 'This user is no longer active.');
        }else{
          if (isNew){
            editUser.passwordStatus = "changed"
            loggedUser.user.passwordStatus = "changed"
            this.editPasswordStatus(loggedUser, editUser)
          }
          if (loggedUser.user.passwordStatus != "changed"){
            this.authService.useRemoteMethod('AppUsers', 'forgotPassword', loggedUser.user)
            .pipe(takeUntil(this.onDestroy))
            .subscribe((r: any) => {
              this.editPasswordStatus(loggedUser, editUser)
              this.loadingService.showLoader.next(false);
              r = r.resp;
              if (r.code == "EMAIL_NOT_FOUND") {
                this.presentAlert(r.message, '');
              }else{
                this.presentAlert('Authentication alert', 'Your password has not been changed, an e-mail has been sent with a link to set it.');
                this.authService.logout(loggedUser)
              }
            }, (err) => {
              this.loadingService.showLoader.next(false);
              this.presentAlert('Connection error', 'Error trying to connect to the server, verify your connection. ' + err);
              this.authService.logout(loggedUser)
            });
          } else{
            this.navigate(loggedUser);
          }            
        }
      } else {
        this.loadingService.showLoader.next(false);
        this.presentAlert('Authentication error', 'Please, verify your credentials.');
      }
    }, (err) => {
      this.loadingService.showLoader.next(false);
      this.presentAlert('Connection error', 'Error trying to connect to the server, verify your connection. ' + err);
    });
  }

  editPasswordStatus(loggedUser, status){
    this.apiService.editDataObject(loggedUser.user.id, status, 'AppUsers').pipe(takeUntil(this.onDestroy)).subscribe(() => {
      console.log('User edited succesfullly');
    }, (e) => {
      console.log('Connection rejected');
    });
  }
  // --------------------------- //
  //       Forgot Password       //
  // --------------------------- //
  password(){
    this.router.navigate(['/forgot_password']).catch();
  }

  // --------------------------- //
  // Show toast on invalid login //
  // --------------------------- //
  presentToast(message: string) {
    this.snackBar.open(message, 'Cerrar', {
      duration: 3000,
      panelClass: ['yellow-snackbar'],
      horizontalPosition: 'end',
      verticalPosition: document.documentElement.clientWidth >= 1050 ? 'top' : 'bottom'
    });
  }

  // --------------------------- //
  // Show alert on invalid login //
  // --------------------------- //
  presentAlert(title, msg) {
    this.dialog.open(MessageModalComponent, {
      width: '300px',
      data: {
        title: title,
        msg: msg
      }
    });
  }
  // -------------------- //
  // On destroy lifecycle //
  // -------------------- //
  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.unsubscribe();
  }
  // -------------------- //
  // Save user //
  // -------------------- //
  saveNotification(userId, id) {
    let notificationToken = {
      appUserId: userId,
      token: id
    };
    // // console.log('saving notification');
    this.apiService.addDataObject( notificationToken , 'NotificationTokens').pipe(takeUntil(this.onDestroy)).subscribe((params) => {

    }, (e) => {
      // // console.log('error', e);

    });
  }


  loggedNavigate(user: any) {
    let data: any = localStorage.getItem('LSrFau') ? localStorage.getItem('LSrFau') : 'dashboard-purchases';
    const decryptedData = this.authService.decrypt(data, this.key);
    let route = (decryptedData) ? JSON.parse(decryptedData) : '';

    if (user && !user.homeViewed) {
      route = 'dashboard-purchases';
      this.updateHomeViewed(user.id);
    }

    this.router.navigate([route]).then(() => {
      this.loadingService.showLoader.next(false);
    }).catch();
  }

  updateHomeViewed(userId: string): void {
    this.apiService.editDataObject(userId, { homeViewed: true }, 'AppUsers').subscribe(() => console.log);
  }

  navigate(user) {
    // const ls = new SecureLS({encodingType:'aes'})
    // let route = ls.get('LSrFau') ? ls.get('LSrFau') :'dashboard-purchases';
    // console.log(ls.get('LSrFau'),'real route')
    // console.log(route,'actual route')
    // if (user.user.role === 'winnipeg') {
    //   route = 'freight-movement'
    // } else if (user.user.role === 'logistics') {
    //   route = 'grain-weeks'
    // } else if (user.user.role === 'accounting') {
    //   route = 'cashRegister'
    // }
    // if(user.user.role === 'dubai') {
    //   route = 'dubai-inward'
    // }
    let route = "dashboard-purchases"
    this.apiService.getDataObject("Branches", user.user.prevBranch ? user.user.prevBranch : user.user.branchIds.length ? user.user.branchIds[0] : '')
    .pipe(takeUntil(this.onDestroy)).subscribe((branch:any)=>{
      if(branch && branch.name){
        switch (branch.name.toUpperCase()) {
          case "CANADA":
            route = this.checkCanadaRoute(user)
            break;
          case "MEXICO":
            route = "dashboard-mexico"
            break;
          case "DUBAI":
            route = "dubai-home"
            break;
          case "USA":
            route = "dashboard-usa"
            break;
          default:
            break;
        }
      }
      this.router.navigate([route]).then(() => {
        this.loadingService.showLoader.next(false);
      }).catch();
    },()=>{
      this.router.navigate([route]).then(() => {
        this.loadingService.showLoader.next(false);
      }).catch();
    })

  }
  
  checkCanadaRoute(user){
    // selecting the route per role
  switch(user.user.role.toLowerCase()){
    case "logistics":
      return "plantOperations";

    case "sales":
      return "dashboard-sales";

    case "purchases":
      return "dashboard-purchases";

    case "winnipeg":
      return "dashboard-winnipeg";

    case "accounting":
      return "cashRegister";

    case "reports":
      return "msi";

    case "generaladmin":
      return "dashboard-purchases";

    case "branchAdmin":
      return "dashboard-purchases";

    case "branchtrader":
      return "dashboard-purchases";

    case "purchases_logistics":
      return "goals";

    case "hr":
      return "calendar";
      
    case "certification":
      return "certifications-home";

    case "positionchart":
      return "position_chart";

    case "plantmanagement":
      return "ticket-generator";

    case "expenses":
      return "expenses-management";
      
    default: 
      return "dashboard-purchases";
    } 
  }

  changePassword() {
    const dialogRef = this.dialog.open(ResetPasswordComponent, {
      data: {
        button: 'Reset',
        title: 'User',
        subtitle: 'Change password',
        message: [
        ]
      },
      width: '350px',
      autoFocus: false,
      panelClass:'usaModal'
    });
    // dialogRef.afterClosed().pipe(takeUntil(this.onDestroy)).subscribe(result => {
    //   if (result !== undefined) {
    //     if (result.password) {
    //       this.apiService.editDataObject(this.dataIn.user.id, {password: result.password}, 'AppUsers').pipe(takeUntil(this.onDestroy)).subscribe(() => {
    //         this.presentToast('User password changed succesfullly', 'green-snackbar');
    //       }, err => {
    //         this.presentToast('Error changing the password', 'red-snackbar');
    //       });
    //     }
    //   }
    // });
  }
}
